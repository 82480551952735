import React, { HTMLProps } from "react";

function TableBody({
  children,
  ...props
}: HTMLProps<HTMLTableSectionElement>): JSX.Element {
  return <tbody {...props}>{children}</tbody>;
}

export default TableBody;
