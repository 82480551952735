import React, { HTMLProps } from "react";
import { css } from "@emotion/core";

export interface TableHeaderCell extends HTMLProps<HTMLTableHeaderCellElement> {
  leftBordered?: boolean;
  rightBordered?: boolean;
}

function TableHeaderCell({
  children,
  leftBordered = true,
  rightBordered = true,
  ...others
}: TableHeaderCell): JSX.Element {
  return (
    <th
      css={css`
        min-width: 104px;
        padding: 5px;
        vertical-align: bottom;
        text-align: center;
        text-transform: uppercase;
        border-left: ${leftBordered ? "0.7px" : "0px"} solid #d3d3d3;
        border-right: ${rightBordered ? "0.7px" : "0px"} solid #d3d3d3;
      `}
      {...others}
    >
      {children}
    </th>
  );
}

export default TableHeaderCell;
