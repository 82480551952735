/* Module: Map
 * ==========================================================================
 *
 * The Map module provides a map of a US election, along with Forecast data
 * about each race. States colored by which party most likely to win that state.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/map.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `usePercentage`: A boolean flag that indicates whether probabilities
 *                      should be displayed as percentage or should be
 *                      displayed as number.
 *                      It defaults to true (display percentage).
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *   - `_locResults`:   Forecasts for the current data config ordered by
 *                      Location.
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_map`:          The HighCharts instance.
 *   - `_mapData`:      Processed map data for the chart.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as color from "../services/color";
import * as probs from "../services/probs";
import * as data from "../services/data";

export class EAMap {
  constructor(element, options) {
    //TODO: make this constructor less ugly. This is due to the legacy code pulling results from the API
    this.moduleType = "map";
    this._$element = $(element);
    this._element = element;
    this._$element.empty();
    this._$element.addClass("ea-map");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      usePercentage: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    // First load any necessary data, then initialize HTML and update it.
    this.setData = false;
    this._updateData().done(
      $.proxy(function () {
        console.log(this._races);
        /*
        this._initializeHtml();
        this._initializeHighCharts();
        this._updateHtml();
        */

        // NEW MAP INIT
        // TODO: Clean up the code and turn it into an ES6 Class

        this.width = 1000;
        this.height = 575;
        document.getElementById("infopane").innerHTML = "";
        //  "<span style='font-weight: 800'>No State Selected</span>";

        let svg = d3
          .select("#map")
          .append("svg")
          .attr("id", "svg-map")
          .attr("preserveAspectRatio", "xMinYMin meet")
          .attr("viewBox", "0 0 " + this.width + " " + this.height)
          .classed("svg-content-responsive", true);
        /*.attr("height", this.height)
        .attr("width", this.width)*/

        this.mapGroup = svg
          .append("g")
          .attr("height", this.height)
          .attr("width", this.width);

        // this.colorScale = d3.scaleLinear()
        //   .domain([.95,0.5,0.05])
        //   //.range(["#5555ff", "white", "#ff5555"]);
        //   //.range(["#318CE7", "white", "#f0000d"]);
        //   //.range(["#318CE7", "purple", "#ef323b"]);
        //   .range(["#318CE7", "#cd8be8", "#ef323b"]);

        this.zoom = d3
          .zoom()
          .scaleExtent([1, 8])
          .on("zoom", this.zoomed.bind(this));

        this.root = document.getElementById("svg-map");

        d3.select(this.root)
          .call(this.zoom)
          .on("wheel.zoom", null)
          .on("mousedown.zoom", null)
          .on("touchstart.zoom", null)
          .on("touchmove.zoom", null)
          .on("touchend.zoom", null);

        this.mapData = null;
        this.projection = null;
        this.path = null;
        this.selected = null;
        this.table = null;
        this.topology = null;

        d3.json(
          "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/united-states/us-albers.json"
        ).then(
          function (data) {
            this.topology = data;
            this.selected = this.mapData;
            this.mapData = topojson.feature(
              this.topology,
              this.topology.objects.us
            );
            console.log(this.mapData);
            // this is a very temporary hack
            let gac3 = {
              type: "Feature",
              id: 6,
              properties: {
                geo_id: "0400000US13",
                fips_state: "13",
                name: "Georgia-Inset",
                iso_3166_2: "GA-C3",
                census: 9687653,
                pop_estimataes_base: 9688681,
                pop_2010: 9714464,
                pop_2011: 9813201,
                pop_2012: 9919000,
                pop_2013: 9994759,
                pop_2014: 10097343,
                demPct: 0.19004,
                repPct: 0.80996,
              },
              geometry: {
                type: "Polygon",
                coordinates: [
                  [
                    [-84.134635, 33.292024],
                    [-82.913334, 33.292024],
                    [-82.913334, 32.304473],
                    [-84.134635, 32.304473],
                    [-84.134635, 33.292024],
                  ],
                ],
              },
            };

            if (this._dataConfig.type === "senate") {
              this.mapData.features.push(gac3);
            }
            console.log(this._locResults);
            for (let state in this.mapData.features) {
              let state_iso = String(
                this.mapData.features[state].properties.iso_3166_2
              );
              if (this._dataConfig.type === "president" && state_iso == "ME")
                state_iso = "ME:AL";
              if (this._dataConfig.type === "president" && state_iso == "NE")
                state_iso = "NE:AL";
              if (this._dataConfig.type === "senate") {
                state_iso = state_iso + "-C2";
                if (state_iso === "AZ-C2") {
                  state_iso = "AZ-C3";
                }
                if (state_iso === "GA-C3-C2") {
                  state_iso = "GA-C3";
                }
              }
              if (state_iso in this._locResults) {
                this.mapData.features[
                  state
                ].properties.demPct = this._locResults[
                  state_iso
                ].probsByParty.Democrat;
                
                if(this.mapData.features[state].properties.iso_3166_2 === "AK" && this._dataConfig.type === "senate")
                { 
                  this.mapData.features[
                    state
                  ].properties.demPct = this._locResults[
                    state_iso
                  ].probsByParty.Independent;
                }
                this.mapData.features[
                  state
                ].properties.repPct = this._locResults[
                  state_iso
                ].probsByParty.Republican;
              }
            }

            this.projection = d3
              .geoMercator()
              .fitSize([this.width, this.height], {
                type: "FeatureCollection",
                features: this.mapData.features,
              });
            this.path = d3.geoPath(this.projection);

            this.mapGroup
              .selectAll("path")
              .data(this.mapData.features)
              .enter()
              .append("path")
              .on("click", this.clicked.bind(this))
              .attr("d", this.path)
              .attr("stroke", this.outlineStateColor.bind(this))
              .attr("stroke-width", this.outlineStateSize.bind(this))
              .attr("name", (d) => {
                return d.properties.name;
              })
              .attr("fill", this.fillState.bind(this));

            // hardcoded fix for 2020, todo remove this
            if (
              this._dataConfig.type === "senate" &&
              this._dataConfig.year === 2020
            ) {
            }

            this.mapGroup.selectAll("path").each(function () {
              if (this.attributes.stroke.value === "black") {
                this.parentNode.appendChild(this);
              }
            });
            if (this._dataConfig.type === "senate") {
              this.mapGroup.selectAll("path").each(function () {
                if (this.attributes.name.value === "Georgia-Inset") {
                  d3.select(this).raise()
                  console.log("raise")
                }
              });
            }

          }.bind(this)
        );
      }, this)
    );
  }

  zoomed() {
    const { transform } = d3.event;
    this.mapGroup.attr("transform", transform);
    this.mapGroup.attr("stroke-width", 1 / transform.k);
  }

  fillState(d) {
    return color.getSteppedMarginColor(
      d.properties.demPct - d.properties.repPct
    );
    // return this.colorScale(d.properties.demPct);
  }

  outlineStateColor(d) {
    let p = d.properties.demPct - d.properties.repPct;
    if (p < 0.2 && p > -0.2) {
      return "black";
    }
    return "white";
  }

  outlineStateSize(d) {
    let p = d.properties.demPct - d.properties.repPct;
    if (p < 0.2 && p > -0.2) {
      return "1.5";
    }
    return "0.5";
  }

  clicked(d) {
    console.log(d);
    if (this.selected === null) this.selected = this.mapData;
    if (this.selected === this.mapData) {
      this.selected = d;
      let [[x0, y0], [x1, y1]] = this.path.bounds(d);

      d3.event.stopPropagation();

      this.mapGroup
        .selectAll("path")
        /*.filter(function (n, i) {
          if (this.getAttribute("name") !== d.properties.name) {
            return n;
          }
        })*/
        .transition()
        .attrTween("opacity", function () {
          return function (t) {
            return 1 - t;
          };
        })
        .duration(500);

      d3.select(this.root)
        .transition()
        .delay(100)
        .duration(750)
        .call(
          this.zoom.transform,
          d3.zoomIdentity
            .translate(this.width / 2, this.height / 2)
            .scale(
              Math.min(
                8,
                0.9 / Math.max((x1 - x0) / this.width, (y1 - y0) / this.height)
              )
            )
            .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
          d3.mouse(this.root)
        );

      setTimeout(() => {
        $("#map").addClass("ea-map-small");
        $("#infopane").addClass("infopane-small");
        this.updatehtml();
        let that = this;
        this.mapGroup
          .selectAll("path")
          .filter(function (n, i) {
            if (this.getAttribute("name") === that.selected.properties.name) {
              return n;
            }
          })
          .transition()
          .attrTween("opacity", function () {
            return function (t) {
              return t;
            };
          })
          .duration(250);
      }, 500);
    } else {
      let selection = this.selected;
      this.selected = this.mapData;

      d3.select(this.root)
        .transition()
        .duration(750)
        .call(this.zoom.transform, d3.zoomIdentity);

      let that = this;
      this.mapGroup
        .selectAll("path")
        .filter(function (n, i) {
          if (this.getAttribute("name") === selection.properties.name) {
            return n;
          }
        })
        .transition()
        .attrTween("opacity", function () {
          return function (t) {
            return 1 - t;
          };
        })
        .duration(250);

      this.mapGroup
        .selectAll("path")
        /*.filter(function (n, i) {
          if (this.getAttribute("name") !== selection.properties.name) {
            return n;
          }
        })*/
        .transition()
        .attrTween("opacity", function () {
          return function (t) {
            return t;
          };
        })
        .delay(500)
        .duration(250);

      setTimeout(() => {
        $("#map").removeClass("ea-map-small");
        $("#infopane").removeClass("infopane-small");
      }, 250);

      document.getElementById("infopane").innerHTML = "";
      //"<span style='font-weight: 800'>No State Selected</span>";
    }
  }

  updatehtml() {
    console.log(this._races)
    let state_iso = this.selected.properties.iso_3166_2;
    console.log(state_iso)
    if (state_iso == "ME" && this._dataConfig.type === "senate") state_iso = "ME";
    if (state_iso == "ME" && this._dataConfig.type === "president") state_iso = "ME:AL";
    if (state_iso == "NE"  && this._dataConfig.type === "president") state_iso = "NE:AL";
    if (state_iso == "NE"  && this._dataConfig.type === "senate") state_iso = "NE";
    if (this._dataConfig.type === "senate") state_iso = state_iso + "-C2";
    if (state_iso === "AZ-C2") state_iso = "AZ-C3";
    if (state_iso === "GA-C3-C2") state_iso = "GA-C3";
    let state_results = this._locResults[state_iso];
    let races = this._races[state_iso][0];
    let newTableData =
      "<div id='result-infopane'><p><span style='font-weight: 800'>" +
      this.selected.properties.name +
      " (" +
      state_results.value +
      ")</span> -- ";
    let keys = [];
    let names = [];
    for (var key of Object.keys(state_results.candidatesByID)) {
      keys.push(key);
      names.push(state_results.candidatesByID[key].lastName.toUpperCase());
      let pct = Math.round(state_results.probsByCandID[key] * 10000) / 100;
      newTableData +=
        '<span style="color:black;">' +
        state_results.candidatesByID[key].lastName +
        ": " +
        pct +
        "%</span>, ";
    }
    newTableData = newTableData.substring(0, newTableData.length - 2);
    newTableData += '</p><table class="table table-condensed ea-eo-overview">';
    newTableData +=
      '<thead class="ea-eo-labels"><th>POLL ORGANIZATION</th><th>POLL DATE</th><th>POLL SAMPLE</th><th>' +
      names[0] +
      "</th><th>" +
      names[1] +
      "</th><th>WEIGHT</th></thead>";
    let polls = races.polls;
    polls.reverse();
    polls.sort((a, b) => {
      return a.startDate.isBefore(b.startDate);
    });
    let n = 0;
    for (let poll in polls) {
      let p = polls[poll];
      let newTableRow = "<tr>";
      newTableRow +=
        '<td class="ea-eo-same">' + p.organizations[0].name + "</td>";
      newTableRow +=
        '<td class="ea-eo-same">' + moment(p.startDate).format("ll") + "</td>";
      newTableRow +=
        '<td class="ea-eo-same">' + p.size + " " + p.voterType + "</td>";
      newTableRow += '<td class="ea-eo-dem">' + p.responses[keys[0]] + "</td>";
      newTableRow += '<td class="ea-eo-rep">' + p.responses[keys[1]] + "</td>";
      newTableRow +=
        '<td class="ea-eo-same">' + this.calcWeight(p, n) + "</td>";
      newTableRow += "</tr>";
      if (this.calcWeight(p, n) !== 0) {
        n++;
        newTableData += newTableRow;
      }
    }
    if (polls.length === 0)
      newTableData += '<tr><td class="ea-eo-same">No recent polls</td></tr>';
    newTableData += "</table></div>";
    document.getElementById("infopane").innerHTML = newTableData;
  }

  calcWeight(p, n) {
    let timeWeight = moment(p.startDate);
    let forecastTime = moment(this._dataConfig.time);
    let onewk = moment(forecastTime).subtract(7, "d");
    let twowk = moment(forecastTime).subtract(14, "d");
    if (timeWeight.isAfter(onewk) && timeWeight.isBefore(forecastTime)) {
      return 1;
    }
    if (
      (timeWeight.isAfter(twowk) && timeWeight.isBefore(forecastTime)) ||
      n < 3
    ) {
      return 0.5;
    }
    return 0;
  }

  update(newDataConfig) {
    //this is an insanely lazy update function
    //this.recreateMap(this._element, newDataConfig);

    $("#map").innerHTML = "";
    this.constructor($("#map"), newDataConfig);
  }

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      //let oldTime = this._dataConfig.time;
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      //if ('time' in this._dataConfig) this._dataConfig.time = oldTime;

      // Do an automatic update of _locResults; caching makes it cheap
      this._locResults = probs.computeLocationResults(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      this.setData = true;
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error(errorInfo);
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecastsAndPolls(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  error(e) {
    console.error(e);
    console.trace();
  }
}

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaMap = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var maps = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    maps.push(new EAMap($element, myOptions));
  });

  return maps;
};

// Somehow not disabling auto loader causes issues
// Autoloader
// $(function() {
//   $(".ea-map").eaMap();
// });
