export type Party = "Democrat" | "DemInd" | "Independent" | "Republican";

export function getProbPartyColor(party: Party, partyProb: number): string {
  if (partyProb >= 0.975) {
    return getStrongPartyColor(party);
  } else if (partyProb >= 0.85) {
    return getPartyColor(party);
  } else if (partyProb >= 0.625) {
    return getWeakPartyColor(party);
  } else {
    return getMixedColor();
  }
}

export const getMarginColor: (v: number) => string = d3
  .scaleLinear()
  .domain([-1, 0, 1])
  .range(["#F64A41", "white", "#318CE7"]);

export function getSteppedMarginColor(d: number): string {
  if (d >= -1 && d <= -0.9) {
    return getMarginColor(-1);
  } else if (d > -0.9 && d <= -0.5) {
    return getMarginColor(-0.7);
  } else if (d > -0.5 && d <= -0.2) {
    return getMarginColor(-0.35);
  } else if (d <= 1 && d >= 0.9) {
    return getMarginColor(1);
  } else if (d < 0.9 && d >= 0.5) {
    return getMarginColor(0.7);
  } else if (d < 0.5 && d >= 0.2) {
    return getMarginColor(0.35);
  } else if (d < 0.2 && d > -0.2) {
    if (d > 0) {
      return getMarginColor(0.1);
    } else if (d < 0) {
      return getMarginColor(-0.1);
    } else {
      return "#ffffff";
    }
  }

  return "#eee";
}

export function getWeakPartyColor(party: Party): string {
  if (party === "Democrat") {
    return "#bad9f7";
  } else if (party === "DemInd") {
    return "#00CED1";
  } else if (party === "Independent") {
    return "#58B661";
  } else if (party === "Republican") {
    return "#fcc3c0";
  } else {
    return "#EEEEEE";
  }
}

export function getPartyColor(party: Party): string {
  if (party === "Democrat") {
    return "#76b2ef";
  } else if (party === "DemInd") {
    return "#1E718C";
  } else if (party === "Independent") {
    return "#1FA72C";
  } else if (party === "Republican") {
    return "#f98680";
  } else {
    return "#CCCCCC";
  }
}

export function getAltPartyColor(party: Party): string {
  if (party === "Democrat") {
    return "#0052A5";
  } else if (party === "DemInd") {
    return "#1E718C";
  } else if (party === "Independent") {
    return "#009321";
  } else if (party === "Republican") {
    return "#E0162B";
  } else {
    return getPartyColor(party);
  }
}

export function getStrongPartyColor(party: Party): string {
  if (party === "Democrat") {
    return "#318CE7";
  } else if (party === "DemInd") {
    return "#1E718C";
  } else if (party === "Independent") {
    return "#16741F";
  } else if (party === "Republican") {
    return "#F64A41";
  } else {
    return "#999999";
  }
}

export function getStrongPartyColorTransparent(party: Party): string {
  if (party === "Democrat") {
    return "rgba(49, 140, 231, 0.7)";
  } else if (party === "DemInd") {
    return "rgba(30, 113, 140, 0.7)";
  } else if (party === "Independent") {
    return "rgba(22,116,31, 0.7)";
  } else if (party === "Republican") {
    return "rgba(246, 74, 65, 0.7)";
  } else {
    return "#999999";
  }
}

export function getMixedColor() {
  return "#800080"; // purple
}

export const gray: string = "#222";
export const lightGray: string = "#999";
export const lighterGray: string = "#adadad";
export const lightererGray: string = "#e6e6e6";
export const lighterererGray: string = "#f8f8f8";
