/* Module: Election Multiview
 * ==========================================================================
 *
 * This module is used to show multiple swing scenarios at once
 * NOTE: data for this module is currently HARDCODED in.
 * TODO: update this module to allow the loading of multiple customized data
 *
 * Usage examples not yet available. Follow example for Election Overview
 * module.
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: dataConfig is currently implemented but not used in this modules
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `showTime`:      Show the forecast time or not. Defaults to false.
 *   - `useCoalitions`: A boolean flag that indicates if coalitions should be
 *                      used or not. It defaults to true (using coalitions).
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";
import * as math from "../services/math";

export class eaElectionMultiview {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-em");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      showTime: false,
      useCoalitions: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }
    if ("showTime" in options) {
      this._moduleConfig.showTime = options.showTime;
    }
    if ("useCoalitions" in options) {
      this._moduleConfig.useCoalitions = options.useCoalitions;
    }

    // First load any necessary data, then initialize HTML and update it.
    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._updateHtml();
      }, this)
    );
  } // end constructor

  _initializeHtml() {
    var $title = $('<h1 class="ea-em-title"></h1>');
    var $notes = $("<p id=notes-block></p>");
    var $controls = $(
      '<form class="form-inline ea-em-controls">' +
        "<br><span>Independent candidates are:</span>" +
        '<div class="form-group">' +
        '<div class="btn-group" data-toggle="buttons">' +
        '<button class="btn btn-default">' +
        '<input type="radio" name="coalitionsSelector" class="ea-em-selector ea-em-coalitionsSelector" value="true">' +
        "Combined" +
        "</button>" +
        '<button class="btn btn-default">' +
        '<input type="radio" name="coalitionsSelector" class="ea-em-selector ea-em-coalitionsSelector" value="false">' +
        "Separated" +
        "</button>" +
        "</div>" +
        "</div>" +
        '<span class="glyphicon glyphicon-question-sign popover-trigger ea-em-controlExplanation" style="vertical-align: middle"></span>' +
        "</form>"
    );
    var $time = $('<p id="forecastTime"></p>');
    var $multiview = $(
      '<table class="table table-condensed ea-em-multiview">' +
        "<thead>" +
        '<tr class="ea-em-labels">' +
        '<th class="ea-em-rowTitle"><span></span></th>' +
        '<th class="ea-em-dem-head"><span></span></th>' +
        '<th class="ea-em-demind-head"><span></span></th>' +
        '<th class="ea-em-ind-head"><span></span></th>' +
        '<th class="ea-em-rep-head"><span></span></th>' +
        "</tr>" +
        "</thead>" +
        "<tbody>" +
        '<tr class="ea-em-expected">' +
        '<td class="ea-em-rowTitle"><span></span></td>' +
        '<td class="ea-em-dem"><span></span></td>' +
        '<td class="ea-em-demind"><span></span></td>' +
        '<td class="ea-em-ind"><span></span></td>' +
        '<td class="ea-em-rep"><span></span></td>' +
        "</tr>" +
        '<tr class="ea-em-expectedDem20">' +
        '<td class="ea-em-rowTitle"><span></span></td>' +
        '<td class="ea-em-dem"><span></span></td>' +
        '<td class="ea-em-demind"><span></span></td>' +
        '<td class="ea-em-ind"><span></span></td>' +
        '<td class="ea-em-rep"><span></span></td>' +
        "</tr>" +
        '<tr class="ea-em-expectedRep20">' +
        '<td class="ea-em-rowTitle"><span></span></td>' +
        '<td class="ea-em-dem"><span></span></td>' +
        '<td class="ea-em-demind"><span></span></td>' +
        '<td class="ea-em-ind"><span></span></td>' +
        '<td class="ea-em-rep"><span></span></td>' +
        "</tr>" +
        "</tbody>" +
        "</table>"
    );

    this._titleModule = $title.eaTitle({
      format: "<b>{type} Election Scenarios</b>",
    })[0];

    if (this._moduleConfig.useCoalitions) {
      $controls
        .find(".ea-em-coalitionsSelector[value='true']")
        .prop("checked", true)
        .parent()
        .addClass("active");
    } else {
      $controls
        .find(".ea-em-coalitionsSelector[value='false']")
        .prop("checked", true)
        .parent()
        .addClass("active");
    }

    $controls.change(
      $.proxy(function () {
        this._moduleConfig.useCoalitions =
          $controls.find(".ea-em-coalitionsSelector:checked").val() === "true";
        this._updateHtml();
      }, this)
    );

    // ensure popovers can't overlay eachother- show 1 at a time
    var popoverTriggers = $multiview.find(
      ".ea-em-rowTitle > span, .ea-em-labels .ea-em-demind-head > span"
    );
    $.merge(popoverTriggers, $controls.find(".ea-em-controlExplanation"));

    popoverTriggers.click({ popoverTriggers: popoverTriggers }, function () {
      var $popoverTrigger = $(this);
      popoverTriggers.not($popoverTrigger).popover("hide");
      $popoverTrigger.popover("toggle");
    });

    $(document).click(function (e) {
      if (!popoverTriggers.is(e.target)) {
        popoverTriggers.popover("hide");
      }
    });

    var makePopover = function ($elem, options) {
      var defaultPopoverOptions = {
        placement: "right",
        trigger: "manual",
        html: true,
      };
      _.defaults(options, defaultPopoverOptions);
      $elem.popover(options);
    };
    makePopover($controls.find(".ea-em-controlExplanation"), {
      container: ".ea-em-controlExplanation",
      title: "Election Multiview Controls",
      content:
        "<div>" +
        "The <em>Combined</em> option will include each Independent candidate in the party with which he or she is most likely to caucus in the Senate. (Currently all Independents caucus with the Democrats.)  The <em>Separated</em> option will show Independents in their own category." +
        "</div>",
    });
    makePopover($multiview.find(".ea-em-labels .ea-em-demind-head > span"), {
      content: $.proxy(function () {
        if (this._dataConfig.year == 2012) {
          return "The two competitive independent candidates are expected to caucus with the Democrats, so they are included here when determining seat totals.";
        } else if (this._dataConfig.year == 2014) {
          return "The current independent senators caucus with the Democrats, so they are included here when determining seat totals.";
        } else {
          return '<div style="font-variant: normal; text-transform: none;">The independent candidates and senators are expected to caucus with the Democrats, so they are included here when determining seat totals.</div>';
        }
      }, this),
    });
    makePopover($multiview.find(".ea-em-expected .ea-em-rowTitle > span"), {
      content: $.proxy(function () {
        if (this._dataConfig.type == "president") {
          return 'The expected number of Electoral Votes for a candidate in <a href="details.php?raceOpt=senate" target="new"><em>Neutral Scenario</em></a>. Read more about <a href="/faq.php#custom" target="new">turnout scenario here</a>.';
        } else {
          return '<div style="font-variant: normal; text-transform: none;">The expected number of seats that a party will hold after the election in a neutral scenario. Read more about <a href="/faq.php#custom" target="new">turnout scenario here</a>.</div>';
        }
      }, this),
    });
    makePopover(
      $multiview.find(".ea-em-expectedDem20 .ea-em-rowTitle > span"),
      {
        content: $.proxy(function () {
          if (this._dataConfig.type == "president") {
            return 'The expected number of Electoral Votes for a candidate in <a href="details.php?swgScn=dem20&raceOpt=senate" target="new"><em>Very Strong Democrat Turnout Scenario</em></a>. Read more about <a href="/faq.php#custom" target="new">turnout scenario here</a>.';
          } else {
            return '<div style="font-variant: normal; text-transform: none;">The expected number of seats that a party will hold after the election in very strong Democrat turnout scenario. Read more about <a href="/faq.php#custom" target="new">turnout scenario here</a>.</div>';
          }
        }, this),
      }
    );
    makePopover(
      $multiview.find(".ea-em-expectedRep20 .ea-em-rowTitle > span"),
      {
        content: $.proxy(function () {
          if (this._dataConfig.type == "president") {
            return 'The expected number of Electoral Votes for a candidate in <a href="details.php?swgScn=rep20&raceOpt=senate" target="new"><em>Very Strong Republican Turnout Scenario</em></a>. Read more about <a href="/faq.php#custom" target="new">turnout scenario here</a>.';
          } else {
            return '<div style="font-variant: normal; text-transform: none;">The expected number of seats that a party will hold after the election in a very strong Republican turnout scenario. Read more about <a href="/faq.php#custom" target="new">turnout scenario here</a>.</div>';
          }
        }, this),
      }
    );

    this._$element
      .append($title)
      .append($time)
      .append($controls)
      .append($multiview)
      .append($notes);
  } // end _initializeHtml

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    // NOTE: insert data config info

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  /*
   * Function that updates HTML elements and display for module. Called
   * after all data has been retrieved and stabilized.
   */
  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });
      this._$element.find(".ea-em-title").show();
    } else {
      this._$element.find(".ea-em-title").hide();
    }
    if (this._moduleConfig.showTime) {
      this._$element
        .find("#forecastTime")
        .html(
          "<span class='ea-em-update'> Updated: " +
            this._dataConfig.time.format("MMM DD, YYYY @ hh:mm A") +
            "</span>"
        );
      this._$element.find("#forecastTime").show();
    } else {
      this._$element.find("#forecastTime").hide();
    }

    this._updateTable();
    this._updateCoalitionsControl();
    return;
  } // end _updateHtml

  _updateTable() {
    var stats = { isFinal: false };
    // NOTE: hard code data. Do the daily update here.
    var expNeutr = {
      Democrat: 0.0,
      Republican: 0.0,
      Independent: 0.0,
      DemInd: 0.0,
    };
    var expDem20 = {
      Democrat: 0.0,
      Republican: 0.0,
      Independent: 0.0,
      DemInd: 0.0,
    };
    var expRep20 = {
      Democrat: 0.0,
      Republican: 0.0,
      Independent: 0.0,
      DemInd: 0.0,
    };

    var labels = {
      Democrat: "Democrats",
      Republican: "Republicans",
      Independent: "Independents",
      DemInd: "Dems & Inds",
    };

    if (
      this._dataConfig.type === "president" &&
      this._dataConfig.year == 2008 &&
      stats.isFinal
    ) {
      this._$element
        .find("#notes-block")
        .html(
          "<small>(Note: Obama won NE-02 while McCain won the " +
            "remaining four electoral votes for Nebraska.)</small>"
        );
    } else {
      this._$element.find("#notes-block").empty();
    }

    // update row titles
    var rowTitles = null;
    if (this._dataConfig.type == "president") {
      rowTitles = {
        header: "Candidate",
        expected: "Expected Electoral Votes",
        expectedDem20:
          "Expected Electoral Votes With Very Strong Democrat Turnout",
        expectedRep20:
          "Expected Electoral Votes With Very Strong Republican Turnout",
      };
    } else {
      rowTitles = {
        header: "",
        expected: "Neutral Turnout",
        expectedDem20: "Very Strong Democrat Turnout",
        expectedRep20: "Very Strong Republican Turnout",
      };
    }
    if (stats.isFinal) {
      if (this._dataConfig.type == "president") {
        rowTitles.expected = "Total Electoral Votes";
      } else {
        rowTitles.expected = "Total Seats";
      }
    }
    // Set the titles
    this._$element
      .find(".ea-em-labels .ea-em-rowTitle > span")
      .text(rowTitles.header);
    this._$element
      .find(".ea-em-expected .ea-em-rowTitle > span")
      .text(rowTitles.expected);
    this._$element
      .find(".ea-em-expectedDem20 .ea-em-rowTitle > span")
      .text(rowTitles.expectedDem20);
    this._$element
      .find(".ea-em-expectedRep20 .ea-em-rowTitle > span")
      .text(rowTitles.expectedRep20);

    // Update the cells for each party
    var parties = ["Democrat", "DemInd", "Independent", "Republican"];
    var partyAbbrevs = ["dem", "demind", "ind", "rep"];
    var nParties = parties.length;
    for (var j = 0; j < nParties; ++j) {
      var party = parties[j];
      var pClass = ".ea-em-" + partyAbbrevs[j];

      this._$element
        .find(".ea-em-labels " + pClass + "-head" + " > span")
        .text(labels[party]);
      if (stats.isFinal) {
        this._$element
          .find(".ea-em-expected " + pClass)
          .text(math.sformat(expNeutr[party], 0));
        this._$element
          .find(".ea-em-expectedDem20 " + pClass)
          .text(math.sformat(expDem20[party], 0));
        this._$element
          .find(".ea-em-expectedRep20 " + pClass)
          .text(math.sformat(expRep20[party], 0));
      } else {
        this._$element
          .find(".ea-em-expected " + pClass)
          .text(math.sformat(expNeutr[party], 2));
        this._$element
          .find(".ea-em-expectedDem20 " + pClass)
          .text(math.sformat(expDem20[party], 2));
        this._$element
          .find(".ea-em-expectedRep20 " + pClass)
          .text(math.sformat(expRep20[party], 2));
      }
    }

    // Determine which elements should be shown
    if (this._dataConfig.type === "president") {
      // Show and hide columns
      this._$element.find(".ea-em-dem").show();
      this._$element.find(".ea-em-demind").hide();
      this._$element.find(".ea-em-ind").hide();
      this._$element.find(".ea-em-rep").show();
    } else {
      if (this._moduleConfig.useCoalitions === true) {
        // Show and hide columns
        this._$element.find("[class^=ea-em-dem]").hide();
        this._$element.find("[class^=ea-em-demind]").show();
        this._$element.find("[class^=ea-em-ind]").hide();
        this._$element.find("[class^=ea-em-rep]").show();
      } else {
        // Show and hide columns
        this._$element.find("[class^=ea-em-dem]").show();
        this._$element.find("[class^=ea-em-demind]").hide();
        this._$element.find("[class^=ea-em-ind]").show();
        this._$element.find("[class^=ea-em-rep]").show();
      }
    }
    return;
  } // end _updateTable

  _updateCoalitionsControl() {
    var $yesCoalitions = this._$element.find(
      ".ea-em-coalitionsSelector[value='true']"
    );
    var $noCoalitions = this._$element.find(
      ".ea-em-coalitionsSelector[value='false']"
    );

    if (this._dataConfig.type === "president") {
      $yesCoalitions.parent().attr("disabled", true);
      $noCoalitions.parent().attr("disabled", true);
      this._$element.find(".ea-em-controls").hide();
    } else {
      $yesCoalitions.parent().removeAttr("disabled");
      $noCoalitions.parent().removeAttr("disabled");
      this._$element.find(".ea-em-controls").show();
    }
  } // end _updateCoalitionsControl

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Election Overview robot... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The Election Multiview module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Election Multiview module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Election Multiview module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Election Multiview module's constructor requires a `year` option."
      );
    } else {
      console.warn(
        "An unknown error occurred in the Election Multiview module."
      );
    }

    // TODO: notify devs
  } // end error
} // end EAElectionOverview.prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaElectionMultiview = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var multiviews = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    multiviews.push(new eaElectionMultiview($element, myOptions));
  });

  return multiviews;
};

// Autoloader
$(function () {
  $(".ea-election-multiview").eaElectionMultiview();
});
