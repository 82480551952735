import _ from "underscore";

/**
 * # Cache Service
 * An object that handles the caching of data, including saving in and loading
 * from local storage.
 *
 * **Name Spaces**
 *
 * The cache's keys are called namespaces, and are an array of strings.  This
 * allows the calling code to request a lower namespace and get back a larger
 * portion of the cache, rather than having to iterate through multiple keys
 * to get all the data it needs.
 *
 * For example, a cache could be namespaced by the inserting function and its
 * parameters (`this.set(['myFunction', 'type', 'year'])`).  This allows that
 * function to have its own portion of the cache, as well as access all data
 * for a given race type (`this.get(['myFunction', 'type']`).
 *
 * **Local Storage**
 *
 * Any changes to the cache can be propagated to local storage.  The whole
 * cache object will be stringified as json and stored at a specified key.
 *
 * You can turn on saving to local storage in the cache's constructor or the
 * `enableLocalStorage()` method.  You can turn it off through the
 * `disableLocalStorage()` method.
 *
 */
export class Cache {
  private cache: { [key in string]: Cache | any } = {};
  private useLocalStorage: boolean = false;
  private localStorageKey: string | null = null;

  constructor(localStorageKey?: string) {
    if (localStorageKey) {
      // truthy
      this.enableLocalStorage(
        localStorageKey,
        this.loadLocalStorage.bind(this)
      );
    }
  }

  /**
   * Enable Local Storage
   * @param localStorageKey The key in localStorage that the cache will be
   * stored at
   *
   * @param handleExisting A function, called if there is an existing entry
   * in localStorage for the given key.  It is called with three
   * parameters: the Cache instance, the localStorageKey,
   * and its current value.  After the handleExisting function is
   * called the Cache object will be free to change or remove any
   * value left in localStorage for the key.  Existing functions in
   * Cache can be used for common scenarios, for example passing in
   * `Cache.loadLocalStorage` will replace its cache with the
   * existing value.  This is what the constructor does.
   *
   * If `handleExisting` is not a function (or undefined) then
   * the cache instance will skip dealing with the exising value
   * and go right to overwriting it.
   */
  enableLocalStorage(
    localStorageKey: string,
    handleExisting: (...params: any[]) => void
  ): void {
    // preconditions
    if (!localStorageKey) {
      // falsey
      throw new Error(
        "Cache.enableLocalStorage was called without a local storage key to use."
      );
    }

    this.useLocalStorage = true;
    this.localStorageKey = localStorageKey;

    let currentValue = JSON.parse(localStorage.getItem(this.localStorageKey!)!);
    if (currentValue !== null && _.isFunction(handleExisting)) {
      handleExisting(this, localStorageKey, currentValue);
    }

    this.setLocalStorage();
  }

  /**
   * Disable Local Storage ###
   *
   * @param scrub A flag that will remove the Cache's localStorage entry.
   * Useful for memory management or privacy, however if the
   * localStorage value is scrubbed then the cache can not
   * re-initialize on the next page load unless `enableLocalStorage`
   * is called
   */
  disableLocalStorage(scrub: boolean): void {
    if (scrub) {
      // truthy
      localStorage.removeItem(this.localStorageKey!);
    }

    this.useLocalStorage = false;
    this.localStorageKey = null;
  }

  /**
   * Load data from local storage
   *
   * @returns true if this instance use local storage
   */
  loadLocalStorage(): boolean {
    if (this.useLocalStorage && this.localStorageKey) {
      this.cache = JSON.parse(localStorage.getItem(this.localStorageKey!)!);
    }

    return this.useLocalStorage;
  }

  /**
   * Try to serialize existing cache into local storage
   *
   * @returns true if this instance use local storage
   */
  setLocalStorage(): boolean {
    if (this.useLocalStorage) {
      localStorage.setItem(this.localStorageKey!, JSON.stringify(this.cache));
    }

    return this.useLocalStorage;
  }

  /**
   * Set a value in a name space
   * @param namespace an array of string where each string represent an element
   * of the namespace
   * @param value the value to add to the namespace
   */
  set(namespace: string[], value: any): void {
    // preconditions
    if (namespace.length === 0) {
      throw new Error("Cache.set was called with an empty namespace.");
    }

    if (value === undefined) {
      throw new Error("Cache.set was called without a value.");
    }

    let inSpace = this.cache;

    for (let i = 0; i < namespace.length - 1; i++) {
      let nextSpace = namespace[i];

      if (!(nextSpace in inSpace)) {
        inSpace[nextSpace] = {};
      }

      inSpace = inSpace[nextSpace];
    }

    let nextSpace = namespace[namespace.length - 1];
    inSpace[nextSpace] = value;

    this.setLocalStorage();
  }

  /**
   * Retreive value from a namespace
   * @param namespace the namespace
   */
  get(namespace: string[]): any | null {
    // preconditions
    if (namespace.length == 0) {
      throw new Error("Cache.set was called with an empty namespace.");
    }

    let inSpace = this.cache;

    for (let i = 0; i < namespace.length; i++) {
      let nextSpace = namespace[i];

      if (!(nextSpace in inSpace)) {
        // for consistency with localStorage.getItem
        return null;
      }

      inSpace = inSpace[nextSpace];
    }

    return inSpace;
  }

  /**
   * Remove a namespace
   * @param namespace an array of string where each string represent an element
   * of the namespace
   */
  remove(namespace: string[]): void {
    // preconditions
    if (namespace.length === 0) {
      throw new Error("Cache.set was called with an emtpy namespace.");
    }

    let inSpace = this.cache;

    for (let i = 0; i < namespace.length - 1; i++) {
      let nextSpace = namespace[i];

      if (!(nextSpace in inSpace)) {
        throw new Error(
          "Cache.remove cannot remove the item in the given namespace- it doesn't exist."
        );
      }

      inSpace = inSpace[nextSpace];
    }

    let nextSpace = namespace[namespace.length - 1];

    if (!(nextSpace in inSpace)) {
      throw new Error(
        "Cache.remove cannot remove the item in the given namespace- it doesn't exist."
      );
    }

    delete inSpace[nextSpace];

    this.setLocalStorage();
  }
}
