import React, { Component } from "react";
import ReactDOM from "react-dom";
import { css } from "@emotion/core";
import Row from "./Row";
import Table from "../Table";
import TableHead from "../TableHead";
import TableHeaderCell from "../TableHeaderCell";
import TableBody from "../TableBody";
import * as util from "../../../services/util";
import * as data from "../../../services/data";
import * as probs from "../../../services/probs";
import * as election from "../../../services/election";
import * as math from "../../../services/math";
import promisify from "../../../utils/promisify";

export interface ElectionOverviewProps {
  options: any;
}

interface Category {
  title: string;
  blue: string;
  red: string;
  tooltip?: JSX.Element;
}

interface ElectionOverviewState {
  blueTitle: string;
  redTitle: string;
  categories: Category[];
}

let toolTipContainerCss = css`
  text-align: left;
`;

let toolTipLinkCss = css`
  color: #bad9f7;
  pointer-events: auto;

  :active {
    color: white;
  }

  :hover {
    color: #76b2ef;
  }
`;

class ElectionOverview extends Component<
  ElectionOverviewProps,
  ElectionOverviewState
> {
  static defaultProps = {
    options: {},
  };

  dataConfig: any;
  moduleConfig: any;
  races: any;

  constructor(props: any) {
    super(props);

    this.state = {
      blueTitle: "Dem & Ind",
      redTitle: "Republicans",
      categories: [],
    };

    const { options } = this.props;

    this.dataConfig = util.createDataConfigFromOptions(options);
    this.moduleConfig = {
      showTitle: false,
      showTime: false,
      useCoalitions: true,
      usePercentage: true,
    };

    if ("showTitle" in options) {
      this.moduleConfig.showTitle = options.showTitle;
    }

    if ("showTime" in options) {
      this.moduleConfig.showTime = options.showTime;
    }

    if ("useCoalitions" in options) {
      this.moduleConfig.useCoalitions = options.useCoalitions;
    }
  }

  private getCategories(stats: any): Category[] {
    const { isFinal } = stats;
    let probText = this.moduleConfig.usePercentage ? "Chance" : "Probability";
    let { majority, superMajority, half } = election.control[
      this.dataConfig.type as "president" | "senate"
    ];

    const expectedDecimal = isFinal ? 0 : 1;

    switch (this.dataConfig.type) {
      case "president":
        return [
          {
            title: isFinal
              ? "Total Electoral Votes"
              : "Expected Electoral Votes",
            blue: math.sformat(stats.info.Democrat.expected, expectedDecimal),
            red: math.sformat(stats.info.Republican.expected, expectedDecimal),
            tooltip: (
              <div css={toolTipContainerCss}>
                The expected number of Electoral Votes for a candidate. You can
                read more about the expected value on{" "}
                <a
                  css={toolTipLinkCss}
                  href="http://en.wikipedia.org/wiki/Expected_value"
                  target="new"
                >
                  Wikipedia
                </a>
              </div>
            ),
          },
          {
            title: "Standard Deviation",
            blue: math.sformat(stats.info.Democrat.stddev, 2),
            red: math.sformat(stats.info.Republican.stddev, 2),
            tooltip: (
              <div css={toolTipContainerCss}>
                The <em>Standard Deviation</em> is a measure of the variability
                in the <em>Expected Electoral Votes</em>. You can read more
                about Standard Deviations{" "}
                <a
                  css={toolTipLinkCss}
                  href="http://en.wikipedia.org/wiki/Standard_deviation"
                  target="new"
                >
                  on Wikipedia
                </a>
              </div>
            ),
          },
          {
            title: "Safe Electoral Votes",
            blue: stats.info.Democrat.safe,
            red: stats.info.Republican.safe,
            tooltip: (
              <div css={toolTipContainerCss}>
                The number of Electoral Votes that a candidate has a high
                probability (&ge; 85%) of receiving.
              </div>
            ),
          },
          {
            title: `${probText} of Winning (270+ Electoral Votes)`,
            blue: math.formatProbability(stats.info.Democrat.pWin),
            red: math.formatProbability(stats.info.Republican.pWin),
            tooltip: (
              <div css={toolTipContainerCss}>
                The {probText.toLowerCase()} of a candidate winning {majority}{" "}
                or more electoral votes, and thus winning the election.
              </div>
            ),
          },
          {
            title: `${probText} of Receiving 300+ Electoral Votes`,
            blue: math.formatProbability(stats.info.Democrat.pSuper),
            red: math.formatProbability(stats.info.Republican.pSuper),
            tooltip: (
              <div css={toolTipContainerCss}>
                The {probText.toLowerCase()} of a candidate winning{" "}
                {superMajority} or more electoral votes, and thus clearly
                winning the election
              </div>
            ),
          },
          {
            title: `${probText} of Tie (269 Electoral Votes each)`,
            blue: math.formatProbability(stats.info.Democrat.pHalf),
            red: math.formatProbability(stats.info.Republican.pHalf),
            tooltip: (
              <div css={toolTipContainerCss}>
                The {probText.toLowerCase()} of the Democrat and Republican
                candidates each receiving {half} electoral votes.
              </div>
            ),
          },
        ];
      case "senate":
        return [
          {
            title: isFinal ? "Total Seats" : "Expected Seats",
            blue: math.sformat(stats.info.DemInd.expected, expectedDecimal),
            red: math.sformat(stats.info.Republican.expected, expectedDecimal),
            tooltip: (
              <div css={toolTipContainerCss}>
                The expected number of seats that a party will hold after the
                election. You can read more about the expected value on{" "}
                <a
                  css={toolTipLinkCss}
                  href="http://en.wikipedia.org/wiki/Expected_value"
                  target="new"
                >
                  Wikipedia
                </a>
                .
              </div>
            ),
          },
          {
            title: "Standard Deviation",
            blue: math.sformat(stats.info.DemInd.stddev, 1),
            red: math.sformat(stats.info.Republican.stddev, 1),
            tooltip: (
              <div css={toolTipContainerCss}>
                The <em>Standard Deviation</em> is a measure of the variability
                in the <em>Expected Seats</em>. You can read more about Standard
                Deviations{" "}
                <a
                  css={toolTipLinkCss}
                  href="http://en.wikipedia.org/wiki/Standard_deviation"
                  target="new"
                >
                  on Wikipedia
                </a>
                .
              </div>
            ),
          },
          {
            title: "Safe Seats",
            blue: stats.info.DemInd.safe,
            red: stats.info.Republican.safe,
            tooltip: (
              <div css={toolTipContainerCss}>
                The number of open seats that a party has a high probability
                (&ge; 85%) of securing.
              </div>
            ),
          },
          {
            title: "Seats Not Up",
            blue: stats.info.DemInd.notUp,
            red: stats.info.Republican.notUp,
            tooltip: (
              <div css={toolTipContainerCss}>
                Senate races have{" "}
                <a
                  css={toolTipLinkCss}
                  href="https://en.wikipedia.org/wiki/United_States_Senate#Term"
                  target="new"
                >
                  staggered elections
                </a>
                , so only some seats are up for election every two years
              </div>
            ),
          },
          {
            title: `${probText} of Majority (51+ Seats)`,
            blue: math.formatProbability(stats.info.DemInd.pWin),
            red: math.formatProbability(stats.info.Republican.pWin),
            tooltip: (
              <div css={toolTipContainerCss}>
                The {probText} of a party holding {majority} or more seats after
                the election, and thus securing a{" "}
                <a
                  css={toolTipLinkCss}
                  href="https://en.wikipedia.org/wiki/United_States_Senate#Majority_and_minority_parties"
                  target="new"
                >
                  majority
                </a>
                .
              </div>
            ),
          },
          {
            title: `${probText} of Supermajority (60+ Seats)`,
            blue: math.formatProbability(stats.info.DemInd.pSuper),
            red: math.formatProbability(stats.info.Republican.pSuper),
            tooltip: (
              <div css={toolTipContainerCss}>
                The {probText} of a party holding
                {superMajority} or more seats after the election, and thus
                securing a{" "}
                <a
                  css={toolTipLinkCss}
                  href="https://en.wikipedia.org/wiki/Supermajority#United_States"
                  target="new"
                >
                  super majority
                </a>
                .
              </div>
            ),
          },
          {
            title: `${probText} of Tie (50 Seats each)`,
            blue: math.formatProbability(stats.info.DemInd.pHalf),
            red: math.formatProbability(stats.info.Republican.pHalf),
            tooltip: (
              <div css={toolTipContainerCss}>
                The {probText} of a party holding exactly {half} seats after the
                election, or half of the total.
              </div>
            ),
          },
        ];
      default:
        return [];
    }
  }

  private updateTable(): void {
    let stats = probs.computeStats(this.dataConfig, this.races);

    let labels = election.getPartyLabels(
      this.dataConfig.type,
      (Object.values(this.races) as any)[0][0].candidates
    );

    const { type } = this.dataConfig.type;

    this.setState((state, props) => ({
      blueTitle: type == "president" ? labels.Democrat : labels.DemInd,
      redTitle: labels.Republican,
      categories: this.getCategories(stats),
    }));
  }

  private async updateData(): Promise<void> {
    // Get Promise object from data service API
    try {
      this.races = await promisify(data.getRacesWithForecasts(this.dataConfig));
      this.dataConfig.time = util.validateDataConfigTime(
        this.dataConfig,
        this.races
      );

      this.updateTable();
    } catch (error) {
      if (error.statusCode === 400 && error.statusText === "Invalid type") {
        this.error("invalid type");
      } else {
        this.error("");
      }
    }
  }

  private error(reason: string): void {
    console.error(reason);
  }

  public update(newDataConfig: any): void {
    this.dataConfig = newDataConfig;
    this.updateData();
  }

  public componentDidMount(): void {
    this.updateData();
  }

  public render() {
    const { blueTitle, redTitle, categories } = this.state;

    return (
      <div>
        <Table
          css={css`
            width: 100%;
            max-width: 100%;
            margin-bottom: 20px;
          `}
        >
          <TableHead>
            <TableHeaderCell leftBordered={false} />
            <TableHeaderCell>
              <span>{blueTitle}</span>
            </TableHeaderCell>
            <TableHeaderCell rightBordered={false}>
              <span>{redTitle}</span>
            </TableHeaderCell>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => {
              return (
                <Row
                  key={index}
                  title={category.title}
                  blue={category.blue}
                  red={category.red}
                  tooltip={category.tooltip}
                ></Row>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export function mount(
  container: HTMLElement,
  props: ElectionOverviewProps
): ElectionOverview {
  let ref = React.createRef<ElectionOverview>();
  ReactDOM.render(<ElectionOverview ref={ref} {...props} />, container);

  if (!ref.current) {
    throw new Error("unable to obtain ref");
  }

  return ref.current;
}

export default ElectionOverview;
