/* Module: display-polls
 * ==========================================================================
 *
 * The Display Polls module displays all polls for the selected state (chosen
 * from a drop-down menu).
 *
 * Usage example available at:
 *      site/dynamic/template/module/display-polls.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `selRaceLocAbbrev`: Race location for selector
 *   - `selRaceIndexInLoc`: Race index for selector
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";

export class EADisplayPolls {
  /* Object Definition + Constructor
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-display-polls");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._updateHtml();
      }, this)
    );
  } // end constructor

  _initializeHtml() {
    var $title = $('<h1 class="ea-display-polls-title"></h1>');

    this._titleModule = $title.eaTitle({
      format: "Polls for the {year} {type} Election",
    })[0];

    // Create drop-down selector for choosing races
    var $raceSelect = $('<select class="ea-display-polls-dropdown"></select>');
    var callUpdate = $.proxy(function () {
      var selInfo = $raceSelect.val().split(",");
      // Tag is labeled as "locAbbrev,index"
      this._moduleConfig.selRaceLocAbbrev = selInfo[0];
      this._moduleConfig.selRaceIndexInLoc = selInfo[1];
      $raceSelect.prop("disabled", true);
      this._updateHtml();
    }, this);

    $raceSelect.change(callUpdate).prop("disabled", true);

    // Create polls table with header info
    var $pollsTable = $(
      '<table class="table table-striped ea-display-polls-table">' +
        "<thead>" +
        "<tr>" +
        "<th>Poll</th>" +
        "<th>Start<br>Date</th>" +
        "<th>End<br>Date</th>" +
        "<th>Time<br>Added</th>" +
        "<th>Size</th>" +
        "<th>Type</th>" +
        "<th>Responses</th>" +
        "</tr>" +
        "</thead>" +
        "<tbody></tbody>" +
        "</table>"
    );

    this._$element.append($title).append($raceSelect).append($pollsTable);
  } // end _initializeHtml

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    this._$element.find(".ea-display-polls-dropdown").prop("disabled", true);

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
        this._$element
          .find(".ea-display-polls-dropdown")
          .prop("disabled", false);
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsAndPollsPromise = data.getRacesWithForecastsAndPolls(
      this._dataConfig
    );
    racesWithForecastsAndPollsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  /*
   * Function that updates HTML elements and display for module. Called
   * after all data has been retrieved and stabilized.
   */
  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-display-polls-title").show();
    } else {
      this._$element.find(".ea-display-polls-title").hide();
    }

    // Populate the drop-down element with the races in this election
    this._updateRaceSelector();

    // Populate the polls table with the polls from the selected race
    this._updatePollsTable();

    this._$element.find(".ea-display-polls-dropdown").prop("disabled", false);
  } // end _updateHtml

  _updateRaceSelector() {
    // Add all of the races to the race selector
    var $raceSelector = this._$element.find(".ea-display-polls-dropdown");
    $raceSelector.empty(); //first want to empty the races
    $.each(this._races, function (locAbbrev, racesInLocation) {
      for (var i = 0; i < racesInLocation.length; i++) {
        var currRace = racesInLocation[i];
        $raceSelector.append(new Option(currRace.name, locAbbrev + "," + i));
        // labeling by both locAbbrev and index
      }
    });

    // If a race is specified in options, make it selected
    var selLocAbbrev = null;
    var indexInLoc = 0;
    if (this._moduleConfig.selRaceLocAbbrev) {
      selLocAbbrev = this._moduleConfig.selRaceLocAbbrev;
      if (this._moduleConfig.selRaceIndexInLoc) {
        indexInLoc = this._moduleConfig.selRaceIndexInLoc;
      }
    } else {
      selLocAbbrev = _.keys(this._races)[0];
    }
    // Set the selected race in the drop-down element
    $raceSelector.val(selLocAbbrev + "," + indexInLoc);
  } // end _updateRaceSelector

  _updatePollsTable() {
    // Get the selected race
    var $raceSelector = this._$element.find(".ea-display-polls-dropdown");
    var selInfo = $raceSelector.val().split(",");
    var race = this._races[selInfo[0]][selInfo[1]];
    // Populate the polls table itself
    var $pollsTable = this._$element.find(".ea-display-polls-table");
    var $tbody = $pollsTable.find("tbody");
    $tbody.empty();

    // Build a quick look-up table for the candidates' names
    var cnamesByID = {};
    $.each(race.candidates, function (cIdx, candidate) {
      cnamesByID[candidate.id] = candidate.lastName;
    });

    if (race.polls !== null && race.polls.length > 0) {
      for (var i = race.polls.length - 1; i >= 0; --i) {
        var poll = race.polls[i];
        var orgString = _.pluck(poll.organizations, "name").join(" / ");

        // Determine the poll responses
        var candString = "";
        $.each(poll.responses, function (cID, response) {
          candString += "" + cnamesByID[cID] + ": " + response + "%<br>";
        });
        if (this._dataConfig.time >= poll.timeAdded) {
          $tbody.append(
            "<tr>" +
              "<td>" +
              orgString +
              "</td>" +
              "<td>" +
              poll.startDate.format("M/D/YYYY") +
              "</td>" +
              "<td>" +
              poll.endDate.format("M/D/YYYY") +
              "</td>" +
              "<td>" +
              poll.timeAdded.format("M/D/YYYY") +
              "<br>" +
              poll.timeAdded.format("h:mm A") +
              "</td>" +
              "<td>" +
              poll.size +
              "</td>" +
              "<td>" +
              poll.voterType +
              "</td>" +
              "<td>" +
              candString +
              "</td>" +
              "</tr>"
          );
        }
      }
    } else {
      // No polls
      $tbody.append("<tr>" + '<td colspan="7">(No polls)</td>' + "</tr>");
    }
  } // end _updatePollsTable

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Display-Polls module... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Display-Polls module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Display-Polls module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Display-Polls module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Display-Polls module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Display-Polls module.");
    }
  } // end error
} // end EADisplayPolls prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaDisplayPolls = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var displayPolls = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    displayPolls.push(new EADisplayPolls($element, myOptions));
  });

  return displayPolls;
};

// Autoloader
$(function () {
  $(".ea-display-polls").eaDisplayPolls();
});
