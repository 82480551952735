import { ElectionType } from "./common";

export interface Election {
  half: number;
  majority: number;
  superMajority: number;
}

export interface Control {
  president: Election;
  senate: Election;
}

export interface SenateSeatsNotUp {
  Democrat: number;
  Republican: number;
  Independent: number;
}

export interface Candidate {
  lastName: string;
  party: "Democrat" | "Republican" | "Independent";
}

export interface LabelsByParty {
  Democrat: string;
  Republican: string;
  Independent: string;
  DemInd: string;
}

export interface WithElectionType {
  type: ElectionType;
}

/**
 * @file Election service
 */

// TODO: Need to figure out how to include override for 2008 Presidential
// race, where Obama won District NE-02 and thus had 1 more EV than what
// the current computations will show. (This is because the DP model
// currently doesn't consider districts separately for presidential
// races.)
// NOTE: These may become year-dependent if we consider historical
// elections or additional states join the Union

export const control: Control = {
  president: {
    half: 269,
    majority: 270,
    superMajority: 300,
  },
  senate: {
    half: 50,
    majority: 51,
    superMajority: 60,
  },
};

export const senateSeatsNotUp: { [year in number]: SenateSeatsNotUp } = {
  2012: {
    Democrat: 30,
    Republican: 37,
    Independent: 0,
  },
  2014: {
    Democrat: 32,
    Republican: 30,
    Independent: 2,
  },
  2016: {
    Democrat: 34,
    Republican: 30,
    Independent: 2,
  },
  2018: {
    Democrat: 23,
    Republican: 42,
    Independent: 0,
  },
  2020: {
    Democrat: 33,
    Republican: 30,
    Independent: 2,
  },
};

/**
 * Get a mapping from party to last names
 * @param type type of election
 * @param candidates a list of candidates
 */
export function getPartyLabels(
  type: ElectionType,
  candidates: Candidate[]
): LabelsByParty {
  var labelsByParty: LabelsByParty = {
    Democrat: "Democrats",
    Republican: "Republicans",
    Independent: "Independents",
    DemInd: "Dems & Inds",
  };

  if (type === "president") {
    // Use the set of candidates to determine the names
    let nCands = candidates.length;

    for (let i = 0; i < nCands; ++i) {
      let cand = candidates[i];
      //var cname = cand.firstName + " " + cand.lastName;
      let cname = cand.lastName;
      let candParty = cand.party;

      if (!(cand.party === "Democrat" || cand.party === "Republican")) {
        candParty = "Independent";
      }

      labelsByParty[candParty] = cname; // TODO: doesn't work for multiple independent candidates
    }
  }

  return labelsByParty;
}

export function validateYearForType(type: ElectionType, year: number): number {
  let validYears = getValidYearsForType(type);
  let oldestYear = validYears[validYears.length - 1];
  let newestYear = validYears[0];
  let yearMod = type === "president" ? 4 : 2;

  if (year < oldestYear) {
    return oldestYear;
  } else if (year > newestYear || year % yearMod !== 0) {
    return newestYear;
  } else {
    // Otherwise, no problems with current type and year, so just return
    return year;
  }
}

/**
 * Given an election type, give a list of years valid
 * @param type
 */
export function getValidYearsForType(type: ElectionType): number[] {
  if (type === "president") {
    return [2020, 2016, 2012, 2008];
  } else if (type === "senate") {
    return [2020, 2018, 2016, 2014, 2012];
  } else {
    return [];
  }
}
