/* Module: Data Dumper
 * ==========================================================================
 *
 * The Data Dumper module is designed to export data for an election in a
 * simple format.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/data-dumper.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * ** moduleConfig options
 *  - N/A
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";
import * as probs from "../services/probs";

console.save = function (data, filename) {
  if (!data) {
    console.error("Console.save: No data");
    return;
  }

  if (!filename) {
    filename = "console.json";
  }

  if (typeof data === "object") {
    data = JSON.stringify(data, undefined, 4);
  }

  var blob = new Blob([data], { type: "text/json" });
  var e = document.createEvent("MouseEvents");
  var a = document.createElement("a");

  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
  e.initMouseEvent(
    "click",
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  a.dispatchEvent(e);
};

export class EADataDumper {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-data-dumper");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations. By having to explicitly
    // copy over options, invalid or unsupported options are discarded.
    this._dataConfig = {
      type: options.type,
      year: options.year,
    };
    if ("raceFilter" in options) {
      this._dataConfig.raceFilter = options.raceFilter;
    }
    // NOTE: The data-dumper module does not support a forecast time option or
    // customizations. The latter omission is because of the excessive
    // computational burden that such support would require.

    // No module config needed

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._exportData();
      }, this)
    );
  }

  _initializeHtml() {
    // Nothing done here...
  } // end _initializeHtml

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    // data-dumper module does not support time or customizations
    this._dataConfig = {
      type: newDataConfig.type,
      year: newDataConfig.year,
    };
    if ("raceFilter" in newDataConfig) {
      this._dataConfig.raceFilter = newDataConfig.raceFilter;
    }

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._exportData();
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _exportData() {
    var allData = {
      type: this._dataConfig.type,
      year: this._dataConfig.year,
      //, races: this._races
      nationalForecasts: [],
    };

    var allTimes = util.getAllForecastTimes(this._dataConfig, this._races);
    var nTimes = allTimes.length;
    for (var i = 0; i < nTimes; ++i) {
      // Set the dataConfig to the current forecast time for updating
      this._dataConfig.time = allTimes[i];

      // Do all of the major computations
      var locResults = probs.computeLocationResults(
        this._dataConfig,
        this._races
      );
      var dp = probs.computeDP(this._dataConfig, this._races);
      var stats = probs.computeStats(this._dataConfig, this._races);

      var nationalForecast = {
        time: allTimes[i],
        locResults: locResults,
        dp: dp,
        stats: stats,
      };

      allData.nationalForecasts.push(nationalForecast);

      // Remove time property before next pass
      delete this._dataConfig.time;
    }
    var fname = this._dataConfig.type + "-" + this._dataConfig.year + ".json";
    console.save(allData, fname);
    return;
  } // end _exportData

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Data Dumper follower... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Data Dumper module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Data Dumper module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Data Dumper module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Data Dumper module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Data Dumper module.");
    }
  } // end error
} // end EADataDumper.prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaDataDumper = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var dataDumpers = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    dataDumpers.push(new EADataDumper($element, myOptions));
  });

  return dataDumpers;
};

// Autoloader
$(function () {
  $(".ea-data-dumper").eaDataDumper();
});
