import React, { PureComponent, ReactNode } from "react";
import { jsx, css } from "@emotion/core";
import ReactDOM from "react-dom";

export interface TitleProps {
  variant?: "h1" | "h2" | "h3";
  year: string;
  type: string;
}

class Title extends PureComponent<TitleProps> {
  static defaultProps = {
    variant: "h1",
    year: "2020",
    type: "Presidential",
  };

  public update(): void {}

  public render(): ReactNode {
    const { variant, year, type } = this.props;

    return jsx(
      variant!,
      {
        css: css`
          margin-bottom: 25px;
          text-transform: capitalize;
        `,
      },
      `${year} ${type} Election Forecast`
    );
  }
}

export function mount(container: HTMLElement, props: TitleProps): Title | null {
  let title = React.createRef<Title>();
  ReactDOM.render(<Title ref={title} {...props} />, container);

  return title.current;
}

export default Title;
