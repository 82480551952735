/* Module: Trends Graph
 * ==========================================================================
 *
 * The Trends Graph module lists some basic statistics about an election over
 * time.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/trends-graph.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `useCoalitions`: A boolean flag that indicates if coalitions should be
 *                      used or not. It defaults to true (using coalitions).
 *   - `subsetter`:     Indicates how to choose a subset of races to show.
 *                      Options are `recent` or `overview` (default).
 *   - `usePercentage`: A boolean flag that indicates whether probabilities
 *                      should be displayed as percentage or should be
 *                      displayed as number.
 *                      It defaults to true (display percentage).
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *       `subsetter` is currently not changable at client side.
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as color from "../services/color";
import * as data from "../services/data";
import * as election from "../services/election";
import * as probs from "../services/probs";
import * as util from "../services/util";
import * as math from "../services/math";

export class EATrendsGraph {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-trendsgraph");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations. By having to explicitly
    // copy over options, invalid or unsupported options are discarded.
    this._dataConfig = {
      type: options.type,
      year: options.year,
      pollScale: 0.3,
      swingScenario: "neutral",
    };
    if ("raceFilter" in options) {
      this._dataConfig.raceFilter = options.raceFilter;
    } else if (
      this._dataConfig.type === "president" &&
      this._dataConfig.year == 2016
    ) {
      this._dataConfig.raceFilter = "with-Johnson-Stein";
    }
    // NOTE: The trends module does not support a forecast time option or
    // customizations. The latter omission is because of the excessive
    // computational burden that such support would require.

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      subsetter: "overview",
      usePercentage: true,
      showTie: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }
    if ("subsetter" in options) {
      this._moduleConfig.subsetter = options.subsetter;
    }
    if ("showTie" in options) {
      this._moduleConfig.showTie = options.showTie;
    }

    this._updateData().done(() => {
      this._initializeHtml();
      this._initializeChartJS();
      this._updateHtml();
    });
  }

  _initializeHtml() {
    var $title = $('<div class="ea-trendsgraph-title"></div>');
    var $trendsgraph = $(
      '<canvas class="ea-trendsgraph-chart" height="100"></canvas>'
    );
    var probText = this._moduleConfig.usePercentage ? "chance" : "probability";
    var $instructions = $(
      '<p class="ea-trendsgraph-instructions">' +
        '<small>"50 Seats each" represents the ' +
        probText +
        " of Republicans and the combination of Democrats and Independents each hold 50 seats.</small>" +
        "</p>"
    );

    this._titleModule = $title.eaTitle({
      format: "", // format is set in update()
    })[0];

    this._$element.append($title).append($trendsgraph);
    //.append($instructions);
  } // end _initializeHtml

  _initializeChartJS() {
    var $trendsgraph = this._$element.find(".ea-trendsgraph-chart");

    var customTooltips = function (tooltip) {
      // Tooltip Element
      var chartID = this._chart.id;
      let tooltipP = document.getElementById("chartjs-tooltip" + chartID);
      let tooltipEl = null;
      if (!tooltipP) {
        tooltipP = document.createElement("div");
        tooltipP.className = "ea-trendsgraph-tooltip";
        tooltipP.id = "chartjs-tooltip" + chartID;
        document.body.appendChild(tooltipP);

        const tooltipEl = document.createElement("div");
        tooltipEl.className = "ea-trendsgraph-tooltip-inner";

        const arrowR = document.createElement("div");
        arrowR.className = `ea-trendsgraph-tooltip-arrow
                            ea-trendsgraph-tooltip-arrow-right`;
        const arrowL = document.createElement("div");
        arrowL.className = `ea-trendsgraph-tooltip-arrow
                            ea-trendsgraph-tooltip-arrow-left`;
        tooltipP.appendChild(arrowR);
        tooltipP.appendChild(tooltipEl);
        tooltipP.appendChild(arrowL);
      }
      if (tooltipEl == null) {
        tooltipEl = tooltipP.getElementsByClassName(
          "ea-trendsgraph-tooltip-inner"
        )[0];
      }

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipP.style.display = "none";
        return;
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      var innerHTML = "";
      var titleLines = tooltip.title || [];
      titleLines.forEach(function (title) {
        innerHTML += '<div class="tooltip-hd">' + title + "</div>";
      });
      tooltipEl.innerHTML = innerHTML + "<table></table>";

      // Set Text
      if (tooltip.body) {
        var bodyLines = tooltip.body.map(getBody);
        var innerHtml = "";

        bodyLines.forEach(function (body, i) {
          var colors = tooltip.labelColors[i];
          var words = body[0].split(": ");
          var n = math.formatProbability(Number(words[1]));
          innerHtml +=
            '<tr><td class="label-entry">' +
            words[0] +
            '</td><td class="num-entry" style="color:' +
            colors.backgroundColor +
            '">' +
            n +
            "</td></tr>";
        });
        innerHtml += "</tbody>";

        var tableRoot = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
      }

      var position = this._chart.canvas.parentNode.getBoundingClientRect();

      // Display, position
      tooltipP.style.display = "block";
      const leftPos = position.left + tooltip.caretX + 10;
      const width = tooltipEl.offsetWidth + 12;
      const rightLim = position.right;
      if (leftPos + width < rightLim) {
        tooltipP.style.left = leftPos + "px";
        tooltipP.getElementsByClassName(
          "ea-trendsgraph-tooltip-arrow-left"
        )[0].style.display = "none";
        tooltipP.getElementsByClassName(
          "ea-trendsgraph-tooltip-arrow-right"
        )[0].style.display = "inline-block";
        tooltipEl.style.marginLeft = "12px";
      } else {
        tooltipP.style.left = leftPos - 20 - width + "px";
        tooltipP.getElementsByClassName(
          "ea-trendsgraph-tooltip-arrow-right"
        )[0].style.display = "none";
        tooltipP.getElementsByClassName(
          "ea-trendsgraph-tooltip-arrow-left"
        )[0].style.display = "inline-block";
        tooltipEl.style.marginLeft = "0px";
      }
      tooltipP.style.top =
        position.top + position.height / 2 + window.scrollY + "px";
    };

    Chart.defaults.global.defaultFontSize = 14;
    Chart.defaults.global.defaultFontColor = color.lightGray;

    // Enables the vertical dashed line on hover
    Chart.defaults.LineWithLine = Chart.defaults.line;
    Chart.controllers.LineWithLine = Chart.controllers.line.extend({
      draw: function (ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);
        if (
          this.index === 0 &&
          this.chart.tooltip._active &&
          this.chart.tooltip._active.length
        ) {
          var activePoint = this.chart.tooltip._active[0],
            ctx = this.chart.ctx,
            x = activePoint.tooltipPosition().x,
            topY = this.chart.scales["y-axis-0"].top,
            bottomY = this.chart.scales["y-axis-0"].bottom;

          var existingOp = ctx.globalCompositeOperation;
          ctx.globalCompositeOperation = "destination-over";
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "rgba(0, 0, 0, 0.2)";
          ctx.setLineDash([5, 5]);
          ctx.stroke();
          ctx.restore();
          ctx.globalCompositeOperation = existingOp;
        }
      },
    });

    var trendChart = new Chart($trendsgraph, {
      type: "LineWithLine",
      data: {},
      options: {
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                minUnit: "week",
                displayFormats: {
                  year: "MMMM YYYY",
                  month: "MMMM",
                  week: "MMM D",
                },
              },
              ticks: {
                fontFamily: "proxima-nova, sans-serif",
                callback: function (tickValue, index, ticks) {
                  return tickValue.toUpperCase();
                },
              },
              gridLines: {
                zeroLineColor: "rgba(0, 0, 0, 0.1)",
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: "ibm-plex-mono, sans-serif",
                padding: 7,
                min: 0,
                max: 1,
                stepSize: 0.2,
                callback: function (tickValue, index, ticks) {
                  if (index == 0)
                    return (Number(tickValue) * 100).toString() + "%";
                  else return (Number(tickValue) * 100).toString();
                },
              },
              gridLines: {
                zeroLineColor: color.gray,
                drawBorder: false,
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
            fontFamily: "proxima-nova, sans-serif",
            fontStyle: "small-caps",
            filter: function (legItem, chartData) {
              legItem.text = legItem.text.toLowerCase();
              return legItem;
            },
            usePointStyle: true,
          },
        },
        tooltips: {
          enabled: false,
          mode: "index",
          intersect: false,
          custom: customTooltips,
          callbacks: {
            title: function (t, o) {
              if (t.length > 0 && o.datasets.length > 0) {
                const idx = t[0].index;
                return o.datasets[0].data[idx].x.format("MMMM Do");
              }
            },
          },
        },
        hover: {
          mode: "index",
          intersect: false,
          animationDuration: 0,
        },
      },
    });

    this._chart = trendChart;
  }

  /* Updating
   * ------------------------------------------------------ */
  update(newDataConfig) {
    // trends module does not support time or customizations
    this._dataConfig = {
      type: newDataConfig.type,
      year: newDataConfig.year,
      pollScale: newDataConfig.pollScale,
      swingScenario: newDataConfig.swingScenario,
    };
    if ("raceFilter" in newDataConfig) {
      this._dataConfig.raceFilter = newDataConfig.raceFilter;
    }
    if ("subsetter" in newDataConfig) {
      this._moduleConfig.subsetter = newDataConfig.subsetter;
    }

    /*
    this._$element.find(".ea-trendsgraph-subsetter")
      .prop("disabled", true);
    */

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(() => {
      this._updateHtml();
      /*
        this._$element.find(".ea-trendsgraph-subsetter")
          .prop("disabled", false);
        */
    }); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = (races) => {
      this._races = races;
      // Resolve internal state after update
      internalStateUpdated.resolve();
    };

    // Callback function to execute upon failure
    var failCallback = (errorInfo) => {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    };

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });
      this._$element.find(".ea-trendsgraph-title").show();
    } else {
      this._$element.find(".ea-trendsgraph-title").hide();
    }
    if (this._dataConfig.type === "senate") {
      this._$element.find(".ea-trendsgraph-instructions").show();
    } else {
      this._$element.find(".ea-trendsgraph-instructions").hide();
    }
    this._updateChart();
    return;
  }

  _updateChart() {
    var xAxisTitle = null;
    this._chart.data = { datasets: this._getAllSeries() };
    this._chart.update();
  }

  _getAllSeries() {
    var shownForecastTimes = this._subsetForecasts();
    var labelsByParty = election.getPartyLabels(
      this._dataConfig.type,
      _.values(this._races)[0][0].candidates
    );

    var showIndep =
      this._dataConfig.type === "senate" ||
      ("raceFilter" in this._dataConfig &&
        this._dataConfig.raceFilter !== "basic");

    var shownForecasts = [];
    var allSeries = [];
    var parties = ["Democrat", "DemInd", "Republican"];
    var partyAbbrevs = ["dem", "demind", "rep"];
    var nParties = parties.length;
    // Initialize allSeries array to include all possible series
    for (var i = 0; i < nParties; i++) {
      var party = parties[i];
      let partyColorName = party === "DemInd" ? "Democrat" : party;
      var partyLine = {
        borderColor: color.getStrongPartyColor(partyColorName),
        backgroundColor: color.getStrongPartyColor(partyColorName),
        data: [],
        order: i,
        label: labelsByParty[party],
        fill: false,
        pointRadius: 0,
      };
      allSeries.push(partyLine);
    }
    // Add an additional series for tie in senate races (50 seats for Republican)
    // TODO: find a more elegent way of handling this.
    if (this._dataConfig.type === "senate") {
      allSeries.push({
        borderColor: color.getMixedColor(),
        backgroundColor: color.getMixedColor(),
        data: [],
        order: nParties,
        label: "Tie",
        fill: false,
        pointRadius: 0,
      });
    }
    // For each time stamp compute the expected win probability
    for (var j = 0; j < shownForecastTimes.length; j++) {
      // Set the dataConfig to the current forecast time for updating
      var fTime = shownForecastTimes[j];
      this._dataConfig.time = fTime;
      var stats = probs.computeStats(this._dataConfig, this._races);
      delete this._dataConfig.time;
      // Populate series
      for (var k = 0; k < nParties; k++) {
        var party = parties[k];
        if (!(party in stats.info)) {
          continue;
        } // Skip party
        var partyChartIndex = 0;
        switch (party) {
          case "Democrat":
            partyChartIndex = 0;
            break;
          case "DemInd":
            partyChartIndex = 1;
            break;
          case "Republican":
            partyChartIndex = 2;
            break;
        }
        allSeries[partyChartIndex].data.push({
          x: fTime,
          y: stats.info[party].pWin,
        });
      }
      // Add an additional series for tie (probability of Republican reaches
      // 50 seats)
      if (this._dataConfig.type === "senate") {
        allSeries[nParties].data.push({
          x: fTime,
          y: stats.info["Republican"].pHalf,
        });
      }
    }
    // Additional processing
    for (var i = 0; i < nParties; i++) {
      var trendsgraphSeries = allSeries[i];
      var party = parties[i];
      if (
        trendsgraphSeries.data.length === 0 ||
        party == "Independent" ||
        (this._dataConfig.type == "senate" && party == "Democrat")
      ) {
        continue;
      }
      trendsgraphSeries.data.reverse();
      shownForecasts.push(trendsgraphSeries);
    }
    if (this._dataConfig.type === "senate" && this._moduleConfig.showTie) {
      var trendsgraphSeries = allSeries[nParties];
      if (trendsgraphSeries.data.length > 0) {
        trendsgraphSeries.data.reverse();
        shownForecasts.push(trendsgraphSeries);
      }
    }
    return shownForecasts;
  } //end getAllSeries

  /* ### Subset Forecasts ###
   *
   * Limit the number of Forecasts to show, since calculating the stats
   * (and thus running the DP) for all of them is too slow.  Which
   * Forecasts are selected is dictated by the `subsetter` property.
   * The lists of Forecasts return is provided in descending order (most
   * recent one first).
   *
   * The "overview" strategy displays a representative sample of Forecasts
   * through evenly spaced intervals between the most recent and least
   * recent ones.
   *
   * The "recent" strategy shows only the most recent Forecasts.
   *
   * TODO: Make `maxForecasts` an optional setting, possibly even chosen
   *       by the user.
   */
  _subsetForecasts() {
    var maxForecasts = 21;
    var allForecastTimes = util.getAllForecastTimes(
      this._dataConfig,
      this._races
    );
    var shownForecastTimes = [];

    // Last element (earliest forecast) should be ignored, since it is based
    // on prior.
    if (this._moduleConfig.subsetter === "overview") {
      var skip = Math.max(
        1,
        Math.floor(allForecastTimes.length / maxForecasts)
      );
      for (var i = allForecastTimes.length - 1; i >= 1; i -= skip) {
        shownForecastTimes.push(allForecastTimes[i]);
      }
    } else if (this._moduleConfig.subsetter === "recent") {
      var until =
        allForecastTimes.length - maxForecasts > 1
          ? allForecastTimes.length - maxForecasts
          : 1;
      for (var i = allForecastTimes.length - 1; i >= until; i--) {
        shownForecastTimes.push(allForecastTimes[i]);
      }
    } else {
      // somehow the `subsetter` property got messed up
      console.warn(
        "EATrendsGraph._subsetForecasts doesn't know of a " +
          this._moduleConfig.subsetter +
          " subsetter option. Defaulting to 'overview' instead."
      );

      this._moduleConfig.subsetter = "overview";
      return this._subsetForecasts();
    }

    return shownForecastTimes;
  }

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Trendsgraph follower... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Trendsgraph module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Trendsgraph module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Trendsgraph module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Trendsgraph module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Trendsgraph module.");
    }
  } // end error
} // end EATrendsGraph

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaTrendsGraph = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var trendsGraph = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    trendsGraph.push(new EATrendsGraph($element, myOptions));
  });

  return trendsGraph;
};

// Autoloader
// $(function () {
//   $(".ea-trendsgraph").eaTrendsGraph();
// });
