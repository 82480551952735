import React from "react";
import { css } from "@emotion/core";
import Tippy, { useSingleton } from "@tippyjs/react";
import Block, { BlockContent } from "./Block";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

export interface BlocksProps {
  blocks: BlockContent[];
}

function Blocks(props: BlocksProps): JSX.Element {
  const { blocks } = props;
  const [source, target] = useSingleton();

  return (
    <div>
      <Tippy theme="light-border" placement="bottom" singleton={source}></Tippy>
      <div
        css={css`
          height: 30px;
          width: 100%;
          background: #f64a41;
          display: flex;
          flex-direction: row;
        `}
      >
        {blocks.map((block, index) => (
          <Block key={index} target={target} {...block}></Block>
        ))}
      </div>
    </div>
  );
}

export default Blocks;
