/* Module: Sliders Multiview
 * ==========================================================================
 *
 * The Sliders Multiview module displays multiple swing scenarios at once in
 * graphical form.
 * NOTE: data for this module is currently HARDCODED in.
 * TODO: update this module to allow the loading of multiple customized data
 *
 * Usage examples not yet available. Follow example for sliders.
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: dataConfig is currently implemented but not used in this modules
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_chart`:        The Highcharts instance.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";

export class EASlidersMultiview {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-sliders-multiview");

    // Validate the initial options
    if (typeof options != "object") {
      this.error("constructor: options not an object");
      return;
    }

    if (!options.type) {
      this.error("constructor: no type");
      return;
    }
    if (!options.year) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
    };

    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    var _this = this;

    this._updateData().done(function () {
      _this._initializeHtml();
      _this._initializeChartjs();
      _this._updateHtml();
    });
  }

  _initializeHtml() {
    var $title = $("<div></div>");

    var $sliders = $('<canvas class="ea-sliders-multiview-chart"></canvas>');

    this._titleModule = $title.eaTitle({
      format: "", // format is set in update()
    })[0];

    this._$element.append($title).append($sliders);
  } // end _initializeHtml

  _initializeChartjs() {
    var $slidersMultiview = this._$element.find(".ea-sliders-multiview-chart");
    var $context = $slidersMultiview[0].getContext("2d");

    this._chart = new Chart($context, {
      type: "horizontalBar",
      data: {
        labels: [
          "Expected Seats",
          "Expected Seats With Very Strong Democrat Turnout",
          "Expected Seats With Very Strong Republican Turnout",
        ],
      },
      options: {
        animation: false,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    });
  } // end _initializeChartjs

  /**
   * @returns {Promise<any>}
   */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    var _this = this;

    // Returns a filtered promise to avoid exposing excess data.
    return this._updateData().then(function () {
      _this._updateHtml();
    }); // end updater promise
  } // end update

  /**
   * @returns {Promise<any>}
   */
  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      var filteredLocRaces = util.removeInactiveRaces(
        this._dataConfig,
        this._races
      );
      // Update the default display order
      this._displayOrder = [];
      $.each(
        filteredLocRaces,
        $.proxy(function (locAbbrev) {
          this._displayOrder.push(locAbbrev);
        }, this)
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      var formatStr =
        this._dataConfig.type === "president"
          ? "{year} {type} Races by State"
          : "{year} {type} Races by Senate Seat";

      this._titleModule.setFormat(formatStr);
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-sliders-multiview-title").show();
    } else {
      this._$element.find(".ea-sliders-multiview-title").hide();
    }

    // Update the chart and re-enable the drop-down
    this._updateChart();
    return;
  }

  _updateChart() {
    // NOTE: hard code data. Do the daily update here.
    var datasets = [
      {
        backgroundColor: "#318CE7",
        barPercentage: 0.25,
        data: [45.8, 46.88, 44.57],
        // , index: 3
        // , legendIndex: 0
        label: "Democrats",
      },
      {
        backgroundColor: "#1FA72C",
        data: [2, 2, 2],
        barPercentage: 0.25,
        // , index: 1
        // , legendIndex: 1
        label: "Independents",
      },
      {
        backgroundColor: "red",
        data: [52.2, 51.12, 53.43],
        barPercentage: 0.25,
        // , index: 0
        // , legendIndex: 2
        label: "Republicans",
      },
    ];

    this._chart.data.datasets = datasets;
    this._chart.update();
  } // end _updateChart

  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Sliders Multiview widget... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The Sliders module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Slider module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Sliders module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Sliders module's constructor requires a `year` option."
      );
    } else {
      console.warn(
        "An unknown error occurred in the Sliders Multiview module."
      );
    }

    // TODO: notify devs
  } // end error
}

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaSlidersMultiview = function (options) {
  var elements = this;

  options = _.isObject(options) ? options : {};

  var slidersMultiview = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    slidersMultiview.push(new EASlidersMultiview($element, myOptions));
  });

  return slidersMultiview;
};

// Autoloader
$(function () {
  $(".ea-sliders-multiview").eaSlidersMultiview();
});
