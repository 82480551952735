/* Module: custom-scenarios
 * ==========================================================================
 *
 * The scenarios customization module is a simplified Custom Forecasts modules
 * that only allows the change in scenarios. This module is created for
 * loading different scenarios for modules on the main page that still shown
 * single scenario.
 *
 * Usage examples not yet available. Follow example for Custom Forecasts
 * module.
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *  - N/A
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 * ** Instance Variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_races`:        All Races of `type` in `year`.
 *   - `_forecastTimes`:Times for Forecasts, across all Races. These times are
 *                      determined solely by type-year combination;
 *                      customization options are omitted.
 *
 *   - `_$element` - The element this jQuery plugin is called on.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as election from "../services/election";
import * as data from "../services/data";

export class eaCustomScenarios {
  /* Object Definition + Constructor
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-custom-forecasts");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Make sure swingScenario and pollFilters are set to defaults here (not
    // all modules need these values specified)
    if (!("swingScenario" in this._dataConfig)) {
      this._dataConfig.swingScenario = "neutral";
    }
    if (!("pollFilters" in this._dataConfig)) {
      this._dataConfig.pollFilters = [];
    }
    if (!("pollScale" in this._dataConfig)) {
      this._dataConfig.pollScale = 0.3;
    }
    if (!("raceFilter" in this._dataConfig)) {
      this._dataConfig.raceFilter = "basic";
    }

    // No module config needed

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._updateHtml();
        this._enableSelectors();
      }, this)
    );
  } // end constructor

  /* Create
   * ------------------------------------------------------
   * Initialize the module's html structure and non-updatable properties.
   * NOTE: Only the _$element instance var is set at this point
   */
  _initializeHtml() {
    // Swing scenario selector
    var $swingScenario = $(
      '<div id="swingScenarioOptions">' +
        //'<div class="collapse in collapse-customize row" aria-expanded="true">' +
        '<form class="form-inline ea-cf-swingScenarioForm">' +
        "<span>Change the turnout scenario to see forecasts for each state: </span>" +
        '<div class="form-group">' +
        '<label class="sr-only" for="swingScenario">Scenario: </label>' +
        '<select name="ssSelector" class="form-control ea-cf-selector ea-cf-swingScenarioSelector">' +
        /*REMOVED:'<option value="dem50">Extreme Democrat</option>' +*/
        '<option value="dem20">Very Strong Democrat</option>' +
        '<option value="neutral">Neutral</option>' +
        '<option value="rep20">Very Strong Republican</option>' +
        /*REMOVED:'<option value="rep50">Extreme Republican</option>' +*/
        "</select>" +
        "</div>" +
        '<span class="glyphicon glyphicon-question-sign popover-trigger ea-cf-swingScenarioExplanation"></span>' +
        "</form>" +
        //'</div>' +
        "</div>"
    );

    $swingScenario.change(
      $.proxy(function () {
        var newDataConfig = this._shallowCopyDataConfig();
        newDataConfig.swingScenario = $swingScenario
          .find(".ea-cf-swingScenarioSelector")
          .val();
        this.update(newDataConfig);
      }, this)
    );

    var popoverTriggers = $swingScenario.find(
      ".ea-cf-swingScenarioExplanation"
    );

    popoverTriggers.on("shown.bs.popover", function () {
      $(this).next().find(".arrow").css("top", "-11px");
    });

    popoverTriggers.click({ popoverTriggers: popoverTriggers }, function () {
      var $popoverTrigger = $(this);
      popoverTriggers.not($popoverTrigger).popover("hide");
      $popoverTrigger.popover("toggle");
    });

    var makePopover = function ($elem, options) {
      var defaultPopoverOptions = {
        placement: "bottom",
        trigger: "manual",
        html: true,
      };
      _.defaults(options, defaultPopoverOptions);
      $elem.popover(options);
    };
    makePopover($swingScenario.find(".ea-cf-swingScenarioExplanation"), {
      container: ".ea-cf-swingScenarioExplanation",
      title: "Turnout Scenario",
      content: $.proxy(function () {
        var message =
          'Change the assignment of undecided voters to either major party. More info can be found at <a href="/faq.php#custom">Custom Forecasts FAQ</a>.';
        return message;
      }, this),
    });

    this._$element.append($swingScenario);
  } // end _initializeHtml

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    this._disableSelectors();

    // Returns a filtered promise to avoid exposing excess data.
    // Make sure instance vars (e.g., races, customRaces) are updated, and
    // then update HTML elements on page. Finally, tell other modules on
    // page to update as well, and give them the customRaces to do so.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();

        // Tell the other modules on the page to update with the given
        // options by triggering the 'modification' event for the
        // custom forecasts element
        //console.log("Telling modules on page to update..."); // DEBUG
        var changeEvent = $.Event("modification", {
          options: this._dataConfig,
        });
        this._$element.trigger(changeEvent);

        // Re-enable the selectors after an update
        this._enableSelectors();
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // DEBUG:
    //console.log("custom: `_updateData` called");
    //console.log(this._dataConfig);

    // Ensure that the year is valid; might not be if user switched from
    // Senate off-year to presidential without modifying the year
    this._dataConfig.year = election.validateYearForType(
      this._dataConfig.type,
      this._dataConfig.year
    );

    // Create a dataConfig without customizations, so we can retrieve the
    // base forecast times
    var dataConfigNoCustom = util.createDataConfigWithoutCustomizations(
      this._dataConfig
    );

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (racesNoCustom, races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Update list of available forecast times
      this._forecastTimes = util.getAllForecastTimes(
        dataConfigNoCustom,
        racesNoCustom
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get multiple Promise objects from data service API
    var dataPromise = $.when(
      data.getRacesWithForecasts(dataConfigNoCustom),
      data.getRacesWithForecasts(this._dataConfig)
    )
      .done(successCallback)
      .fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    this._$element
      .find(".ea-cf-swingScenarioSelector")
      .val(this._dataConfig.swingScenario);

    return;
  } // end _updateHtml

  _shallowCopyDataConfig() {
    var newDataConfig = {
      type: this._dataConfig.type,
      year: this._dataConfig.year,
      time: this._dataConfig.time,
      pollFilters: [],
      swingScenario: this._dataConfig.swingScenario,
      pollScale: this._dataConfig.pollScale,
      raceFilter: this._dataConfig.raceFilter,
    };
    // Copy over poll filters
    var nFilters = this._dataConfig.pollFilters.length;
    for (var i = 0; i < nFilters; ++i) {
      newDataConfig.pollFilters.push(this._dataConfig.pollFilters[i]);
    }
    return newDataConfig;
  } // end _shallowCopyDataConfig

  _disableSelectors() {
    this._$element
      .find(".ea-cf-swingScenarioForm :input")
      .prop("disabled", true);
  } // end _disableSelectors

  _enableSelectors() {
    this._$element
      .find(".ea-cf-swingScenarioForm :input")
      .prop("disabled", false);
  } // end _enableSelectors

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Custom Scenarios module... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Custom Scenarios module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Custom Scenarios module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Custom Scenarios module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Custom Scenarios module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Custom Scenarios module.");
    }
  } // end error
} // end eaSimpleCustomForecasts prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaCustomScenarios = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var eaCustomScenariosObjs = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    eaCustomScenariosObjs.push(new eaCustomScenarios($element, myOptions));
  });

  return eaCustomScenariosObjs;
};

// Autoloader
$(function () {
  $(".ea-custom-scenarios").eaCustomScenarios();
});
