/* Module: Histogram
 * ==========================================================================
 *
 * The histogram module shows how likely it is for each candidate/party to
 * get a specific number of votes/seats.
 *
 * Usage examples available at:
 *
 *    site/dynamic/template/module/histogram.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `usePercentage`: A boolean flag that indicates whether probabilities
 *                      should be displayed as percentage or should be
 *                      displayed as number.
 *                      It defaults to true (display percentage).
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_chart`:        The Highcharts instance.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as color from "../services/color";
import * as election from "../services/election";
import * as data from "../services/data";
import * as probs from "../services/probs";
import * as math from "../services/math";

export class EAHistogram {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-histogram");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      usePercentage: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    this._chart = {};
    this._currentSeries = {};

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._initializeD3();
        this._updateHtml();
      }, this)
    );
  } // end constructor

  _initializeHtml() {
    var $title = $('<div class="ea-histogram-title"></div>');
    var $histogram = $('<div class="ea-histogram-chart"></div>');

    this._titleModule = $title.eaTitle({
      format: "", // format is set in update()
    })[0];

    this._$element.append($title).append($histogram);
  } // end _initializeHtml

  _initializeD3() {
    const $histogram = this._$element.find(".ea-histogram-chart");
    // var usePercentage = this._moduleConfig.usePercentage;

    const histogram = { Democrat: [], Republican: [] };
    const defaultOptions = {
      w: $histogram.width(),
      h: $histogram.width() / 2.6,
      margin: {
        top: 76,
        bottom: 80,
        left: 70,
        right: 1,
      },
      bucketSize: 1,
      defaultRange: [50, 100],
      format: d3.format(".3s"),
      defaultYRange: [0, 0.2],
    };

    const histogramD = histogram["Democrat"];
    const histogramR = histogram["Republican"];

    const [min, max] = d3.extent(Object.keys(histogramD).map((d) => +d));
    // const range = [min, max + 1];
    let range = [];
    if (this._dataConfig.type == "president") range = [0, 539];
    else range = [0, 101];

    // set width and height of svg
    const { w, h, margin, defaultRange, bucketSize, format, defaultYRange } = {
      ...defaultOptions,
    };

    // dimensions of slider bar
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    // create x scale
    const x = d3
      .scaleLinear()
      .domain(range) // data space
      .range([0, width]); // display space
    let yDomain = [0, d3.max(Object.values(histogramD))];
    if (Object.values(histogramD).length <= 0) yDomain = defaultYRange;
    let y = d3.scaleLinear().domain(yDomain).range([0, height]);

    // create svg
    let svg = d3
      .select(".ea-histogram-chart")
      .append("svg")
      .attr("width", w)
      .attr("height", h);

    // draw x axis
    const xAxis = svg
      .append("g")
      .attr("class", "grid gridX")
      .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(10)
          .tickSize(-height - 10)
          .tickSizeOuter(0)
      );
    xAxis
      .selectAll(".tick line, .tick text")
      .attr("transform", "translate(0,10)");
    xAxis.selectAll(".tick text").attr("font-size", "16");
    xAxis
      .append("text")
      .attr("class", "axis-label")
      .attr("x", width / 2)
      .attr("y", 10 + 28)
      .attr("font-size", "16")
      .attr("dominant-baseline", "hanging")
      .text("Electoral votes");
    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
      .call(d3.axisBottom(x).tickFormat("").tickSize(0));

    // draw y axis
    const createYAxis = (domain) => {
      const y_draw = d3.scaleLinear().domain(domain).range([height, 0]);
      const yAxis = d3
        .axisLeft(y_draw)
        .ticks(3)
        .tickSize(-width)
        .tickSizeOuter(0)
        .tickFormat((d) => d3.format(".1f")(d * 100));
      return yAxis;
    };
    const yAxis = svg
      .append("g")
      .attr("class", "grid gridY")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .call(createYAxis(yDomain));
    yAxis.selectAll(".tick text").attr("font-size", "16");
    yAxis
      .append("text")
      .attr("class", "axis-label")
      .attr("x", -height / 2)
      .attr("y", -54)
      .attr("transform", "rotate(-90)")
      .attr("font-size", "16")
      .attr("dominant-baseline", "baseline")
      .text("% Chance");
    const updateYAxis = (domain) => {
      yAxis.transition().duration(750).call(createYAxis(domain));
      y.domain(domain);
      yAxis.selectAll(".tick text").attr("font-size", "16");
    };
    this._chart["updateYAxis"] = updateYAxis;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // draw democrat histogram values
    const barsD = g
      .append("g")
      .selectAll("rect")
      .data(d3.range(range[0], range[1] + 1))
      .enter()
      .append("rect")
      .attr("x", (d) => x(d))
      .attr("y", (d) => height - y(histogramD[d] || 0))
      .attr("width", width / (range[1] - range[0]))
      .attr("height", (d) => y(histogramD[d] || 0))
      .style("fill", color.getStrongPartyColorTransparent("Democrat"));

    // draw republican histogram values
    const barsR = g
      .append("g")
      .selectAll("rect")
      .data(d3.range(range[0], range[1] + 1))
      .enter()
      .append("rect")
      .attr("x", (d) => x(d))
      .attr("y", (d) => height - y(histogramR[d] || 0))
      .attr("width", width / (range[1] - range[0]))
      .attr("height", (d) => y(histogramR[d] || 0))
      .style("fill", color.getStrongPartyColorTransparent("Republican"));

    // histogram update function
    const updateBars = (histogramL) => {
      const raceType = this._dataConfig.type;
      let hD = [];
      if (raceType == "president") hD = histogramL["Democrat"];
      else hD = histogramL["DemInd"];
      const hR = histogramL["Republican"];
      barsD
        .data(d3.range(range[0], range[1] + 1))
        .transition()
        .duration(750)
        .attr("y", (d) => height - y(hD[d] || 0))
        .attr("height", (d) => y(hD[d] || 0));
      barsR
        .data(d3.range(range[0], range[1] + 1))
        .transition()
        .duration(750)
        .attr("y", (d) => height - y(hR[d] || 0))
        .attr("height", (d) => y(hR[d] || 0));
      updateLabels();
    };
    this._chart["updateBars"] = updateBars;

    let labelBound = g
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", -22);
    let labelL = labelBound.append("tspan");
    let labelR = labelBound.append("tspan");

    let labels = election.getPartyLabels(
      this._dataConfig.type,
      _.values(this._races)[0][0].candidates
    );
    let labelProb = g
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", -40);
    let demCandLabel = labelProb
      .append("tspan")
      .text(
        `${
          this._dataConfig.type == "president" ? labels.Democrat : labels.DemInd
        }`
      )
      .attr("class", "dem-color");
    labelProb
      .append("tspan")
      .text(` ${this._dataConfig.type == "president" ? "has" : "have"} a `);
    let labelProbD = labelProb.append("tspan").attr("class", "dem-color");
    labelProb.append("tspan").text(` chance, and `);
    let repCandLabel = labelProb
      .append("tspan")
      .text(`${labels.Republican}`)
      .attr("class", "gop-color");
    labelProb
      .append("tspan")
      .text(` ${this._dataConfig.type == "president" ? "has" : "have"} a `);
    let labelProbR = labelProb.append("tspan").attr("class", "gop-color");
    labelProb.append("tspan").text(` chance`);
    const updateCandidateLabels = () => {
      labels = election.getPartyLabels(
        this._dataConfig.type,
        _.values(this._races)[0][0].candidates
      );
      demCandLabel.text(
        `${
          this._dataConfig.type == "president" ? labels.Democrat : labels.DemInd
        }`
      );
      repCandLabel.text(`${labels.Republican}`);
    };
    this._chart["updateCandidateLabels"] = updateCandidateLabels;

    const updateLabels = () => {
      const a = Math.round(x.invert(s[0])) * bucketSize;
      const b = (Math.round(x.invert(s[1])) - 1) * bucketSize;
      labelL.text(`of receiving between ${a} and`);
      const raceType = this._dataConfig.type;
      labelR.text(
        ` ${b} ${raceType == "president" ? "electoral votes" : "seats"}`
      );
      const cumulProbs = this._cumulativeProbability(a, b);
      labelProbD.text(math.formatProbability(cumulProbs.Democrat));
      labelProbR.text(math.formatProbability(cumulProbs.Republican));
    };

    let s = [];
    // define brush
    let brush = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("brush", () => {
        s = d3.event.selection;
        // update and move labels
        updateLabels();

        // move brush handles
        selectedL.attr("width", `${s[0]}`);
        selectedR.attr("width", `${width - s[1]}`).attr("x", `${s[1]}`);
        handleL.attr("transform", `translate(${s[0]}, 0)`);
        handleR.attr("transform", `translate(${s[1] - width}, 0)`);

        // update view
        // if the view should only be updated after brushing is over,
        // move these two lines into the on('end') part below
        svg.node().value = s.map((d) => bucketSize * Math.round(x.invert(d)));
        svg.node().dispatchEvent(new CustomEvent("input"));
      })
      .on("end", function () {
        if (!d3.event.sourceEvent) return;
        let d0 = d3.event.selection.map(x.invert);
        let d1 = d0.map(Math.round);
        d3.select(this).transition().call(d3.event.target.move, d1.map(x));
      });

    // append brush to g
    let gBrush = g.append("g").attr("class", "brush").call(brush);

    let selectedL = gBrush
      .append("rect")
      .attr("class", "brush-selection")
      .attr("width", "0")
      .attr("height", `${height}`)
      .attr("x", "0")
      .attr("y", "0");
    let selectedR = gBrush
      .append("rect")
      .attr("class", "brush-selection")
      .attr("width", "0")
      .attr("height", `${height}`)
      .attr("x", `${width}`)
      .attr("y", "0");

    let handleL = gBrush
      .append("path")
      .attr("class", "brush-handle")
      .attr("d", `M0 0 L0 ${height}`);
    let handleR = gBrush
      .append("path")
      .attr("class", "brush-handle")
      .attr("d", `M${width} 0 L${width} ${height}`);

    // override default behaviour - clicking outside of the selected area
    // will select a small piece there rather than deselecting everything
    // https://bl.ocks.org/mbostock/6498000
    gBrush
      .selectAll(".overlay")
      .each(function (d) {
        d.type = "selection";
      })
      .on("mousedown touchstart", brushcentered);

    function brushcentered() {
      let dx = x(1) - x(0), // Use a fixed width when recentering.
        cx = d3.mouse(this)[0],
        x0 = cx - dx / 2,
        x1 = cx + dx / 2;
      d3.select(this.parentNode).call(
        brush.move,
        x1 > width ? [width - dx, width] : x0 < 0 ? [0, dx] : [x0, x1]
      );
    }

    // select default range
    const translatedRange = defaultRange
      .map((d) => width * (d / 100))
      .map(x.invert)
      .map(Math.round)
      .map(x);
    gBrush.call(brush.move, translatedRange);
  } // end _initializeD3

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
      }, this)
    );

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      var formatStr =
        this._dataConfig.type === "president"
          ? "Distribution of Electoral Votes by Candidate in {year}"
          : "Distribution of Senate Seats by Party in {year}";

      this._titleModule.setFormat(formatStr);
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-histogram-title").show();
    } else {
      this._$element.find(".ea-histogram-title").hide();
    }

    this._updateChart();
    return;
  }

  _updateChart() {
    const histogram = this._getAllSeries();
    this._currentSeries = histogram;
    const yDomain = [0, d3.max(Object.values(histogram["Republican"])) * 1.1];
    this._chart.updateYAxis(yDomain);
    this._chart.updateBars(histogram);
    this._chart.updateCandidateLabels();
  } // end _updateChart

  _cumulativeProbability(lb, ub) {
    const probs = { Democrat: 0, Republican: 0 };
    const raceType = this._dataConfig.type;
    let demDist = [];
    if (raceType == "president") demDist = this._currentSeries["Democrat"];
    else demDist = this._currentSeries["DemInd"];
    const repDist = this._currentSeries["Republican"];
    for (let i = lb; i <= ub; i++) {
      if (demDist && i in demDist) {
        probs["Democrat"] += demDist[i];
      }
      if (repDist && i in repDist) {
        probs["Republican"] += repDist[i];
      }
    }
    return probs;
  } // end _updateChart

  _getAllSeries() {
    var dp = probs.computeDP(this._dataConfig, this._races);
    var labelsByParty = election.getPartyLabels(
      this._dataConfig.type,
      _.values(this._races)[0][0].candidates
    );
    var showIndep =
      this._dataConfig.type === "senate" ||
      ("raceFilter" in this._dataConfig &&
        this._dataConfig.raceFilter !== "basic");

    var allSeries = {};
    let minVal = 0;
    let maxVal = 538;
    $.each(
      dp.parties,
      $.proxy(function (pIdx, party) {
        var partyBase = dp.info[party].baseValue;
        var partyValuesDist = dp.info[party].valueDistribution;
        if (this._dataConfig.type === "senate") {
          if (party === "DemInd") {
            partyBase +=
              election.senateSeatsNotUp[this._dataConfig.year].Democrat +
              election.senateSeatsNotUp[this._dataConfig.year].Independent;
          } else {
            partyBase +=
              election.senateSeatsNotUp[this._dataConfig.year][party];
          }
        } else if (party === "Independent") {
          return;
        }
        var partyChartIndex = 0;
        switch (party) {
          case "Democrat":
            partyChartIndex = 0;
            break;
          case "DemInd":
            partyChartIndex = 1;
            break;
          case "Independent":
            partyChartIndex = 2;
            break;
          case "Republican":
            partyChartIndex = 3;
            break;
        }
        var partyHistogram = {};

        for (var val = -partyBase; val < partyValuesDist.length; val++) {
          const xVal = val + partyBase;
          const yVal = val >= 0 ? partyValuesDist[val] : 0;
          if (xVal < minVal && yVal >= 0.00001) minVal = xVal;
          if (xVal > maxVal && yVal >= 0.00001) maxVal = xVal;
          partyHistogram[xVal] = yVal;
        }

        // Hide Independents by default as they tend to skew the Histograms
        // display: they usually have 1-2 outcomes on the far left, and a near
        // 100% chance of achieving those.
        if (party === "Independent") {
          partyHistogram.visible = false;
        }
        // Hide the Democrats alone by default for Senate races in favor of
        // showing the coalition of Democrats and Independents
        if (this._dataConfig.type === "senate" && party === "Democrat") {
          partyHistogram.visible = false;
        }

        if (party !== "Independent" || showIndep) {
          allSeries[party] = partyHistogram;
        }
      }, this)
    );

    // allSeries.forEach((item, idx) => {
    //   const len = item.data.length;
    //   item.data.splice(Math.min(maxVal, len), len);
    //   item.data.splice(0, Math.min(minVal, len));
    // });
    // let buckets = [];
    // for (let i = minVal; i <= maxVal; i++) {
    //   buckets.push(i);
    // }

    // const data = { labels: buckets, datasets: allSeries };
    return allSeries;
  } // end _getAllSeries

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Histogram machine... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The Histogram module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Histogram module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Histogram module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Histogram module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Histogram module.");
    }

    // TODO: notify devs
  } // end error
} // end EAHistogram prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaHistogram = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var histograms = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    histograms.push(new EAHistogram($element, myOptions));
  });

  return histograms;
};

// Autoloader
$(function () {
  $(".ea-histogram").eaHistogram();
});
