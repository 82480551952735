import $ from "jquery";
import nprogress from "nprogress";

/** Module Loading Indicator
 * ==========================================================================
 * This service subscribes to module loading events and controls the
 * NProgress progress bar.
 *
 * Note that you can't actually interact with the loading indicator service,
 * it simply hooks up the events to the progress bar on load.
 */
export function setupNProgress(): void {
  nprogress.configure({
    // time to 100% is 1.625 secons
    // total tiime = (1 - minimum) / (trickleRate * 1000 / trickleSpeed)
    // where 1000 is the number of milliseconds in a second
    minimum: 0.15,
    // trickleRate: 0.08,
    trickleSpeed: 100,
  });

  $(document).ajaxStart(function () {
    nprogress.start();
  });

  $(document).ajaxStop(function () {
    nprogress.done();
  });
}

$(setupNProgress);
