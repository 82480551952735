/* Module: custom-forecasts
 * =============================================================================
 *
 * The Custom Forecasts module is an extension of the election selector
 * module that allows for filtering of polls and global modifications of the
 * undecided split (previously called swing scenarios).
 *
 * Once the user determines the settings, the module will figure out what the
 * weighted responses should be (the b and w values) and then it will ask the
 * web server to compute new probabilities given those values. It will then
 * use these values to update the forecasts for each race, and then tell other
 * modules on the page to update their data accordingly.
 *
 * Usage example available at:
 *      site/dynamic/template/module/custom-forecasts.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *   - `chooseType`: If the type selector should be enabled.
 *   - `chooseYear`: If the year selector should be enabled.
 *   - `choosePolls`: If the poll filter selector should be enabled.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *  - N/A
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 * ** Instance Variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_races`:        All Races of `type` in `year`.
 *   - `_forecastTimes`:Times for Forecasts, across all Races. These times are
 *                      determined solely by type-year combination;
 *                      customization options are omitted.
 *
 *   - `_$element` - The element this jQuery plugin is called on.
 *
 */

import _ from "underscore";
import * as util from "../services/util";
import * as color from "../services/color";
import * as election from "../services/election";
import * as data from "../services/data";
import * as math from "../services/math";

export class EACustomForecasts {
  /* Object Definition + Constructor
   * ------------------------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-custom-forecasts");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // options to show/hide particular selectors
    let chooseType = true;
    if ("chooseType" in options) {
      chooseType = options.chooseType;
    }
    let chooseYear = true;
    if ("chooseYear" in options) {
      chooseYear = options.chooseYear;
    }
    let choosePolls = true;
    if ("choosePolls" in options) {
      choosePolls = options.choosePolls;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Make sure swingScenario and pollFilters are set to defaults here (not
    // all modules need these values specified)
    if (!("swingScenario" in this._dataConfig)) {
      this._dataConfig.swingScenario = "neutral";
    }
    if (!("pollFilters" in this._dataConfig)) {
      this._dataConfig.pollFilters = [];
    }
    if (!("pollScale" in this._dataConfig)) {
      this._dataConfig.pollScale = 0.3;
    }
    if (!("raceFilter" in this._dataConfig)) {
      //if ((this._dataConfig.type === 'president') &&
      //    (this._dataConfig.year == 2016)) {
      //  this._dataConfig.raceFilter = 'with-Johnson-Stein';
      //} else {
      this._dataConfig.raceFilter = "basic";
      //}
    }

    // No module config needed

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml(chooseType, chooseYear, choosePolls);
        this._updateHtml();
        this._enableSelectors();
      }, this)
    );
  } // end constructor

  /* Create
   * -------------------------------------------------------------------------
   * Initialize the module's html structure and non-updatable properties.
   * NOTE: Only the _$element instance var is set at this point
   */
  _initializeHtml(chooseType, chooseYear, choosePolls) {
    // Create the election selector object (adapted from election
    // selector module code)
    var $selectors = $(`
        <h4><b>Customize Forecast</b></h4>
        <form class="ea-cf-selectors">
          ${
            chooseType
              ? `
          <div class="ea-cf-form-group">
            <div class="btn-group ea-cf-typeSelector" data-toggle="buttons">
              <button class="btn btn-default active ea-cf-selector">
                <input id="presidentSelector" type="radio" name="typeSelector"
                       value="president" checked>
                President
              </button>
              <button class="btn btn-default ea-cf-selector">
                <input id="senateSelector" type="radio" name="typeSelector"
                       value="senate">
                Senate
              </button>
            </div>
          </div

          >`
              : ""
          }${
      chooseYear
        ? `<div class="ea-cf-form-group">
            <div class="ea-cf-yearSelector"></div>
          </div

          >`
        : ""
    }<div class="ea-cf-form-group">
            <div class="ea-cf-dateBox"></div>
          </div>
        </form>
      `);

    if (chooseYear) {
      const yearSelect = $selectors
        .find(".ea-cf-yearSelector")
        .eaComponentSelect({
          choices: [{ value: "default", display: "default" }],
          startValue: "default",
          onChange: function (value) {
            const newDataConfig = this._shallowCopyDataConfig();
            newDataConfig.year = value;
            this.update(newDataConfig);
          }.bind(this),
        });
      $selectors.find(".ea-cf-yearSelector").data({
        eaComponentSelect: yearSelect[0],
        raceType: null,
      });
    }

    const dateSelect = $selectors.find(".ea-cf-dateBox").eaComponentCalendar({
      days: this._forecastTimes,
      selected: this._dataConfig.time,
      onSelect: function (dateSelectPrm, newDate) {
        const newDataConfig = this._shallowCopyDataConfig();
        const timeIdx = dateSelectPrm.bSearch(this._forecastTimes, newDate);
        newDataConfig.time = this._forecastTimes[timeIdx];
        this.update(newDataConfig);
      }.bind(this),
    })[0];
    $selectors.find(".ea-cf-dateBox").data({
      eaComponentCalendar: dateSelect,
      type: this._dataConfig.type,
      year: this._dataConfig.year,
    });

    $selectors.change(
      $.proxy(function () {
        var newDataConfig = this._shallowCopyDataConfig();
        newDataConfig.type = this._$element
          .find("[name='typeSelector']:checked")
          .val();
        this.update(newDataConfig);
      }, this)
    );

    // Poll filter selector and functions
    /*NOTE: Support for additional options needs to be added to
     * functions in ea.util.service in order to be fully
     * operational. */
    var $pollFilter = $(
      choosePolls
        ? `
        <div id="pollFilterOptions">
          <form class="form-inline ea-cf-pollFilterForm">
            <span>Pollster Filters: </span>
            <div class="form-group" style="display: inline-block">
              <label class="sr-only" for="pollFilter">Filter: </label>
              <select name="pfSelector" class="form-control ea-cf-selector
                                               ea-cf-pollFilterSelector"
                      id="multiselect-pollFilter" multiple="multiple">
                <option value="excludeCBS">Exclude CBS</option>
                <option value="excludeCNN">Exclude CNN</option>
                <option value="excludeFOX">Exclude FOX</option>
                <option value="excludeGra">Exclude Gravis</option>
                <option value="excludeNBC">Exclude NBC</option>
                <option value="excludePPP">Exclude PPP</option>
                <option value="excludeQui">Exclude Quinnipiac</option>
                <option value="excludeRas">Exclude Rasmussen</option>
                <option value="excludeWSJ">Exclude WSJ</option>
                <a class="btn btn-primary">Compute</a>
              </select>
            </div>
            <span class="glyphicon glyphicon-question-sign popover-trigger
                         ea-cf-pollFilterExplanation"></span>
          </form>
        </div>
      `
        : ""
    );

    // Function to toggle recompute button. The button will only be enabled
    // when config has changed.
    var toggleDisable = $.proxy(function () {
      var recomputeBtn = this._$element.find(".opt-recompute");
      var newPollFilters = [];
      this._$element
        .find("#multiselect-pollFilter option:selected")
        .each(function () {
          newPollFilters.push($(this).val());
        });
      if (_.isEqual(this._dataConfig.pollFilters, newPollFilters)) {
        recomputeBtn.collapse("toggle");
      } else if (!recomputeBtn.hasClass("in")) {
        recomputeBtn.collapse("toggle");
      }
    }, this);

    // Function to update the customized forecast
    var updateCustomForecast = $.proxy(function () {
      var newPollFilters = [];
      this._$element
        .find("#multiselect-pollFilter option:selected")
        .each(function () {
          newPollFilters.push($(this).val());
        });

      var newDataConfig = this._shallowCopyDataConfig();
      newDataConfig.pollFilters = newPollFilters;
      this.update(newDataConfig);
      this._$element.find(".opt-recompute").removeClass("in");
    }, this);

    if (choosePolls) {
      $pollFilter.find("#multiselect-pollFilter").multiselect({
        nonSelectedText: "No filter selected",
        includeSelectAllOption: true,
        numberDisplayed: 2,
        onChange: toggleDisable,
        onSelectAll: toggleDisable,
        onDeselectAll: toggleDisable,
        onDropdownHide: updateCustomForecast,
        buttonContainer: '<div class="btn-group pollfilter-group" />',
      });
    }

    var $recomputeBtn = $(`
        <li class="opt-recompute collapse"><a class="btn btn-info btn-recompute"
            type="button">
          <span style="font-size: 20px">Recompute</span>
        </a></li>
      `);

    if (choosePolls) {
      $pollFilter.find(".pollfilter-group ul").append($recomputeBtn);
      $pollFilter.find(".btn-recompute").on("click", updateCustomForecast);
    }

    // Swing scenario selector
    var $swingScenario = $(`
        <div id="swingScenarioOptions">
          <form class="ea-cf-swingScenarioForm">
            <div class="form-group ea-cf-rangeGroup">
              <label>Undecided Swing</label>
              <span class="glyphicon glyphicon-question-sign popover-trigger
                           ea-cf-swingScenarioExplanation"></span>
              <div class="ea-cf-colorRange"></div>
            </div>
          </form>
        </div>
      `);

    const swingSlider = $swingScenario
      .find(".ea-cf-colorRange")
      .eaComponentSlider({
        ticks: [
          { slider: 0, label: "+20%", value: "dem20" },
          { slider: 500, label: "+10%", value: "dem10" },
          { slider: 750, label: "+5%", value: "dem5" },
          { slider: 1000, label: "0", value: "neutral" },
          { slider: 1250, label: "+5%", value: "rep5" },
          { slider: 1500, label: "+10%", value: "rep10" },
          { slider: 2000, label: "+20%", value: "rep20" },
        ],
        defaultTick: 3,
        progressBar: false,
        gradient: `linear-gradient(to right,
          ${color.getStrongPartyColor("Democrat")}, white,
          ${color.getStrongPartyColor("Republican")})`,
        onSelect: (val) => {
          var newDataConfig = this._shallowCopyDataConfig();
          newDataConfig.swingScenario = val;
          this.update(newDataConfig);
        },
      })[0];
    $swingScenario.find(".ea-cf-colorRange").data({
      eaComponentSlider: swingSlider,
    });

    // Selector for poll scaling
    var $pollScaler = $(`
        <div id="pollScalerOptions">
          <form class="ea-cf-pollScalerForm">
            <div class="form-group ea-cf-rangeGroup">
              <label>Weight of Polls</label>
              <span class="glyphicon glyphicon-question-sign popover-trigger
                           ea-cf-pollScalerExplanation"></span>
              <div class="ea-cf-pollRange"></div>
              </div>
            </div>
          </form>
        </div>
      `);

    const pollSlider = $pollScaler.find(".ea-cf-pollRange").eaComponentSlider({
      ticks: [
        { slider: 0, label: "1%", value: 0.01 },
        { slider: 182, label: "10%", value: 0.1 },
        { slider: 383, label: "20%", value: 0.2 },
        { slider: 586, label: "30%", value: 0.3 },
        { slider: 788, label: "40%", value: 0.4 },
        { slider: 990, label: "50%", value: 0.5 },
        { slider: 1192, label: "60%", value: 0.6 },
        { slider: 1394, label: "70%", value: 0.7 },
        { slider: 1596, label: "80%", value: 0.8 },
        { slider: 1798, label: "90%", value: 0.9 },
        { slider: 2000, label: "100%", value: 1.0 },
      ],
      defaultTick: 3,
      onSelect: (val) => {
        var newDataConfig = this._shallowCopyDataConfig();
        newDataConfig.pollScale = val;
        this.update(newDataConfig);
      },
    })[0];
    $pollScaler.find(".ea-cf-pollRange").data({
      eaComponentSlider: pollSlider,
    });

    // Race filter options
    var $raceFilter = $(`
        <div id="raceFilterOptions">
          <form class="ea-cf-raceFilterForm">
            <div class="ea-cf-form-group">
              <label>Included candidates:</label>
            </div>

            <div class="ea-cf-form-group">
              <div class="ea-cf-raceFilterSelectorC"></div>
            </div>

            <span class="glyphicon glyphicon-question-sign popover-trigger
                         ea-cf-raceFilterExplanation"></span>
          </form>
        </div>
      `);

    var $collapseSec = $(`<div></div>`);

    const raceFilterSelect = $raceFilter
      .find(".ea-cf-raceFilterSelectorC")
      .eaComponentSelect({
        choices: [
          {
            value: "basic",
            display: "Clinton, Trump",
          },
          {
            value: "with-Johnson",
            display: "Clinton, Trump, Johnson",
          },
          {
            value: "with-Johnson-Stein",
            display: "Clinton, Trump, Johnson, Stein",
          },
        ],
        startValue: "basic",
        onChange: function (value) {
          const newDataConfig = this._shallowCopyDataConfig();
          newDataConfig.raceFilter = value;
          this.update(newDataConfig);
        }.bind(this),
      });
    $raceFilter.find(".ea-cf-raceFilterSelectorC").data({
      eaComponentSelect: raceFilterSelect[0],
    });

    var popoverTriggers = $raceFilter.find(".ea-cf-raceFilterExplanation");
    $.merge(popoverTriggers, $pollFilter.find(".ea-cf-pollFilterExplanation"));
    $.merge(
      popoverTriggers,
      $swingScenario.find(".ea-cf-swingScenarioExplanation")
    );
    $.merge(popoverTriggers, $pollScaler.find(".ea-cf-pollScalerExplanation"));

    popoverTriggers.on("shown.bs.popover", function () {
      $(this).next().find(".arrow").css("top", "-11px");
    });

    popoverTriggers.click({ popoverTriggers: popoverTriggers }, function () {
      var $popoverTrigger = $(this);
      popoverTriggers.not($popoverTrigger).popover("hide");
      $popoverTrigger.popover("toggle");
    });

    var makePopover = function ($elem, options) {
      var defaultPopoverOptions = {
        placement: "bottom",
        trigger: "manual",
        html: true,
      };
      _.defaults(options, defaultPopoverOptions);
      $elem.popover(options);
    };
    makePopover($raceFilter.find(".ea-cf-raceFilterExplanation"), {
      container: ".ea-cf-raceFilterExplanation",
      title: "Included Candidates",
      content: $.proxy(function () {
        var message = `For the 2016 presidential election, some
                              polling data includes third-party candidates
                              Gary Johnson (Libertarian) and Jill Stein
                              (Green). While these candidates are not
                              currently polling high enough to win any
                              state, their presence in the race may impact
                              the votes that the two major party candidates
                              receive. Including Johnson and/or Stein will
                              use these polls in states where they are
                              available.`;
        return message;
      }, this),
    });
    makePopover($pollFilter.find(".ea-cf-pollFilterExplanation"), {
      container: ".ea-cf-pollFilterExplanation",
      title: "Pollster Filter",
      content: $.proxy(function () {
        var message = `Exclude certain polling organizations from
                               consideration during computation of forecasts.
                               More info can be found at
                               <a href="/faq.php#custom">
                               Custom Forecasts FAQ</a>.`;
        return message;
      }, this),
    });
    makePopover($pollScaler.find(".ea-cf-pollScalerExplanation"), {
      container: ".ea-cf-pollScalerExplanation",
      title: "Weight of Polls",
      content: $.proxy(function () {
        var message = `Modify the influence of polls in determining
                               candidates's probabilities. More info can be
                               found on <a href="/about.php#pollWeight">
                               our about page</a>.`;
        return message;
      }, this),
    });
    makePopover($swingScenario.find(".ea-cf-swingScenarioExplanation"), {
      container: ".ea-cf-swingScenarioExplanation",
      title: "Undecided Swing",
      content: $.proxy(function () {
        var message = `Change the assignment of undecided voters to
                               either major party. More info can be found on
                               <a href="/about.php#undecidedSwing">
                               our about page</a>.`;
        return message;
      }, this),
    });

    var $selectBtn = $('<div class="row">' + "</div>");

    $selectBtn.find(".btn-customize-opt").on(
      "click",
      $.proxy(function () {
        // When toggling, hide all popovers
        this._$element.find(".popover-trigger").popover("hide");
        // Toggle collapsable elements
        this._$element.find(".collapse-customize").collapse("toggle");
      }, this)
    );

    /*
     * When navbar height changes, updates has to be made accordingly
     * for affix and scrollspy to work:
     *
     * 1.  update global variable navbarHeight;
     * 2.  update scrollspy offset to match current navbar height;
     * 3.  update the "content-filler" div so affix works correctly
     *     when switching from affix to inline.
     * TO DO: Find a more elegent way to update affix and scrollspy.
     */
    var updateScrollSpyAndAffix = function (topSpaceHeight) {
      var $body = $("body");
      // Get scrollspy data
      var scrollSpyData = $body.data("bs.scrollspy");
      if (scrollSpyData) {
        // Change scrollspy data offset
        scrollSpyData.options.offset = topSpaceHeight + 50;
        // Update scrollspy
        $body.data("bs.scrollspy", scrollSpyData);
        // Refresh scrollspy
        $body.scrollspy("refresh");
      }
      $("#controls-filler").animate({ height: topSpaceHeight }, 500);
    };
    // Get nav height after collapse toggle event, and update scroll and
    // affix accordingly after. This causes a delay in page animation, but
    // does not need window.navbarHeight to be hard coded in.
    $collapseSec.on(
      "shown.bs.collapse",
      $.proxy(function () {
        this._$element
          .find(".btn-customize-opt small")
          .text("Hide Customization Options");
        window.navbarHeight = $("#controls").outerHeight();
        updateScrollSpyAndAffix(window.navbarHeight);
      }, this)
    );
    $collapseSec.on(
      "hidden.bs.collapse",
      $.proxy(function () {
        this._$element
          .find(".btn-customize-opt small")
          .text("Show Customization Options");
        window.navbarHeight = $("#controls").outerHeight();
        updateScrollSpyAndAffix(window.navbarHeight);
      }, this)
    );

    window.setInterval(function () {
      var navbarSec = $("#controls");
      var customSec = $("#navbar-collapse");
      if (
        navbarSec.hasClass("navbar-scrolling") &&
        !navbarSec.hasClass("hover") &&
        customSec.hasClass("in")
      ) {
        customSec.collapse("hide");
      } else if (
        !navbarSec.hasClass("navbar-scrolling") &&
        !customSec.hasClass("in")
      ) {
        customSec.collapse("show");
      }
    }, 250);

    var timeEvent;
    $("#controls").on("mouseenter", function () {
      timeEvent = window.setTimeout(function () {
        if ($("#controls").hasClass("navbar-scrolling")) {
          $("#controls").addClass("hover");
          $("#navbar-collapse").collapse("show");
        }
      }, 1000);
    });
    $("#controls").on("mouseleave", function () {
      window.clearTimeout(timeEvent);
      $("#controls").removeClass("hover");
    });

    $("#navbar-collapse").on(
      "shown.bs.collapse",
      $.proxy(function () {
        window.navbarHeight = $("#controls").outerHeight();
        updateScrollSpyAndAffix(window.navbarHeight);
      }, this)
    );
    $("#navbar-collapse").on(
      "hidden.bs.collapse",
      $.proxy(function () {
        window.navbarHeight = $("#controls").outerHeight();
        updateScrollSpyAndAffix(window.navbarHeight);
      }, this)
    );

    $collapseSec
      .append($selectors)
      .append($pollFilter)
      .append($pollScaler)
      .append($swingScenario)
      .append($raceFilter);

    this._$element.append($collapseSec).append($selectBtn);
  } // end _initializeHtml

  /* Update
   * ---------------------------------------------------------------------- */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    this._disableSelectors();

    // Returns a filtered promise to avoid exposing excess data.
    // Make sure instance vars (e.g., races, customRaces) are updated, and
    // then update HTML elements on page. Finally, tell other modules on
    // page to update as well, and give them the customRaces to do so.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();

        /* TODO: Update this to do the appropriate thing once we have
           *       support for modifying individual poll weights
          // Populate the drop-down element with the races in this election.
          // Any change requires this to be updated, as races may change
          // from active to inactive depending on the forecast time.
          // After updating, attempt to manually set the selected race to
          // what it was before using
          //   `$raceSelector.val("<locAbbrev>,<indexInLoc>")`
          // assuming that the race is still available.
          this._updateRaceSelector(options);

          // Populate the summary table and polls table with the relevant
          // details from the selected race (and time) (do this on every
          // update)
          this._updateTables();
          */

        // Tell the other modules on the page to update with the given
        // options by triggering the 'modification' event for the
        // custom forecasts element
        //console.log("Telling modules on page to update..."); // DEBUG
        var changeEvent = $.Event("modification", {
          options: this._dataConfig,
        });
        this._$element.trigger(changeEvent);

        // Re-enable the selectors after an update
        this._enableSelectors();
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // DEBUG:
    //console.log("custom: `_updateData` called");
    //console.log(this._dataConfig);

    // Ensure that the year is valid; might not be if user switched from
    // Senate off-year to presidential without modifying the year
    this._dataConfig.year = election.validateYearForType(
      this._dataConfig.type,
      this._dataConfig.year
    );

    // Create a dataConfig without customizations, so we can retrieve the
    // base forecast times
    var dataConfigNoCustom = util.createDataConfigWithoutCustomizations(
      this._dataConfig
    );

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (racesNoCustom, races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Update list of available forecast times
      this._forecastTimes = util.getAllForecastTimes(
        dataConfigNoCustom,
        racesNoCustom
      );

      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get multiple Promise objects from data service API
    var dataPromise = $.when(
      data.getRacesWithForecasts(dataConfigNoCustom),
      data.getRacesWithForecasts(this._dataConfig)
    )
      .done(successCallback)
      .fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    // Update year selector
    const years = election.getValidYearsForType(this._dataConfig.type);
    const yearOptions = years.map((y) => ({ value: y, display: y }));
    const $yearSelector = this._$element.find(".ea-cf-yearSelector");
    if (
      $yearSelector.length > 0 &&
      $yearSelector.data("raceType") != this._dataConfig.type
    ) {
      $yearSelector.data("eaComponentSelect").update({
        choices: yearOptions,
        startValue: this._dataConfig.year,
      });
      $yearSelector.data("raceType", this._dataConfig.type);
    }

    // Update forecast selector
    const dateBox = this._$element
      .find(".ea-cf-dateBox")
      .data("eaComponentCalendar");
    const dateBoxType = this._$element.find(".ea-cf-dateBox").data("type");
    const dateBoxYear = this._$element.find(".ea-cf-dateBox").data("year");
    if (
      dateBox !== undefined &&
      (this._dataConfig.type != dateBoxType ||
        this._dataConfig.year != dateBoxYear)
    ) {
      dateBox.setBounds(this._forecastTimes);
      dateBox.selectDate(this._dataConfig.time);
      this._$element.find(".ea-cf-dateBox").data("type", this._dataConfig.type);
      this._$element.find(".ea-cf-dateBox").data("year", this._dataConfig.year);
    }

    // Update raceFilter
    if (
      this._dataConfig.type === "president" &&
      this._dataConfig.year == 2016
    ) {
      this._$element
        .find(".ea-cf-raceFilterSelectorC")
        .data("eaComponentSelect")
        .setValue(this._dataConfig.raceFilter);
      this._$element.find(".ea-cf-raceFilterForm").show();
    } else {
      this._$element.find(".ea-cf-raceFilterForm").hide();
    }

    return;
  } // end _updateHtml

  _shallowCopyDataConfig() {
    var newDataConfig = {
      type: this._dataConfig.type,
      year: this._dataConfig.year,
      time: this._dataConfig.time,
      pollFilters: [],
      swingScenario: this._dataConfig.swingScenario,
      pollScale: this._dataConfig.pollScale,
      raceFilter: this._dataConfig.raceFilter,
    };
    // Copy over poll filters
    var nFilters = this._dataConfig.pollFilters.length;
    for (var i = 0; i < nFilters; ++i) {
      newDataConfig.pollFilters.push(this._dataConfig.pollFilters[i]);
    }
    return newDataConfig;
  } // end _shallowCopyDataConfig

  // NOTE: Unused at present
  _updateRaceSelector() {
    var options = {}; // TODO: Fix this...
    // Add all of the races to the race selector
    var $raceSelector = this._$element.find(".ea-custom-forecasts-dropdown");
    // Default to previously selected race
    var raceToSelect = $raceSelector.val();
    // If a race is specified in options, make it selected
    if (options.selRaceLocAbbrev) {
      raceToSelect = options.selRaceLocAbbrev + ",";
      if (options.selRaceIndexInLoc) {
        raceToSelect += options.selRaceIndexInLoc;
      } else {
        raceToSelect += 0;
      }
    }
    var raceToSelectExists = false;
    var fTime = this._time;

    $raceSelector.empty(); //first want to empty the races
    $.each(this._races, function (locAbbrev, racesInLocation) {
      for (var i = 0; i < racesInLocation.length; i++) {
        // labeling by both locAbbrev and index
        var optionLabel = locAbbrev + "," + i;
        var currRace = racesInLocation[i];
        var $option = new Option(currRace.name, optionLabel);
        if (!util.isRaceActive(currRace, fTime)) {
          $option.disabled = true;
        } else if (optionLabel === raceToSelect) {
          // Race is active, so see if it equals the one to select
          raceToSelectExists = true;
        }
        $raceSelector.append($option);
      }
    });

    // Set the selected race in the drop-down element
    if (!raceToSelectExists) {
      raceToSelect = _.keys(this._races)[0] + ",0";
    }
    $raceSelector.val(raceToSelect);
  } // end _updateRaceSelector

  _updateTables() {
    // Get the selected race
    var $raceSelector = this._$element.find(".ea-custom-forecasts-dropdown");
    var selInfo = $raceSelector.val().split(",");
    var race = this._races[selInfo[0]][selInfo[1]];

    var raceIsActive = util.isRaceActive(race, this._time);
    var raceIsEnded = util.isRaceEnded(race, this._time);

    // Update the race status field
    var curTime = this._time.format("MMM DD, YYYY @ hh:mm:ss A");
    var statusText = "<small>(Race ";
    if (!raceIsActive) {
      statusText += "is <strong>inactive</strong> on " + curTime;
    } else if (raceIsEnded) {
      statusText +=
        "<strong>ended</strong> on " +
        race.endTime.format("MMM DD, YYYY @ hh:mm:ss A");
    } else {
      // active
      statusText += "is <strong>ongoing</strong> as of " + curTime;
    }
    statusText += ")</small>";
    this._$element.find("#race-status-field").html(statusText);

    // Build a quick look-up table for the candidates' names
    var cnamesByID = {};
    var responseSumsByID = {};
    $.each(race.candidates, function (cIdx, candidate) {
      cnamesByID[candidate.id] = candidate.lastName;
      responseSumsByID[candidate.id] = 0;
    });

    // Populate the polls table itself
    var $pollsTable = this._$element.find(".ea-custom-forecasts-polls-table");
    var $tbody = $pollsTable.find("tbody");
    $tbody.empty();

    var forecast = util.selectForecastAtTimeX(race, this._time);

    if (race.polls !== null && race.polls.length > 0) {
      for (var i = race.polls.length - 1; i >= 0; --i) {
        var poll = race.polls[i];
        var orgString = _.pluck(poll.organizations, "name").join(" / ");

        var pollWeight = 0.0;
        if (poll.id in forecast.pollWeights) {
          pollWeight = forecast.pollWeights[poll.id];
        }

        // Determine the poll responses
        var candString = "";
        $.each(poll.responses, function (cID, response) {
          candString += "" + cnamesByID[cID] + ": " + response + "%<br>";
          responseSumsByID[cID] += Math.floor(
            poll.size * response * pollWeight * 0.01
          );
        });

        // After using poll weight, set to N/A if not available
        if (this._time < poll.timeAdded || forecast.isActualOutcome) {
          pollWeight = "N/A";
        } else {
          pollWeight = math.sformat(pollWeight, 2);
        }

        $tbody.append(
          "<tr>" +
            "<td>" +
            orgString +
            "</td>" +
            "<td>" +
            poll.startDate.format("M/D/YYYY") +
            "</td>" +
            "<td>" +
            poll.endDate.format("M/D/YYYY") +
            "</td>" +
            "<td>" +
            poll.timeAdded.format("M/D/YYYY") +
            "<br>" +
            poll.timeAdded.format("h:mm A") +
            "</td>" +
            "<td>" +
            poll.size +
            "</td>" +
            "<td>" +
            poll.voterType +
            "</td>" +
            "<td>" +
            candString +
            "</td>" +
            "<td>" +
            pollWeight +
            "</td>" +
            "</tr>"
        );
      }
    } else {
      // No polls
      $tbody.append("<tr>" + '<td colspan="7">(No polls)</td>' + "</tr>");
    }

    // Populate the summary table after computing weights for candidates
    var $summaryTable = this._$element.find(
      ".ea-custom-forecasts-summary-table"
    );
    $tbody = $summaryTable.find("tbody");
    $tbody.empty();

    var fTime = this._time;
    $.each(race.candidates, function (cIdx, candidate) {
      var cID = candidate.id;
      if (util.isCandidateActive(candidate, fTime)) {
        var relMajProb = 0.0;
        if (cID in forecast.candRelativeMajorityProbs) {
          relMajProb = forecast.candRelativeMajorityProbs[cID];
        }
        var absMajProb = 0.0;
        if (cID in forecast.candAbsoluteMajorityProbs) {
          absMajProb = forecast.candAbsoluteMajorityProbs[cID];
        }
        var undProp = 0.0;
        if (cID in forecast.candUndecidedProportions) {
          undProp = forecast.candUndecidedProportions[cID];
        }
        $tbody.append(
          "<tr>" +
            "<td>" +
            candidate.lastName +
            " (" +
            candidate.party.charAt(0) +
            ")</td>" +
            "<td>" +
            math.sformat(candidate.prior, 3) +
            "</td>" +
            "<td>" +
            math.sformat(undProp, 2) +
            "</td>" +
            "<td>" +
            responseSumsByID[cID] +
            "</td>" +
            "<td>" +
            math.sformat(relMajProb, 3) +
            "</td>" +
            "<td>" +
            math.sformat(absMajProb, 3) +
            "</td>" +
            "</tr>"
        );
      } else {
        $tbody.append(
          "<tr>" +
            "<td>" +
            candidate.lastName +
            " (" +
            candidate.party.charAt(0) +
            ")</td>" +
            '<td colspan="5"><small>(inactive on ' +
            curTime +
            ")</small></td>" +
            "</tr>"
        );
      }
    });
  } // end _updateTables

  _disableSelectors() {
    this._$element.find(".ea-cf-selectors :input").prop("disabled", true);
    const $yearSelector = this._$element.find(".ea-cf-yearSelector");
    if ($yearSelector.length > 0) {
      $yearSelector.data("eaComponentSelect").disable();
    }
    this._$element.find(".ea-cf-dateBox").data("eaComponentCalendar").disable();
    this._$element.find(".ea-cf-pollFilterForm :input").prop("disabled", true);
    this._$element
      .find(".ea-cf-colorRange")
      .data("eaComponentSlider")
      .disable();
    this._$element.find(".ea-cf-pollRange").data("eaComponentSlider").disable();
    this._$element
      .find(".ea-cf-raceFilterSelectorC")
      .data("eaComponentSelect")
      .disable();
  } // end _disableSelectors

  _enableSelectors() {
    this._$element.find(".ea-cf-selectors :input").prop("disabled", false);
    const $yearSelector = this._$element.find(".ea-cf-yearSelector");
    if ($yearSelector.length > 0) {
      $yearSelector.data("eaComponentSelect").enable();
    }
    this._$element.find(".ea-cf-dateBox").data("eaComponentCalendar").enable();
    this._$element.find(".ea-cf-pollFilterForm :input").prop("disabled", false);
    this._$element.find(".ea-cf-colorRange").data("eaComponentSlider").enable();
    this._$element.find(".ea-cf-pollRange").data("eaComponentSlider").enable();
    this._$element
      .find(".ea-cf-raceFilterSelectorC")
      .data("eaComponentSelect")
      .enable();
  } // end _enableSelectors

  /* Error
   * ---------------------------------------------------------------------- */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(`
        <div class="alert alert-error">
          Something seems to have gone wrong with our Custom Forecasts module.
          We'll be looking into the issue shortly. Perhaps a refresh would
          help?
        </div>
      `);
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Custom Forecasts module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(`The Custom Forecasts module's constructor received an
                      invalid options parameter: it wasn't an object.`);
    } else if (type === "constructor: no type") {
      console.warn(`The Custom Forecasts module's constructor requires a
                      'type' option.`);
    } else if (type === "constructor: no year") {
      console.warn(`The Custom Forecasts module's constructor requires a
                      'year' option.`);
    } else {
      console.warn(`An unknown error occurred in the Custom Forecasts
                      module.`);
    }
  } // end error
} // end EACustomForecasts prototype

/* jQuery Plugin & Autoloading
 * -------------------------------------------------------------------------- */
// jQuery Plugin Definition
$.fn.eaCustomForecasts = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var customForecastsObjs = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    customForecastsObjs.push(new EACustomForecasts($element, myOptions));
  });

  return customForecastsObjs;
};

// Autoloader
$(function () {
  $(".ea-custom-forecasts").eaCustomForecasts();
});
