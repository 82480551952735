/* Module: Location Overview
 * ==========================================================================
 *
 * The Location Overview module lists information about the races in each
 * location.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/location-overview.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `selectedLoc`:   Race location for selector
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";

export class EALocationOverview {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-location-overview");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._updateHtml();
      }, this)
    );
  } // end constructor

  _initializeHtml() {
    var $title = $('<h1 class="ea-location-overview-title"></h1>');

    this._titleModule = $title.eaTitle({
      format: "Overview of Races by Location in the {year} {type} Election",
    })[0];

    var $descriptor = $("<p id=descriptor-block></p>");

    // Create drop-down selector for choosing races
    var $locSelect = $(
      '<select class="ea-location-overview-dropdown"></select>'
    );
    var callUpdate = $.proxy(function () {
      this._moduleConfig.selectedLoc = $locSelect.val();
      $locSelect.prop("disabled", true);
      this._updateHtml();
    }, this);

    $locSelect.change(callUpdate).prop("disabled", true);

    // Create races table with header info
    var $racesTable = $(
      '<table class="table table-striped ea-location-overview-table">' +
        "<thead>" +
        "<tr>" +
        "<th>Race</th>" +
        "<th>Subtype</th>" +
        "<th>Election<br>Date</th>" +
        "<th>Time<br>Added</th>" +
        "<th>Time<br>Removed</th>" +
        "<th>End<br>Time</th>" +
        "<th>Candidates</th>" +
        "</tr>" +
        "</thead>" +
        "<tbody></tbody>" +
        "</table>"
    );

    this._$element
      .append($title)
      .append($descriptor)
      .append($locSelect)
      .append($racesTable);
  } // end _initializeHtml

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    this._$element
      .find(".ea-location-overview-dropdown")
      .prop("disabled", true);

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
        this._$element
          .find(".ea-location-overview-dropdown")
          .prop("disabled", false);
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-location-overview-title").show();
    } else {
      this._$element.find(".ea-location-overview-title").hide();
    }

    // Populate the drop-down element with locations in this election
    this._updateLocSelector();

    this._updateRacesTable();

    this._$element
      .find(".ea-location-overview-dropdown")
      .prop("disabled", false);
  } // end _updateHtml

  _updateLocSelector() {
    // Add all of the locations to the location selector
    var $locSelect = this._$element.find(".ea-location-overview-dropdown");
    $locSelect.empty();
    $.each(_.keys(this._races), function (keyIdx, locAbbrev) {
      $locSelect.append(new Option(locAbbrev, locAbbrev));
    });

    // If a location is specified in options, make it selected
    var selLocAbbrev = null;
    if (this._moduleConfig.selectedLoc) {
      selLocAbbrev = this._moduleConfig.selectedLoc;
    } else {
      selLocAbbrev = _.keys(this._races)[0];
    }
    // Set the selected location in the drop-down element
    $locSelect.val(selLocAbbrev);
  } // end _updateLocSelector

  _updateRacesTable() {
    // Get the selected location
    var $locSelector = this._$element.find(".ea-location-overview-dropdown");
    var selLocAbbrev = $locSelector.val();

    // Compute the locResults for storing in the table
    // NOTE: Not currently used; uncomment if needed
    //var locResults = probs.computeLocationResults(
    //    this._dataConfig
    //  , this._races
    //);

    // Populate the races table itself
    var $racesTable = this._$element.find(".ea-location-overview-table");
    var $tbody = $racesTable.find("tbody");
    $tbody.empty();

    var racesInLocation = this._races[selLocAbbrev];
    //var locResult = locResults[selLocAbbrev];

    var nRaces = racesInLocation.length;
    for (var i = 0; i < nRaces; ++i) {
      var race = racesInLocation[i];
      var forecast = util.selectForecastAtTimeX(race, this._dataConfig.time);

      var candString = "";
      var candidates = race.candidates;
      var nCands = candidates.length;
      for (var k = 0; k < nCands; ++k) {
        var cand = candidates[k];
        if (!util.isCandidateActive(cand, this._dataConfig.time)) {
          // TODO: Come up with a better color scheme for inactive cands
          candString +=
            '<font color="grey">' + cand.lastName + ": N/A<br></font>";
        } else {
          var prob = forecast.candRelativeMajorityProbs[cand.id];
          if (prob === null) {
            prob = 0.0;
          }
          candString += "" + cand.lastName + ": " + prob + "<br>";
        }
      }
      // TODO: Come up with a better color scheme for inactive races
      var fRaceName = util.isRaceActive(race, this._dataConfig.time)
        ? race.name
        : '<font color="grey">' + race.name + "</font>";
      var fRaceDate = race.raceDate.format("M/D/YYYY");
      var fTimeAdded =
        race.timeAdded !== null
          ? race.timeAdded.format("M/D/YYYY") +
            "<br>" +
            race.timeAdded.format("h:mm A")
          : "---";
      var fTimeRemoved =
        race.timeRemoved !== null
          ? race.timeRemoved.format("M/D/YYYY") +
            "<br>" +
            race.timeRemoved.format("h:mm A")
          : "---";
      var fEndTime =
        race.endTime !== null
          ? race.endTime.format("M/D/YYYY") +
            "<br>" +
            race.endTime.format("h:mm A")
          : "---";
      $tbody.append(
        "<tr>" +
          "<td>" +
          fRaceName +
          "</td>" +
          "<td>" +
          race.subtype +
          "</td>" +
          "<td>" +
          fRaceDate +
          "</td>" +
          "<td>" +
          fTimeAdded +
          "</td>" +
          "<td>" +
          fTimeRemoved +
          "</td>" +
          "<td>" +
          fEndTime +
          "</td>" +
          "<td>" +
          candString +
          "</td>" +
          "</tr>"
      );
    }
  } // end _updateRacesTable

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Location Overview robot... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The LocationOverview module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The LocationOverview module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The LocationOverview module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The LocationOverview module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the LocationOverview module.");
    }

    // TODO: notify devs
  } // end error
} // end EALocationOverview.prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaLocationOverview = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var locOverviews = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    locOverviews.push(new EALocationOverview($element, myOptions));
  });

  return locOverviews;
};

// Autoloader
$(function () {
  $(".ea-location-overview").eaLocationOverview();
});
