import $ from "jquery";

/* API Service
 * ============================================================================
 *
 * A service layer that interacts with the backend API.  It contains basic
 * functions to perform CRUD operations, as well as a `request` function to
 * handle the details of making an ajax request to the Api.
 *
 * Any further Api interactive functionality should be added in its own service
 * and call the `ea.service.api.request` method to make an Api request.
 *
 * For example, if the Poll object needs some pre-processing before an `update`,
 * an `ea.service.poll` service should be added with an `update` function that
 * calls the `ea.service.api.update` function after the preprocessing is
 * performed.  Similarly, a non-creud function `mergePolls` may be put in
 * `ea.service.poll.mergePolls` and call `ea.service.api.request`.
 *
 * **About jqXHR Objects**
 *
 * jQuery XHR objects (jqXHR) are promises which are fulfilled by the status of
 * an ajax request:
 *
 *  * `jqXHR.done(success)`            - called on a successful request
 *  * `jqXHR.fail(error)`              - called on a failed request
 *  * `jqXHR.always(success or error)` - called regardless of status, with either
 *                                       the success or error parameters
 *  * `jqXHR.complete(success, error)` - a shorthand for adding both success and
 *                                       failure functions
 *
 * For more info, see the jqXHR section at http://api.jquery.com/jQuery.ajax/.
 */

/**
 * Request; Make an API request.
 *
 * @param type the type of object to access
 * @param action the api action to perform
 * @param data supporting information for the request, such as an
 * object or id
 *
 * **Returns**
 *
 * A jQuery XHR object (jqXHR) that implements the Promise interface.
 * The jqXHR resolved and failed callbacks are called with a packed
 * return object, with fields of the same name as the jQuery
 * [Ajax Deferred arguments](http://api.jquery.com/jQuery.ajax/#jqXHR).
 *
 * **Example Usage**
 *
 * ea.service.api.request('Data', 'getRaces', electionDataObj)
 *   .done(function(data, textStatus, jqXHR) {
 *      // success code here
 *   });
 */
export function request(type: string, action: string, data: any): JQuery.jqXHR {
  var requestPayload = JSON.stringify({
    object: type,
    action: action,
    data: data,
  });

  return $.ajax({
    contentType: "application/json",
    data: requestPayload,
    dataType: "json",
    processData: false,
    type: "POST",
    url: "/core/api/api.php",
  });
}

export function _request(
  type: string,
  action: string,
  data: any
): Promise<Response> {
  return fetch("/core/api/api.php", {
    body: JSON.stringify({
      object: type,
      action: action,
      data: data,
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
