import $ from "jquery";
import { Cache } from "./cache";
import * as api from "./api";
import * as time from "./time";
import * as util from "./util";
import * as custom from "./custom";

const cache: Cache = new Cache();

/**
 * _get
 * ------------------------------------------------------------
 * Private helper function for creating API requests.
 *
 * @param cacheLabel the namespace for stored results in cache
 * @param requestAction the action to pass to the server's API layer
 * @param requestData the data to pass to the server's API layer
 * @param postProcessor a function used to filter returned data
 *
 * @returns A jQuery Promise object to which additional success and fail callbacks
 * can be attached. The `done` callback receives the data requested from
 * the server. The `fail` callback receives an errorInfo object
 * containing `statusCode` and `statusText` fields.
 */
function _get(
  cacheLabel: string[],
  requestAction: string,
  requestData: any,
  postProcessor: (data: any) => any
) {
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else make an API request to retrieve the data.
  // loadData is a jqXHR object created by $.ajax() which implements
  // the `Promise` interface; this allows us to attach the postProcessor
  // function to be called once the AJAX call returns, along with an
  // error function that returns the relevant information to the caller
  // for it to deal with.
  /* jshint unused: false */

  var loadData = api.request("Data", requestAction, requestData).then(
    (data: any, textStatus: any, jqXHR: any) => {
      // Pass through the post-processed data, removing rest
      return postProcessor(data);
    },
    (jqXHR, textStatus, errorThrown) => {
      console.log(jqXHR.responseText);
      console.log(
        "Action: " + requestAction + "; Params: " + JSON.stringify(requestData)
      );
      console.warn("API request failed: " + textStatus);
      // Pass through the jqXHR's status and responseText values
      return {
        statusCode: jqXHR.status,
        statusText: jqXHR.responseText,
      };
    }
  );
  /* jshint unused: true */
  // Rather than returning the jqXHR object itself, we return an
  // associated Promise object, which filters out some unnecessary
  // details of the jqXHR object while still allowing users to access
  // the data and attach additional callback functions
  var loadDataPromise = loadData.promise();
  // update the cache with the promise
  cache.set(cacheLabel, loadDataPromise);

  return loadDataPromise;
}

/**
 * getRaces
 * ------------------------------------------------------------
 * Get all races in the specified election.
 *
 * @param dataConfig object containing at least the type of election
 * (e.g., president, senate) and the year
 *
 * @returns A jQuery Promise object to which additional success and fail callbacks
 * can be attached. The `done` callback receives an array of races sorted
 * by ID.
 */
export function getRaces(dataConfig: { type: string; year: string }): any {
  // Only cache based on type and year; other dataConfig options will
  // not affect the return values
  var cacheLabel = ["races", dataConfig.type, dataConfig.year];
  var requestAction = "getRaces";
  var requestData = {
    type: dataConfig.type,
    year: dataConfig.year,
  };

  var racePostProcessor = function (races: any) {
    // Convert all dates to moment objects
    $.each(races, function (rIdx, race) {
      race.raceDate = moment
        .tz(race.raceDate.date, race.raceDate.timezone)
        .tz(time.getLocalTimezone());
      if (race.timeAdded !== null) {
        race.timeAdded = moment
          .tz(race.timeAdded.date, race.timeAdded.timezone)
          .tz(time.getLocalTimezone());
      }
      if (race.timeRemoved !== null) {
        race.timeRemoved = moment
          .tz(race.timeRemoved.date, race.timeRemoved.timezone)
          .tz(time.getLocalTimezone());
      }
      if (race.endTime !== null) {
        race.endTime = moment
          .tz(race.endTime.date, race.endTime.timezone)
          .tz(time.getLocalTimezone());
      }

      for (var j = 0; j < race.candidates.length; j++) {
        var candidate = race.candidates[j];
        if (candidate.dob !== null) {
          candidate.dob = moment
            .tz(candidate.dob.date, candidate.dob.timezone)
            .tz(time.getLocalTimezone());
        }
        if (candidate.timeEntered !== null) {
          candidate.timeEntered = moment
            .tz(candidate.timeEntered.date, candidate.timeEntered.timezone)
            .tz(time.getLocalTimezone());
        }
        if (candidate.timeWithdrew !== null) {
          candidate.timeWithdrew = moment
            .tz(candidate.timeWithdrew.date, candidate.timeWithdrew.timezone)
            .tz(time.getLocalTimezone());
        }
      }
    }); // end $.each(races, ...)
    // After converting race data, pass it through
    return races;
  }; // end apiSuccessFunction
  // Store return in temp variable to make explicit what we're returning
  var racesPromise = _get(
    cacheLabel,
    requestAction,
    requestData,
    racePostProcessor
  );
  return racesPromise;
}

/**
 * getForecasts
 * ------------------------------------------------------------
 * Get all forecasts in the specified election.
 *
 * @param dataConfig object containing at least the type of election
 * (e.g., president, senate) and the year; does NOT
 * support customization options here (use one of the
 * `getRacesWith...` functions for customizing)
 *
 * @param withWeights [Optional] boolean flag indicating if poll weights
 * should also be retrieved
 *
 * @returns A jQuery Promise object to which additional success and fail callbacks
 * can be attached. The `done` callback receives an array of forecasts
 * sorted by raceID.
 */
export function getForecasts(dataConfig: any, withWeights?: boolean) {
  withWeights = typeof withWeights === undefined ? false : withWeights;
  // Only cache based on type and year; other dataConfig options not
  // supported here
  var cacheLabel = util.generateDataCacheLabel("forecasts", dataConfig);
  var requestAction = "getForecasts";
  if (withWeights) {
    cacheLabel[0] += "WithWeights";
    requestAction += "WithWeights";
  }
  var requestData = {
    type: dataConfig.type,
    year: dataConfig.year,
    swingScenario: dataConfig.swingScenario,
    pollScale: dataConfig.pollScale,
    pollFilters: dataConfig.pollFilters,
  };
  var forecastPostProcessor = (forecasts: any) => {
    // Convert all dates to moment objects
    $.each(forecasts, function (fIdx, forecast) {
      forecast.forecastTime = moment
        .tz(forecast.forecastTime.date, forecast.forecastTime.timezone)
        .tz(time.getLocalTimezone());
    });
    // Sort forecasts by raceID
    forecasts.sort((a: any, b: any) => {
      if (a.raceID === b.raceID) {
        if (a.forecastTime === b.forecastTime) {
          return 0;
        }
        return a.forecastTime < b.forecastTime ? -1 : 1;
      }
      return a.raceID < b.raceID ? -1 : 1;
    });
    // Pass the forecasts through
    return forecasts;
  }; // end apiSuccessFunction
  // Store return in temp variable to make explicit what we're returning
  var forecastsPromise = _get(
    cacheLabel,
    requestAction,
    requestData,
    forecastPostProcessor
  );
  return forecastsPromise;
}

/**
 * getPolls
 * ------------------------------------------------------------
 * Get all polls in the specified election.
 *
 * @param dataConfig object containing at least the type of election
 * (e.g., president, senate) and the year
 *
 * @returns A jQuery Promise object to which additional success and fail callbacks
 * can be attached. The `done` callback receives an array of polls sorted
 * by raceID.
 */
export function getPolls(dataConfig: any): any {
  // Only cache based on type and year; other dataConfig options will
  // not affect the return values
  var cacheLabel = ["polls", dataConfig.type, dataConfig.year];
  var requestAction = "getPolls";
  var requestData = {
    type: dataConfig.type,
    year: dataConfig.year,
    swingScenario: dataConfig.swingScenario,
    pollScale: dataConfig.pollScale,
    pollFilters: dataConfig.pollFilters,
  };
  var pollPostProcessor = function (polls: any) {
    // Convert all dates to moment objects
    $.each(polls, function (pIdx, poll) {
      poll.startDate = moment
        .tz(poll.startDate.date, poll.startDate.timezone)
        .tz(time.getLocalTimezone());
      poll.endDate = moment
        .tz(poll.endDate.date, poll.endDate.timezone)
        .tz(time.getLocalTimezone());
      poll.timeAdded = moment
        .tz(poll.timeAdded.date, poll.timeAdded.timezone)
        .tz(time.getLocalTimezone());
    }); // end $.each(polls, ...)
    // Sort polls by raceID
    polls.sort(function (a: any, b: any) {
      if (a.raceID === b.raceID) {
        if (a.timeAdded === b.timeAdded) {
          return 0;
        }
        return a.timeAdded < b.timeAdded ? -1 : 1;
      }
      return a.raceID < b.raceID ? -1 : 1;
    });
    // Pass the polls through
    return polls;
  }; // end apiSuccessFunction
  // Store return in temp variable to make explicit what we're returning
  var pollsPromise = _get(
    cacheLabel,
    requestAction,
    requestData,
    pollPostProcessor
  );
  return pollsPromise;
}

/**
 * getRacesWithForecasts
 * ------------------------------------------------------------
 * Get all races along with forecasts in the specified election.
 * Supports custom forecasts by calling `getRacesWithForecastsAndPolls`.
 *
 * @param dataConfig object containing at least the type of election
 * (e.g., president, senate) and the year
 *
 * @returns A jQuery Promise object to which additional success and fail callbacks
 * can be attached. The `done` callback receives an associative array of
 * (<locAbbrev> => [<race0>,<race1>,..]) pairs, with each race including
 * its own forecasts.
 */
export function getRacesWithForecasts(dataConfig: any): any {
  // If dataConfig contains customization options, we will need to
  // retrieve polls in order to build the custom forecasts, so just call
  // that function and use it as the point of entry for supporting
  // custom forecasts.
  // if (ea.service.util.configContainsCustomizations(dataConfig)) {
  //   return this.getRacesWithForecastsAndPolls(dataConfig);
  // }
  // Else we're just doing a request for regular forecasts. As such, the
  // cache label only needs to support type and year.
  var cacheLabel = util.generateDataCacheLabel(
    "racesWithForecasts",
    dataConfig
  );
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else construct the promise object, store it, and return it
  var racesWithForecastsPromise = $.when(
    getRaces(dataConfig),
    getForecasts(dataConfig)
  ).then((races, forecasts) => {
    var racesByLocation: any = {};
    // Merge forecasts into the races and group races by location
    var nRaces = races.length;
    var nForecasts = forecasts.length;
    var j = 0; // Indexes forecasts
    for (var i = 0; i < nRaces; ++i) {
      // Make a shallow copy of the race object, so that forecasts
      // aren't added to existing race objects stored in the cache.
      var race = shallowCopyRace(races[i]);
      //var race = races[i];
      race.forecasts = [];
      for (; j < nForecasts && forecasts[j].raceID === race.id; j++) {
        race.forecasts.push(forecasts[j]);
      }
      var locAbbrev = race.location.abbrev;
      if (!(locAbbrev in racesByLocation)) {
        racesByLocation[locAbbrev] = [];
      }
      racesByLocation[locAbbrev].push(race);
    }
    return racesByLocation;
  }); // no fail function override; just pass along error info
  cache.set(cacheLabel, racesWithForecastsPromise);
  return racesWithForecastsPromise;
}

/**
 * getRacesWithForecastsAndPolls
 * ------------------------------------------------------------
 * Get all races with forecasts and polls in the specified election.
 * Supports customization options.
 *
 * @param dataConfig object containing at least the type of election
 * (e.g., president, senate) and the year
 *
 * @returns A jQuery Promise object to which additional success and fail callbacks
 * can be attached. The `done` callback receives an associative array of
 * (<locAbbrev> => [<race0>,<race1>,..]) pairs, with each race including
 * its own forecasts (including poll weights) and polls.
 */
export function getRacesWithForecastsAndPolls(dataConfig: any) {
  var cacheLabel = util.generateDataCacheLabel(
    "racesWithForecastsAndPolls",
    dataConfig
  );
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else construct the promise object, store it, and return it
  var racesForecastsAndPollsPromise = $.when(
    getRaces(dataConfig),
    getForecasts(dataConfig, false),
    getPolls(dataConfig)
  )
    .then((races, forecasts, polls) => {
      var racesByLocation: any = {};
      // Merge forecasts, polls into races while grouping by location
      var nRaces = races.length;
      var nForecasts = forecasts.length;
      var nPolls = polls.length;
      var j = 0; // Indexes forecasts
      var k = 0; // Indexes polls
      for (var i = 0; i < nRaces; ++i) {
        // Make a shallow copy of the race object, so that forecasts
        // and polls aren't added to existing objects stored in cache.
        var race = shallowCopyRace(races[i]);
        race.forecasts = [];
        for (; j < nForecasts && forecasts[j].raceID === race.id; j++) {
          race.forecasts.push(forecasts[j]);
        }
        race.polls = [];
        for (; k < nPolls && polls[k].raceID === race.id; k++) {
          race.polls.push(polls[k]);
        }
        var locAbbrev = race.location.abbrev;
        if (!(locAbbrev in racesByLocation)) {
          racesByLocation[locAbbrev] = [];
        }
        racesByLocation[locAbbrev].push(race);
      }
      // Add customization support here
      if (util.configContainsCustomizations(dataConfig)) {
        var customDataPromise = custom.createCustomForecasts(
          dataConfig,
          racesByLocation
        );
        return customDataPromise;
      } else {
        // Return empty promise with races passed through if no
        // customization is needed
        return $.Deferred()
          .resolve()
          .promise()
          .then(function () {
            return racesByLocation;
          });
      }
    })
    .then(function (racesByLocation) {
      return racesByLocation;
    }); // no fail function override; just pass along error info
  cache.set(cacheLabel, racesForecastsAndPollsPromise);
  return racesForecastsAndPollsPromise;
}

/**
 * shallowCopyRace
 * ------------------------------------------------------------
 * Public helper function to create a shallow copy of a race object.
 * NOTE: Could use `$.extend({}, race)`, but this should be faster
 * (http://stackoverflow.com/a/5344074); just need to update code with
 * new race fields as they are included.
 */
export function shallowCopyRace(race: any): any {
  return {
    id: race.id,
    type: race.type,
    year: race.year,
    name: race.name,
    location: race.location,
    subtype: race.subtype,
    raceDate: race.raceDate,
    timeAdded: race.timeAdded,
    timeRemoved: race.timeRemoved,
    endTime: race.endTime,
    totalVotes: race.totalVotes,
    candidates: race.candidates,
    forecasts: race.forecasts,
    polls: race.polls,
  };
}
