import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";

export class EASankeyGraph {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-sankey");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    // First load any necessary data, then initialize HTML and update it.
    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._initializeSankey();
        this._updateHtml();
      }, this)
    );
  } // end constructor

  _initializeHtml() {
    var $title = $('<h1 class="ea-sankey-title"></h1>');
    var $sankey = $('<div class="ea-sankey-graph"></div>');

    this._titleModule = $title.eaTitle({
      format: "", // format is set in update()
    })[0];

    this._$element.append($title).append($sankey);
  } // end _initializeHtml

  _initializeSankey() {
    // an implementation of the sankey graph
    d3.sankey = function () {
      var sankey = {},
        nodeWidth = 24,
        nodePadding = 8,
        size = [1, 1],
        nodes = [],
        links = [];

      sankey.nodeWidth = function (_) {
        if (!arguments.length) return nodeWidth;
        nodeWidth = +_;
        return sankey;
      };

      sankey.nodePadding = function (_) {
        if (!arguments.length) return nodePadding;
        nodePadding = +_;
        return sankey;
      };

      sankey.nodes = function (_) {
        if (!arguments.length) return nodes;
        nodes = _;
        return sankey;
      };

      sankey.links = function (_) {
        if (!arguments.length) return links;
        links = _;
        return sankey;
      };

      sankey.size = function (_) {
        if (!arguments.length) return size;
        size = _;
        return sankey;
      };

      sankey.layout = function (iterations) {
        computeNodeLinks();
        computeNodeValues();
        computeNodeBreadths();
        computeNodeDepths(iterations);
        computeLinkDepths();
        return sankey;
      };

      sankey.relayout = function () {
        computeLinkDepths();
        return sankey;
      };

      sankey.link = function () {
        var curvature = 0.5;

        function link(d) {
          var x0 = d.source.x + d.source.dx,
            x1 = d.target.x,
            xi = d3.interpolateNumber(x0, x1),
            x2 = xi(curvature),
            x3 = xi(1 - curvature),
            y0 = d.source.y + d.sy + d.dy / 2,
            y1 = d.target.y + d.ty + d.dy / 2;
          return (
            "M" +
            x0 +
            "," +
            y0 +
            "C" +
            x2 +
            "," +
            y0 +
            " " +
            x3 +
            "," +
            y1 +
            " " +
            x1 +
            "," +
            y1
          );
        }

        link.curvature = function (_) {
          if (!arguments.length) return curvature;
          curvature = +_;
          return link;
        };

        return link;
      };

      // Populate the sourceLinks and targetLinks for each node.
      // Also, if the source and target are not objects, assume they are indices.
      function computeNodeLinks() {
        nodes.forEach(function (node) {
          node.sourceLinks = [];
          node.targetLinks = [];
        });
        links.forEach(function (link) {
          var source = link.source,
            target = link.target;
          if (typeof source === "number")
            source = link.source = nodes[link.source];
          if (typeof target === "number")
            target = link.target = nodes[link.target];
          source.sourceLinks.push(link);
          target.targetLinks.push(link);
        });
      }

      // Compute the value (size) of each node by summing the associated links.
      function computeNodeValues() {
        nodes.forEach(function (node) {
          node.value = Math.max(
            d3.sum(node.sourceLinks, value),
            d3.sum(node.targetLinks, value)
          );
        });
      }

      // Iteratively assign the breadth (x-position) for each node.
      // Nodes are assigned the maximum breadth of incoming neighbors plus one;
      // nodes with no incoming links are assigned breadth zero, while
      // nodes with no outgoing links are assigned the maximum breadth.
      function computeNodeBreadths() {
        var remainingNodes = nodes,
          nextNodes,
          x = 0;

        while (remainingNodes.length) {
          nextNodes = [];
          remainingNodes.forEach(function (node) {
            node.x = x;
            node.dx = nodeWidth;
            node.sourceLinks.forEach(function (link) {
              nextNodes.push(link.target);
            });
          });
          remainingNodes = nextNodes;
          ++x;
        }

        //
        moveSinksRight(x);
        scaleNodeBreadths((width - nodeWidth) / (x - 1));
      }

      function moveSourcesRight() {
        nodes.forEach(function (node) {
          if (!node.targetLinks.length) {
            node.x =
              d3.min(node.sourceLinks, function (d) {
                return d.target.x;
              }) - 1;
          }
        });
      }

      function moveSinksRight(x) {
        nodes.forEach(function (node) {
          if (!node.sourceLinks.length) {
            node.x = x - 1;
          }
        });
      }

      function scaleNodeBreadths(kx) {
        nodes.forEach(function (node) {
          node.x *= kx;
        });
      }

      function computeNodeDepths(iterations) {
        var nodesByBreadth = d3
          .nest()
          .key(function (d) {
            return d.x;
          })
          .sortKeys(d3.ascending)
          .entries(nodes)
          .map(function (d) {
            return d.values;
          });

        //
        initializeNodeDepth();
        resolveCollisions();
        for (var alpha = 1; iterations > 0; --iterations) {
          relaxRightToLeft((alpha *= 0.99));
          resolveCollisions();
          relaxLeftToRight(alpha);
          resolveCollisions();
        }

        function initializeNodeDepth() {
          var ky = d3.min(nodesByBreadth, function (nodes) {
            return (
              (size[1] - (nodes.length - 1) * nodePadding) /
              d3.sum(nodes, value)
            );
          });

          nodesByBreadth.forEach(function (nodes) {
            nodes.forEach(function (node, i) {
              node.y = i;
              node.dy = node.value * ky;
            });
          });

          links.forEach(function (link) {
            link.dy = link.value * ky;
          });
        }

        function relaxLeftToRight(alpha) {
          nodesByBreadth.forEach(function (nodes, breadth) {
            nodes.forEach(function (node) {
              if (node.targetLinks.length) {
                var y =
                  d3.sum(node.targetLinks, weightedSource) /
                  d3.sum(node.targetLinks, value);
                node.y += (y - center(node)) * alpha;
              }
            });
          });

          function weightedSource(link) {
            return center(link.source) * link.value;
          }
        }

        function relaxRightToLeft(alpha) {
          nodesByBreadth
            .slice()
            .reverse()
            .forEach(function (nodes) {
              nodes.forEach(function (node) {
                if (node.sourceLinks.length) {
                  var y =
                    d3.sum(node.sourceLinks, weightedTarget) /
                    d3.sum(node.sourceLinks, value);
                  node.y += (y - center(node)) * alpha;
                }
              });
            });

          function weightedTarget(link) {
            return center(link.target) * link.value;
          }
        }

        function resolveCollisions() {
          nodesByBreadth.forEach(function (nodes) {
            var node,
              dy,
              y0 = 0,
              n = nodes.length,
              i;

            // Push any overlapping nodes down.
            nodes.sort(ascendingDepth);
            for (i = 0; i < n; ++i) {
              node = nodes[i];
              dy = y0 - node.y;
              if (dy > 0) node.y += dy;
              y0 = node.y + node.dy + nodePadding;
            }

            // If the bottommost node goes outside the bounds, push it back up.
            dy = y0 - nodePadding - size[1];
            if (dy > 0) {
              y0 = node.y -= dy;

              // Push any overlapping nodes back up.
              for (i = n - 2; i >= 0; --i) {
                node = nodes[i];
                dy = node.y + node.dy + nodePadding - y0;
                if (dy > 0) node.y -= dy;
                y0 = node.y;
              }
            }
          });
        }

        function ascendingDepth(a, b) {
          return a.y - b.y;
        }
      }

      function computeLinkDepths() {
        nodes.forEach(function (node) {
          node.sourceLinks.sort(ascendingTargetDepth);
          node.targetLinks.sort(ascendingSourceDepth);
        });
        nodes.forEach(function (node) {
          var sy = 0,
            ty = 0;
          node.sourceLinks.forEach(function (link) {
            link.sy = sy;
            sy += link.dy;
          });
          node.targetLinks.forEach(function (link) {
            link.ty = ty;
            ty += link.dy;
          });
        });

        function ascendingSourceDepth(a, b) {
          return a.source.y - b.source.y;
        }

        function ascendingTargetDepth(a, b) {
          return a.target.y - b.target.y;
        }
      }

      function center(node) {
        return node.y + node.dy / 2;
      }

      function value(link) {
        return link.value;
      }

      return sankey;
    };
    // end sankey graph implementation

    var units = "Widgets";
    var margin = {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      width = 950 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;
    var formatNumber = d3.format(",.0f"), // zero decimal places
      format = function (d) {
        return formatNumber(d) + " " + units;
      },
      color = d3.scale.category20();
    // append the svg canvas to the page
    d3.select(".ea-sankey-graph")
      .append("div")
      .classed("svg-container", true) //container class to make it responsive
      .append("svg")
      //responsive SVG needs these 2 attributes and no width and height attr
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 " + width + " " + height)
      //class to make it responsive
      .classed("svg-content-responsive", true);
  } // end _initializeSankey

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  /*
   * Function that updates HTML elements and display for module. Called
   * after all data has been retrieved and stabilized.
   */
  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      var formatStr =
        this._dataConfig.type === "president"
          ? "Change of Electoral Votes by Candidate in {year}"
          : "Change of Senate Seats by Party in {year}";

      this._titleModule.setFormat(formatStr);
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });
      this._$element.find(".ea-sankey-title").show();
    } else {
      this._$element.find(".ea-sankey-title").hide();
    }

    this._updateGraph();
    return;
  } // end _updateHtml

  _updateGraph() {
    var units = "Widgets";
    var margin = {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      width = 950 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;
    var formatNumber = d3.format(",.0f"); // zero decimal places
    var format = function (d) {
      return formatNumber(d) + " " + units;
    };
    var color = d3.scale.category20();
    // append the svg canvas to the page
    d3.select(".ea-sankey-graph").selectAll("*").remove();
    var svg = d3
      .select(".ea-sankey-graph")
      .append("div")
      .classed("svg-container", true) //container class to make it responsive
      .append("svg")
      //responsive SVG needs these 2 attributes and no width and height attr
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 " + width + " " + height)
      //class to make it responsive
      .classed("svg-content-responsive", true);
    // Set the sankey diagram properties
    var sankey = d3
      .sankey()
      .nodeWidth(36)
      .nodePadding(40)
      .size([width, height]);
    var path = sankey.link();

    // NOTE: hard code data. Do the daily update here.
    if (
      !("swingScenario" in this._dataConfig) ||
      this._dataConfig.swingScenario === "neutral"
    ) {
      var data = [
        {
          source: "Vulnerable Democrat Seats",
          target: "Democrat Seats After Midterm",
          value: 4,
          result: "FL, IN, MO, MT",
        },
        {
          source: "Vulnerable Democrat Seats",
          target: "Republican Seats After Midterm",
          value: 1,
          result: "ND",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Democrat Seats After Midterm",
          value: 0,
          result: "",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Republican Seats After Midterm",
          value: 2,
          result: "AZ, NV",
        },
      ];
    } else if (this._dataConfig.swingScenario === "rep20") {
      var data = [
        {
          source: "Vulnerable Democrat Seats",
          target: "Democrat Seats After Midterm",
          value: 3,
          result: "FL, IN, MT",
        },
        {
          source: "Vulnerable Democrat Seats",
          target: "Republican Seats After Midterm",
          value: 2,
          result: "MO, ND",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Democrat Seats After Midterm",
          value: 0,
          result: "",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Republican Seats After Midterm",
          value: 2,
          result: "AZ, NV",
        },
      ];
    } else if (this._dataConfig.swingScenario === "dem20") {
      var data = [
        {
          source: "Vulnerable Democrat Seats",
          target: "Democrat Seats After Midterm",
          value: 4,
          result: "FL, IN, MO, MT",
        },
        {
          source: "Vulnerable Democrat Seats",
          target: "Republican Seats After Midterm",
          value: 1,
          result: "ND",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Democrat Seats After Midterm",
          value: 1,
          result: "AZ",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Republican Seats After Midterm",
          value: 1,
          result: "NV",
        },
      ];
    } else {
      var data = [
        {
          source: "Vulnerable Democrat Seats",
          target: "Democrat Seats After Midterm",
          value: 4,
          result: "FL, IN, MO, MT",
        },
        {
          source: "Vulnerable Democrat Seats",
          target: "Republican Seats After Midterm",
          value: 1,
          result: "ND",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Democrat Seats After Midterm",
          value: 0,
          result: "",
        },
        {
          source: "Vulnerable Republican Seats",
          target: "Republican Seats After Midterm",
          value: 2,
          result: "AZ, NV",
        },
      ];
    }

    //set up graph in same style as original example but empty
    var graph = {
      nodes: [],
      links: [],
    };

    data.forEach(function (d) {
      if (d.value == 0) return;
      graph.nodes.push({
        name: d.source,
      });
      graph.nodes.push({
        name: d.target,
      });
      graph.links.push({
        source: d.source,
        target: d.target,
        value: +d.value,
        result: d.result,
      });
    });

    // return only the distinct / unique nodes
    graph.nodes = d3.keys(
      d3
        .nest()
        .key(function (d) {
          return d.name;
        })
        .map(graph.nodes)
    );

    // loop through each link replacing the text with its index from node
    graph.links.forEach(function (d, i) {
      graph.links[i].source = graph.nodes.indexOf(graph.links[i].source);
      graph.links[i].target = graph.nodes.indexOf(graph.links[i].target);
    });

    //now loop through each nodes to make nodes an array of objects
    // rather than an array of strings
    graph.nodes.forEach(function (d, i) {
      graph.nodes[i] = {
        name: d,
      };
    });

    sankey.nodes(graph.nodes).links(graph.links).layout(32);

    // add in the links
    var link = svg
      .append("g")
      .selectAll(".link")
      .data(graph.links)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("id", function (d, i) {
        return "linkLabel" + i;
      })
      .attr("d", path)
      .style("stroke-width", function (d) {
        return Math.max(1, d.dy);
      })
      .sort(function (a, b) {
        return b.dy - a.dy;
      });

    // add in the nodes
    var node = svg
      .append("g")
      .selectAll(".node")
      .data(graph.nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      });

    // add the rectangles for the nodes
    node
      .append("rect")
      .attr("height", function (d) {
        return d.dy;
      })
      .attr("width", sankey.nodeWidth())
      .style("fill", function (d) {
        if (d.name == "Vulnerable Republican Seats") {
          return "#f0000d";
        } else if (d.name == "Republican Seats After Midterm") {
          return "#f0000d";
        } else if (d.name == "Vulnerable Democrat Seats") {
          return "#318ce7";
        } else {
          return "#318ce7";
        }
        return "#318ce7";
      })
      .style("stroke", function (d) {
        return d3.rgb(d.color).darker(2);
      })
      .append("title")
      .text(function (d) {
        return d.name + "\n" + format(d.value);
      });

    // add in the title for the nodes
    node
      .append("text")
      .attr("x", -6)
      .attr("y", function (d) {
        return d.dy / 2;
      })
      .attr("dy", ".35em")
      .attr("text-anchor", "end")
      .attr("transform", null)
      .text(function (d) {
        return d.name + " (" + d.value + ")";
      })
      .filter(function (d) {
        return d.x < width / 2;
      })
      .attr("x", 6 + sankey.nodeWidth())
      .attr("text-anchor", "start");

    /* add labels to graphs */
    var labelText = svg
      .selectAll(".labelText")
      .data(graph.links)
      .enter()
      .append("text")
      .attr("class", "labelText")
      .attr("dx", width / 3)
      .attr("dy", 0)
      .append("textPath")
      .attr("xlink:href", function (d, i) {
        return "#linkLabel" + i;
      })
      .text(function (d, i) {
        return d.result;
      });
  } // end _updateGraph

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Election Overview robot... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The Sankey Graph module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Sankey Graph module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Sankey Graph module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Sankey Graph module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Sankey Graph module.");
    }

    // TODO: notify devs
  } // end error
} // end EAElectionOverview.prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaSankeyGraph = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var sankey = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    sankey.push(new EASankeyGraph($element, myOptions));
  });

  return sankey;
};

// Autoloader
$(function () {
  $(".ea-election-sankey").eaSankeyGraph();
});
