/* Module: Title
 * ==========================================================================
 *
 * The Title module allows for dynamic (updatable) titles.  It does this by
 * filling in values in the provided format string.
 *
 * It can be used independently or within a module.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/title.twig
 *
 * ** dataConfig options
 *   - `type`: The type of race.
 *   - `year`: The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `format`:        [required on init] The title's format string.
 *   - `headingLevel`:  The heading tag to use, 1 is <h1>, 6 is <h6>.
 *                      Defaults to 3.
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`          => `data-type`
 *          `headingLevel`  => `data-heading-level`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *
 *   - `_$element`: The element this jQuery plugin is called on.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";

export class EATitle {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-title");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("format" in options)) {
      this.error("constructor: no format");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. Ideally, these options
    // should only be changed by the module itself, but for backwards
    // compatibility the title module will allow others to change its format
    // through the `setFormat` method.
    this._moduleConfig = {
      format: options.format,
      headingLevel: 3,
    };
    if ("headingLevel" in options) {
      this._moduleConfig.headingLevel = options.headingLevel;
    }

    // Update module's info based on options
    this._updateData().done(
      $.proxy(function () {
        this._updateHtml();
      }, this)
    );
  } // end constructor

  /* Setter functions
   * ------------------------------------------------------ */
  setFormat(newFormat) {
    this._moduleConfig.format = newFormat;
  }

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
      }, this)
    );

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    if (!("type" in this._dataConfig) || !("year" in this._dataConfig)) {
      // No type and year set, so no data can be requested yet
      internalStateUpdated.resolve();
      return internalStateUpdated.promise();
    }

    // Otherwise we need to load data
    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    var hLvl = "h" + this._moduleConfig.headingLevel;
    var title = this._moduleConfig.format;

    var replaceField = $.proxy(function (field, getText) {
      var regex = new RegExp("{" + field + "}", "gi");

      if (regex.test(this._moduleConfig.format)) {
        var text = _.isFunction(getText) ? (text = getText()) : getText;
        if (text) {
          // truthy
          var replacement =
            '<span class="ea-title-field ea-title-' +
            field +
            '">' +
            text +
            "</span>";
          title = title.replace(regex, replacement);
        } else {
          this.error("missing field");
        }
      }
    }, this);

    // Replace values in the title string if they are available
    if ("type" in this._dataConfig) {
      replaceField(
        "type",
        $.proxy(function () {
          return this._dataConfig.type === "president"
            ? "presidential"
            : this._dataConfig.type;
        }, this)
      );
    }
    if ("year" in this._dataConfig) {
      replaceField("year", this._dataConfig.year);
    }
    if ("time" in this._dataConfig) {
      replaceField(
        "time",
        $.proxy(function () {
          return this._dataConfig.time.format("MMM DD, YYYY @ hh:mm:ss A");
        }, this)
      );
    }

    var $heading = $("<" + hLvl + ">" + title + "</" + hLvl + ">");
    this._$element.empty().append($heading);

    return;
  } // end _updateHtml

  /* Error
   * ------------------------------------------------------
   * Error conditions will leave the title empty instead of showing an error
   * message to the user.  This is ok since the users don't have to have a title,
   * whereas they do expect some sort of content from most modules.  Graceful
   * degradation and all.
   */
  error(type) {
    this._$element.empty();

    if (type === "invalid type") {
      console.warn("The Title module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Title module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no format") {
      console.warn(
        "The Title module's constructor requires a `format` option."
      );
    } else if (type === "missing field") {
      console.warn(
        "The Title module cannot fill in the format string as one of the fields has not been provided."
      );
    } else {
      console.warn("An unknown error occurred in the Title module.");
    }

    // TODO: notify devs
  } // end error
}

// jQuery Plugin & Autoloading

$.fn.eaTitle = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var titles = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    titles.push(new EATitle($element, myOptions));
  });

  return titles;
};

// Autoloader
$(function () {
  $(".ea-title").eaTitle();
});
