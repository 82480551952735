import React, { HTMLProps } from "react";
import { css } from "@emotion/core";

export interface TableDataCellProps
  extends HTMLProps<HTMLTableDataCellElement> {
  variant?: "number" | "text";
  leftBordered?: boolean;
  rightBordered?: boolean;
}

function TableDataCell({
  children,
  variant = "number",
  leftBordered = true,
  rightBordered = true,
  ...others
}: TableDataCellProps): JSX.Element {
  return (
    <td
      css={css`
        padding: 5px;
        text-align: center;
        font-family: ${variant === "number"
          ? "ibm-plex-mono, sans-serif"
          : "inherit"};
        border-left: ${leftBordered ? "0.7px" : "0px"} solid #d3d3d3;
        border-right: ${rightBordered ? "0.7px" : "0px"} solid #d3d3d3;
      `}
      {...others}
    >
      {children}
    </td>
  );
}

export default TableDataCell;
