/* Module: Trends
 * ==========================================================================
 *
 * The Trends module lists some basic statistics about an election over time.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/trends.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `useCoalitions`: A boolean flag that indicates if coalitions should be
 *                      used or not. It defaults to true (using coalitions).
 *   - `subsetter`:     Indicates how to choose a subset of races to show.
 *                      Options are `recent` or `overview` (default).
 *   - `usePercentage`: A boolean flag that indicates whether probabilities
 *                      should be displayed as percentage or should be
 *                      displayed as number.
 *                      It defaults to true (display percentage).
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as election from "../services/election";
import * as data from "../services/data";
import * as probs from "../services/probs";
import * as math from "../services/math";
import * as util from "../services/util";

/* Creation
 * ------------------------------------------------------ */
export class EATrends {
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-trends");

    // Validate the initial options
    if (typeof options !== "object") {
      this.error("constructor: options not an object");
      return;
    }
    if (!options.type) {
      this.error("constructor: no type");
      return;
    }
    if (!options.year) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations. By having to explicitly
    // copy over options, invalid or unsupported options are discarded.
    this._dataConfig = {
      type: options.type,
      year: options.year,
      pollScale: 0.3,
      swingScenario: "neutral",
    };
    if ("raceFilter" in options) {
      this._dataConfig.raceFilter = options.raceFilter;
    }
    // NOTE: The trends module does not support a forecast time option or
    // customizations. The latter omission is because of the excessive
    // computational burden that such support would require.

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      useCoalitions: true,
      subsetter: "overview",
      usePercentage: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }
    if ("useCoalitions" in options) {
      this._moduleConfig.useCoalitions = options.useCoalitions;
    }
    if ("subsetter" in options) {
      this._moduleConfig.subsetter = options.subsetter;
    }

    this._updateData().done(() => {
      this._initializeHtml();
      this._updateHtml();
    });
  }

  _initializeHtml() {
    var $title = $('<div class="ea-trends-title"></div>');

    var $displayOptions = $(
      '<form class="form-inline ea-trends-displayOptions">' +
        "<span>Show me:</span>" +
        '<div class="form-group">' +
        '<div class="btn-group" data-toggle="buttons">' +
        '<button class="btn btn-default active">' +
        '<input type="radio" name="subsetter" class="ea-trends-subsetter" value="overview" checked>' +
        "An Overview" +
        "</button>" +
        '<button class="btn btn-default">' +
        '<input type="radio" name="subsetter" class="ea-trends-subsetter" value="recent">' +
        "Recent Forecasts" +
        "</button>" +
        "</div>" +
        "</div>" +
        '<span class="ea-trends-coalitions">with Independents</span>' +
        '<div class="form-group ea-trends-coalitions">' +
        '<div class="btn-group" data-toggle="buttons">' +
        '<button class="btn btn-default">' +
        '<input type="radio" name="coalitionsSelector" class="ea-trends-selector ea-trends-coalitionsSelector" value="true">' +
        "Combined" +
        "</button>" +
        '<button class="btn btn-default">' +
        '<input type="radio" name="coalitionsSelector" class="ea-trends-selector ea-trends-coalitionsSelector" value="false">' +
        "Separated" +
        "</button>" +
        "</div>" +
        "</div>" +
        '<span class="glyphicon glyphicon-question-sign popover-trigger ea-trends-displayOptionExplanation" style="vertical-align: middle"></span>' +
        "</form><br>"
    );

    var $trends = $(
      '<table class="table table-condensed ea-trends-table">' +
        "<thead>" +
        '<tr class="ea-trends-partyLabels">' +
        "<th></th>" +
        '<th colspan="2" class="ea-trends-dem"><span></span></th>' +
        '<th colspan="2" class="ea-trends-demind"><span></span></th>' +
        '<th colspan="2" class="ea-trends-ind"><span></span></th>' +
        '<th colspan="2" class="ea-trends-rep"><span></span></th>' +
        '<th class="ea-trends-tie"></th>' +
        "</tr>" +
        '<tr class="ea-trends-labels">' +
        '<th class="ea-trends-forecastTime"><span>Forecast</span></th>' +
        '<th class="ea-trends-expected ea-trends-dem"><span></span></th>' +
        '<th class="ea-trends-win ea-trends-dem"><span></span></th>' +
        '<th class="ea-trends-expected ea-trends-demind"><span></span></th>' +
        '<th class="ea-trends-win ea-trends-demind"><span></span></th>' +
        '<th class="ea-trends-expected ea-trends-ind"><span></span></th>' +
        '<th class="ea-trends-win ea-trends-ind"><span></span></th>' +
        '<th class="ea-trends-expected ea-trends-rep"><span></span></th>' +
        '<th class="ea-trends-win ea-trends-rep"><span></span></th>' +
        '<th class="ea-trends-tie"><span></span></th>' +
        "</tr>" +
        "</thead>" +
        '<tbody class="ea-trends-data"></tbody>' +
        "</table>"
    );

    var probText = this._moduleConfig.usePercentage ? "chance" : "probability";
    var probNumText = this._moduleConfig.usePercentage ? '"0%"' : '"0.000"';

    this._titleModule = $title.eaTitle({
      format: "Trends for the {year} {type} Election",
    })[0];

    if (this._moduleConfig.useCoalitions) {
      $displayOptions
        .find(".ea-trends-coalitionsSelector[value='true']")
        .prop("checked", true)
        .parent()
        .addClass("active");
    } else {
      $displayOptions
        .find(".ea-trends-coalitionsSelector[value='false']")
        .prop("checked", true)
        .parent()
        .addClass("active");
    }

    var callUpdate = $.proxy(function () {
      this._moduleConfig.useCoalitions =
        $displayOptions.find(".ea-trends-coalitionsSelector:checked").val() ===
        "true";
      this._moduleConfig.subsetter = $displayOptions
        .find(".ea-trends-subsetter:checked")
        .val();
      this._updateHtml();
    }, this);

    $displayOptions.change(callUpdate).prop("disabled", true);

    // ensure popovers can't overlay eachother- show 1 at a time
    var popoverTriggers = $trends.find(
      ".ea-trends-labels .ea-trends-expected > span, " +
        ".ea-trends-labels .ea-trends-win > span, " +
        ".ea-trends-labels .ea-trends-tie > span, " +
        ".ea-trends-partyLabels .ea-trends-demind > span"
    );
    $.merge(
      popoverTriggers,
      $displayOptions.find(".ea-trends-displayOptionExplanation")
    );

    popoverTriggers.on("shown.bs.popover", function () {
      $(this).next().find(".arrow").css("top", "-11px");
    });

    popoverTriggers.click({ popoverTriggers: popoverTriggers }, function () {
      var $popoverTrigger = $(this);
      popoverTriggers.not($popoverTrigger).popover("hide");
      $popoverTrigger.popover("toggle");
    });

    $(document).click(function (e) {
      if (!popoverTriggers.is(e.target)) {
        popoverTriggers.popover("hide");
      }
    });

    var makePopover = function ($elem, options) {
      var defaultPopoverOptions = {
        placement: "bottom",
        trigger: "manual",
        html: true,
      };
      _.defaults(options, defaultPopoverOptions);
      $elem.popover(options);
    };
    makePopover($displayOptions.find(".ea-trends-displayOptionExplanation"), {
      title: "Trends Controls",
      content: $.proxy(function () {
        var message =
          "<p>" +
          "The <em>Overview</em> option will show you forecasts over the duration of the election season.  The <em>Recent</em> option will show you the most recent forecasts." +
          "</p>";
        if (this._dataConfig.type === "senate") {
          message +=
            "<p>" +
            "The <em>Combined</em> option will include each Independent candidate in the party with which he or she is most likely to caucus in the Senate. (Currently all Independents caucus with the Democrats.)  The <em>Separated</em> option will show Independents in their own category." +
            "</p>";
        }
        return message;
      }, this),
    });
    makePopover(
      $trends.find(".ea-trends-partyLabels .ea-trends-demind > span"),
      {
        content: $.proxy(function () {
          if (this._dataConfig.year == 2012) {
            return "The two competitive independent candidates are expected to caucus with the Democrats, so they are included here when determining seat totals.";
          } else if (this._dataConfig.year == 2014) {
            return "The current independent senators caucus with the Democrats, so they are included here when determining seat totals.";
          } else {
            return '<div style="font-variant: normal; text-transform: none;">The independent candidates and senators are expected to caucus with the Democrats, so they are included here when determining seat totals.</div>';
          }
        }, this),
      }
    );
    makePopover($trends.find(".ea-trends-labels .ea-trends-expected > span"), {
      content: $.proxy(function () {
        if (this._dataConfig.type == "president") {
          return '<div style="font-variant: normal; text-transform: none;">The expected number of Electoral Votes for a candidate.  You can read more about the expected value <a href="http://en.wikipedia.org/wiki/Expected_value" target="new">on Wikipedia</a>.</div>';
        } else {
          return '<div style="font-variant: normal; text-transform: none;">The expected number of seats that a party will hold after the election.  You can read more about the expected value <a href="http://en.wikipedia.org/wiki/Expected_value" target="new">on Wikipedia</a>.</div>';
        }
      }, this),
    });
    makePopover($trends.find(".ea-trends-labels .ea-trends-win > span"), {
      content: $.proxy(function () {
        var majority = election.control[this._dataConfig.type].majority;
        if (this._dataConfig.type == "president") {
          return (
            '<div style="font-variant: normal; text-transform: none;">The probability of a candidate winning ' +
            majority +
            " or more electoral votes, and thus winning the election.</div>"
          );
        } else {
          return (
            '<div style="font-variant: normal; text-transform: none;">The probability of a party holding ' +
            majority +
            ' or more seats after the election, and thus securing a <a href="https://en.wikipedia.org/wiki/United_States_Senate#Majority_and_minority_parties" target="new">majority</a>.</div>'
          );
        }
      }, this),
    });
    makePopover($trends.find(".ea-trends-labels .ea-trends-tie > span"), {
      content: $.proxy(function () {
        var half = election.control[this._dataConfig.type].half;
        if (this._dataConfig.type === "president") {
          return (
            '<div style="font-variant: normal; text-transform: none;">The probability of the Democrat and Republican candidates each receiving ' +
            half +
            " electoral votes.</div>"
          );
        } else {
          return (
            '<div style="font-variant: normal; text-transform: none;">The probability of the Democrats & Independents securing exactly ' +
            half +
            " seats and the Republicans securing the remaining " +
            half +
            " seats.</div>"
          );
        }
      }, this),
    });

    this._$element.append($title).append($displayOptions).append($trends);
  } // end _initializeHtml

  /* Updating
   * ------------------------------------------------------ */
  update(newDataConfig) {
    // trends module does not support time or customizations
    this._dataConfig = {
      type: newDataConfig.type,
      year: newDataConfig.year,
      pollScale: newDataConfig.pollScale,
      swingScenario: newDataConfig.swingScenario,
    };
    if ("raceFilter" in newDataConfig) {
      this._dataConfig.raceFilter = newDataConfig.raceFilter;
    }

    this._$element.find(".ea-trends-subsetter").prop("disabled", true);
    this._$element.find(".ea-trends-coalitionsSelector").prop("disabled", true);

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(() => {
      this._updateHtml();

      this._$element.find(".ea-trends-subsetter").prop("disabled", false);
      this._$element
        .find(".ea-trends-coalitionsSelector")
        .prop("disabled", false);
    });

    return updater;
  }

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = (races) => {
      this._races = races;
      // Resolve internal state after update
      internalStateUpdated.resolve();
    };

    // Callback function to execute upon failure
    var failCallback = (errorInfo) => {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    };

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  }

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-trends-title").show();
    } else {
      this._$element.find(".ea-trends-title").hide();
    }

    this._updateTable();
    this._updateCoalitionsControl();
  }

  _updateTable() {
    var shownForecastTimes = this._subsetForecasts();

    var labels = election.getPartyLabels(
      this._dataConfig.type,
      _.values(this._races)[0][0].candidates
    );

    // update labels
    this._$element
      .find(".ea-trends-partyLabels .ea-trends-dem > span")
      .text(labels.Democrat);
    this._$element
      .find(".ea-trends-partyLabels .ea-trends-demind > span")
      .text(labels.DemInd);
    this._$element
      .find(".ea-trends-partyLabels .ea-trends-ind > span")
      .text(labels.Independent);
    this._$element
      .find(".ea-trends-partyLabels .ea-trends-rep > span")
      .text(labels.Republican);

    var expectedLabel = "";
    var pWinLabel = "";
    var pTieLabel = "";
    var half = election.control[this._dataConfig.type].half;
    var majority = election.control[this._dataConfig.type].majority;
    if (this._dataConfig.type === "president") {
      expectedLabel = "Expected Electoral Votes";
      pWinLabel = "Prob. Winning";
      pTieLabel = "Prob. Tie";
    } else {
      expectedLabel = "Expected Seats";
      pWinLabel = "Prob. Majority";
      pTieLabel = "Prob. Tie";
    }
    this._$element
      .find(".ea-trends-labels .ea-trends-expected > span")
      .html(expectedLabel);
    this._$element
      .find(".ea-trends-labels .ea-trends-win > span")
      .html(pWinLabel);
    this._$element
      .find(".ea-trends-labels .ea-trends-tie > span")
      .html(pTieLabel);

    // update trends data
    this._$element.find(".ea-trends-data").empty();
    for (var i = 0; i < shownForecastTimes.length; i++) {
      // Set the dataConfig to the current forecast time for updating
      var fTime = shownForecastTimes[i];
      this._dataConfig.time = fTime;
      var stats = probs.computeStats(this._dataConfig, this._races);
      // Remove property before next pass
      delete this._dataConfig.time;
      var extraText = "";
      if (stats.isFinal) {
        extraText = "<br />(Actual Outcome)";
      }

      // Initialize the current trend row
      var $trendRow = $(
        '<tr class="ea-trends-forecast">' +
          '<td class="ea-trends-forecastTime">' +
          '<span class="ea-trends-forecastTime-date">' +
          fTime.format("MM/DD/YYYY") +
          "</span> " +
          '<span class="ea-trends-forecastTime-time">' +
          fTime.format("hh:mm:ss A") +
          "</span>" +
          extraText +
          "</td>" +
          '<td class="ea-trends-expected ea-trends-dem"></td>' +
          '<td class="ea-trends-win ea-trends-dem"></td>' +
          '<td class="ea-trends-expected ea-trends-demind"></td>' +
          '<td class="ea-trends-win ea-trends-demind"></td>' +
          '<td class="ea-trends-expected ea-trends-ind"></td>' +
          '<td class="ea-trends-win ea-trends-ind"></td>' +
          '<td class="ea-trends-expected ea-trends-rep"></td>' +
          '<td class="ea-trends-win ea-trends-rep"></td>' +
          '<td class="ea-trends-tie"></td>' +
          "</tr>"
      );

      var parties = ["Democrat", "DemInd", "Independent", "Republican"];
      var partyAbbrevs = ["dem", "demind", "ind", "rep"];
      var nParties = parties.length;
      for (var j = 0; j < nParties; ++j) {
        var party = parties[j];
        if (!(party in stats.info)) {
          continue;
        } // Skip party
        var pClass = ".ea-trends-" + partyAbbrevs[j];
        var expected = stats.isFinal
          ? math.sformat(stats.info[party].expected, 0)
          : math.sformat(stats.info[party].expected, 1);
        // Choose format for display probability
        var winNumberText = this._moduleConfig.usePercentage
          ? math.formatProbability(stats.info[party].pWin)
          : math.sformat(stats.info[party].pWin, 3);
        var pWin = stats.info[party].pWin !== null ? winNumberText : "0.0%";
        $trendRow.find(".ea-trends-expected" + pClass).text(expected);
        $trendRow.find(".ea-trends-win" + pClass).text(pWin);
      }
      // For now, this works to compute the probability of a tie. If
      // we consider different coalitions, we'll need to update this.
      // Choose format for display probability
      var pTieNumberText = this._moduleConfig.usePercentage
        ? math.formatProbability(stats.info.Republican.pHalf)
        : math.sformat(stats.info.Republican.pHalf, 3);
      var pTie = stats.info.Republican.pHalf !== null ? pTieNumberText : "0.0%";
      $trendRow.find(".ea-trends-tie").text(pTie);

      // Finally, add the row to the table
      this._$element.find(".ea-trends-data").append($trendRow);
    }

    // Determine which elements should be shown
    if (this._dataConfig.type === "president") {
      // Show and hide columns
      this._$element.find(".ea-trends-dem").show();
      this._$element.find(".ea-trends-demind").hide();
      this._$element.find(".ea-trends-ind").hide();
      this._$element.find(".ea-trends-rep").show();
      this._$element.find(".ea-trends-tie").show();
    } else {
      if (this._moduleConfig.useCoalitions === true) {
        // Show and hide columns
        this._$element.find(".ea-trends-dem").hide();
        this._$element.find(".ea-trends-demind").show();
        this._$element.find(".ea-trends-ind").hide();
        this._$element.find(".ea-trends-rep").show();
        this._$element.find(".ea-trends-tie").show();
      } else {
        // Show and hide columns
        this._$element.find(".ea-trends-dem").show();
        this._$element.find(".ea-trends-demind").hide();
        this._$element.find(".ea-trends-ind").show();
        this._$element.find(".ea-trends-rep").show();
        this._$element.find(".ea-trends-tie").hide();
      }
    }
  }

  _updateCoalitionsControl() {
    var $yesCoalitions = this._$element.find(
      ".ea-trends-coalitionsSelector[value='true']"
    );
    var $noCoalitions = this._$element.find(
      ".ea-trends-coalitionsSelector[value='false']"
    );

    if (this._dataConfig.type === "president") {
      $yesCoalitions.parent().attr("disabled", true);
      $noCoalitions.parent().attr("disabled", true);
      this._$element.find(".ea-trends-coalitions").hide();
    } else {
      $yesCoalitions.parent().removeAttr("disabled");
      $noCoalitions.parent().removeAttr("disabled");
      this._$element.find(".ea-trends-coalitions").show();
    }
  }

  /* ### Subset Forecasts ###
   *
   * Limit the number of Forecasts to show, since calculating the stats
   * (and thus running the DP) for all of them is too slow.  Which
   * Forecasts are selected is dictated by the `subsetter` property.
   * The lists of Forecasts return is provided in descending order (most
   * recent one first).
   *
   * The "overview" strategy displays a representative sample of Forecasts
   * through evenly spaced intervals between the most recent and least
   * recent ones.
   *
   * The "recent" strategy shows only the most recent Forecasts.
   *
   * TODO: Make `maxForecasts` an optional setting, possibly even chosen
   *       by the user.
   */
  _subsetForecasts() {
    var maxForecasts = 20;
    var allForecastTimes = util.getAllForecastTimes(
      this._dataConfig,
      this._races
    );
    var shownForecastTimes = [];

    if (this._moduleConfig.subsetter === "overview") {
      var skip = Math.max(
        1,
        Math.floor(allForecastTimes.length / maxForecasts)
      );
      for (var i = allForecastTimes.length - 1; i >= 0; i -= skip) {
        shownForecastTimes.push(allForecastTimes[i]);
      }
    } else if (this._moduleConfig.subsetter === "recent") {
      var until =
        allForecastTimes.length - maxForecasts > 0
          ? allForecastTimes.length - maxForecasts
          : 0;
      for (var i = allForecastTimes.length - 1; i >= until; i--) {
        shownForecastTimes.push(allForecastTimes[i]);
      }
    } else {
      // somehow the `subsetter` property got messed up
      console.warn(
        "EATrends._subsetForecasts doesn't know of a " +
          this._moduleConfig.subsetter +
          " subsetter option. Defaulting to 'overview' instead."
      );

      this._moduleConfig.subsetter = "overview";
      return this._subsetForecasts();
    }

    return shownForecastTimes;
  }

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Trends follower... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Trends module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Trends module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn("The Trends module's constructor requires a `type` option.");
    } else if (type === "constructor: no year") {
      console.warn("The Trends module's constructor requires a `year` option.");
    } else {
      console.warn("An unknown error occurred in the Trends module.");
    }
  } // end error
}

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaTrends = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var trends = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    trends.push(new EATrends($element, myOptions));
  });

  return trends;
};

// Autoloader
// $(function () {
//   $(".ea-trends").eaTrends();
// });
