import React, { PropsWithChildren } from "react";
import { css } from "@emotion/core";
import Table from "../Table";
import TableHead from "../TableHead";
import TableRow from "../TableRow";
import TableHeaderCell from "../TableHeaderCell";
import TableBody from "../TableBody";
import { Theme } from "../../../theme";

export interface ResultTableProps {
  /**
   * Header to use for republicans
   */
  republican?: string;
  /**
   * Header to use for democrats
   */
  democrat?: string;
}

function ResultTable({
  children,
  republican = "Republican",
  democrat = "Democrat",
}: PropsWithChildren<ResultTableProps>): JSX.Element {
  return (
    <Table>
      <TableHead>
        <TableRow topBordered={false}>
          <TableHeaderCell leftBordered={false}></TableHeaderCell>
          <TableHeaderCell colSpan={2}>
            Actual Percentage of Votes
          </TableHeaderCell>
          <TableHeaderCell rightBordered={false} colSpan={3}>
            Forecast chance of winning
          </TableHeaderCell>
        </TableRow>
        <TableRow>
          <TableHeaderCell leftBordered={false}>State</TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.dem};
                color: white;
              `
            }
          >
            {democrat}
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.rep};
                color: white;
              `
            }
          >
            {republican}
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.dem};
                color: white;
              `
            }
          >
            {democrat}
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.rep};
                color: white;
              `
            }
            rightBordered={false}
          >
            {republican}
          </TableHeaderCell>
          {/* <TableHeaderCell>
            Correct?
          </TableHeaderCell> */}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}

export default ResultTable;
