import React from "react";
import { css } from "@emotion/core";

export interface OverviewProps {
  blueTitle: string;
  redTitle: string;
  redNumber: number;
  blueNumber: number;
  bluePercentage: number;
}

function Overview(props: OverviewProps): JSX.Element {
  const { blueTitle, redTitle, redNumber, blueNumber, bluePercentage } = props;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      `}
    >
      {/* Blue */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
        style={{ flexGrow: bluePercentage }}
      >
        <div
          css={css`
            flex-grow: 1;
            color: #318ce7;
          `}
        >
          {blueTitle}
        </div>
        <div
          css={css`
            margin-right: 8px;
            color: #318ce7;
          `}
        >
          {blueNumber}
        </div>
      </div>
      {/* Separator */}
      <div
        css={css`
          flex-shrink: 0;
          z-index: 100;
          margin-bottom: -30px;
          border-left: 2px dotted #222;
          pointer-events: none;
        `}
      ></div>
      {/* Red */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
        style={{ flexGrow: 1 - bluePercentage }}
      >
        <div
          css={css`
            flex-grow: 1;
            margin-left: 8px;
            color: #f64a41;
          `}
        >
          {redNumber}
        </div>
        <div
          css={css`
            color: #f64a41;
          `}
        >
          {redTitle}
        </div>
      </div>
    </div>
  );
}

export default Overview;
