/* Module: race-overview
 * ==========================================================================
 *
 * The Race Overview module displays race data for a specific race in an
 * election, including current probabilities and poll weights. The race is
 * chosen from a drop-down menu.
 *
 * Usage example available at:
 *      site/dynamic/template/module/race-overview.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `selRaceLocAbbrev`: Race location for selector
 *   - `selRaceIndexInLoc`: Race index for selector
 *   - `usePercentage`: A boolean flag that indicates whether probabilities
 *                      should be displayed as percentage or should be
 *                      displayed as number.
 *                      It defaults to true (display percentage).
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";
import * as math from "../services/math";

export class EARaceOverview {
  /* Object Definition + Constructor
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-race-overview");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      usePercentage: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._updateHtml();
      }, this)
    );
  } // end constructor

  /* Create
   * ------------------------------------------------------
   * Initialize the module's html structure and non-updatable properties.
   */
  _initializeHtml() {
    var $title = $('<h1 class="ea-race-overview-title"></h1>');

    this._titleModule = $title.eaTitle({
      format: "Race Overview for the {year} {type} Election on {time}",
    })[0];

    // Create drop-down selector for choosing races
    var $raceSelect = $('<select class="ea-race-overview-dropdown"></select>');
    var callUpdate = $.proxy(function () {
      var selInfo = $raceSelect.val().split(",");
      // Tag is labeled as "locAbbrev,index"
      this._moduleConfig.selRaceLocAbbrev = selInfo[0];
      this._moduleConfig.selRaceIndexInLoc = selInfo[1];
      $raceSelect.prop("disabled", true);
      this._updateHtml();
    }, this);

    $raceSelect.change(callUpdate).prop("disabled", true);

    var $raceStatus = $("<p id=race-status-field></p>");

    // Create summary table for race
    var $summaryTable = $(
      '<table class="table table-striped ea-race-overview-summary-table">' +
        "<thead>" +
        "<tr>" +
        "<th>Candidate</th>" +
        "<th>Prior</th>" +
        "<th>Undecided<br>Proportion</th>" +
        "<th>Weighted Sum of Prior<br>and Poll Responses</th>" +
        "<th>Probability of<br>Relative Majority</th>" +
        "<th>Probability of<br>Absolute Majority</th>" +
        "</tr>" +
        "</thead>" +
        "<tbody></tbody>" +
        "</table>"
    );

    // Create polls table with header info
    var $pollsTable = $(
      '<table class="table table-striped ea-race-overview-polls-table">' +
        "<thead>" +
        "<tr>" +
        "<th>Poll</th>" +
        "<th>Start<br>Date</th>" +
        "<th>End<br>Date</th>" +
        "<th>Time<br>Added</th>" +
        "<th>Size</th>" +
        "<th>Type</th>" +
        "<th>Responses</th>" +
        "<th>Weight</th>" +
        "</tr>" +
        "</thead>" +
        "<tbody></tbody>" +
        "</table>"
    );

    this._$element
      .append($title)
      .append($raceSelect)
      .append($raceStatus)
      .append($summaryTable)
      .append($pollsTable);
  } // end _initializeHtml

  /* Updating
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    this._$element.find(".ea-race-overview-dropdown").prop("disabled", true);

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
        this._$element
          .find(".ea-race-overview-dropdown")
          .prop("disabled", false);
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsAndPollsPromise = data.getRacesWithForecastsAndPolls(
      this._dataConfig
    );
    racesWithForecastsAndPollsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-race-overview-title").show();
    } else {
      this._$element.find(".ea-race-overview-title").hide();
    }

    // Populate the drop-down element with the races in this election.
    // Any change requires this to be updated, as races may change
    // from active to inactive depending on the forecast time.
    // After updating, attempt to manually set the selected race to
    // what it was before using
    //   `$raceSelector.val("<locAbbrev>,<indexInLoc>")`
    // assuming that the race is still available.
    this._updateRaceSelector();

    // Populate the summary table and polls table with the relevant
    // details from the selected race (and time) (do this on every
    // update)
    this._updateTables();

    this._$element.find(".ea-race-overview-dropdown").prop("disabled", false);
  } // end _updateHtml

  _updateRaceSelector() {
    // Add all of the races to the race selector
    var $raceSelector = this._$element.find(".ea-race-overview-dropdown");
    // Default to previously selected race
    var raceToSelect = $raceSelector.val();
    // If a race is specified in options, make it selected
    if (this._moduleConfig.selRaceLocAbbrev) {
      raceToSelect = this._moduleConfig.selRaceLocAbbrev + ",";
      if (this._moduleConfig.selRaceIndexInLoc) {
        raceToSelect += this._moduleConfig.selRaceIndexInLoc;
      } else {
        raceToSelect += 0;
      }
    }
    var raceToSelectExists = false;
    var fTime = this._dataConfig.time;

    $raceSelector.empty(); //first want to empty the races
    $.each(this._races, function (locAbbrev, racesInLocation) {
      for (var i = 0; i < racesInLocation.length; i++) {
        // labeling by both locAbbrev and index
        var optionLabel = locAbbrev + "," + i;
        var currRace = racesInLocation[i];
        var $option = new Option(currRace.name, optionLabel);
        if (!util.isRaceActive(currRace, fTime)) {
          $option.disabled = true;
        } else if (optionLabel === raceToSelect) {
          // Race is active, so see if it equals the one to select
          raceToSelectExists = true;
        }
        $raceSelector.append($option);
      }
    });

    // Set the selected race in the drop-down element
    if (!raceToSelectExists) {
      raceToSelect = _.keys(this._races)[0] + ",0";
    }
    $raceSelector.val(raceToSelect);
  } // end _updateRaceSelector

  _updateTables() {
    // Get the selected race
    var $raceSelector = this._$element.find(".ea-race-overview-dropdown");
    var selInfo = $raceSelector.val().split(",");
    var race = this._races[selInfo[0]][selInfo[1]];

    var raceIsActive = util.isRaceActive(race, this._dataConfig.time);
    var raceIsEnded = util.isRaceEnded(race, this._dataConfig.time);

    // Update the race status field
    var curTime = this._dataConfig.time.format("MMM DD, YYYY @ hh:mm:ss A");
    var statusText = "<small>(Race ";
    if (!raceIsActive) {
      statusText += "is <strong>inactive</strong> on " + curTime;
    } else if (raceIsEnded) {
      statusText +=
        "<strong>ended</strong> on " +
        race.endTime.format("MMM DD, YYYY @ hh:mm:ss A");
    } else {
      // active
      statusText += "is <strong>ongoing</strong> as of " + curTime;
    }
    statusText += ")</small>";
    this._$element.find("#race-status-field").html(statusText);

    // Build a quick look-up table for the candidates' names
    var cnamesByID = {};
    var responseSumsByID = {};
    $.each(race.candidates, function (cIdx, candidate) {
      cnamesByID[candidate.id] = candidate.lastName;
      responseSumsByID[candidate.id] = 0;
    });

    // Populate the polls table itself
    var $pollsTable = this._$element.find(".ea-race-overview-polls-table");
    var $tbody = $pollsTable.find("tbody");
    $tbody.empty();

    var forecast = util.selectForecastAtTimeX(race, this._dataConfig.time);

    if (race.polls !== null && race.polls.length > 0) {
      for (var i = race.polls.length - 1; i >= 0; --i) {
        var poll = race.polls[i];
        var orgString = _.pluck(poll.organizations, "name").join(" / ");

        var pollWeight = 0.0;
        if (poll.id in forecast.pollWeights) {
          pollWeight = forecast.pollWeights[poll.id];
        }

        // Determine the poll responses
        var candString = "";
        $.each(poll.responses, function (cID, response) {
          candString += "" + cnamesByID[cID] + ": " + response + "%<br>";
          responseSumsByID[cID] += Math.floor(
            poll.size * response * pollWeight * 0.01
          );
        });

        // After using poll weight, set to N/A if not available
        if (
          this._dataConfig.time < poll.timeAdded ||
          forecast.isActualOutcome
        ) {
          pollWeight = "N/A";
        } else {
          pollWeight = math.sformat(pollWeight, 2);
        }

        $tbody.append(
          "<tr>" +
            "<td>" +
            orgString +
            "</td>" +
            "<td>" +
            poll.startDate.format("M/D/YYYY") +
            "</td>" +
            "<td>" +
            poll.endDate.format("M/D/YYYY") +
            "</td>" +
            "<td>" +
            poll.timeAdded.format("M/D/YYYY") +
            "<br>" +
            poll.timeAdded.format("h:mm A") +
            "</td>" +
            "<td>" +
            poll.size +
            "</td>" +
            "<td>" +
            poll.voterType +
            "</td>" +
            "<td>" +
            candString +
            "</td>" +
            "<td>" +
            pollWeight +
            "</td>" +
            "</tr>"
        );
      }
    } else {
      // No polls
      $tbody.append("<tr>" + '<td colspan="7">(No polls)</td>' + "</tr>");
    }

    // Populate the summary table after computing weights for candidates
    var $summaryTable = this._$element.find(".ea-race-overview-summary-table");
    $tbody = $summaryTable.find("tbody");
    $tbody.empty();
    var usePercentage = this._moduleConfig.usePercentage;

    var fTime = this._dataConfig.time;
    $.each(race.candidates, function (cIdx, candidate) {
      var cID = candidate.id;
      if (util.isCandidateActive(candidate, fTime)) {
        // Choose format for display probability
        var relMajProb = 0.0;
        if (cID in forecast.candRelativeMajorityProbs) {
          relMajProb = forecast.candRelativeMajorityProbs[cID];
        }
        var relMajProbText = usePercentage
          ? math.percentFormat(relMajProb, 2)
          : math.sformat(relMajProb, 3);
        // Choose format for display probability
        var absMajProb = 0.0;
        if (cID in forecast.candAbsoluteMajorityProbs) {
          absMajProb = forecast.candAbsoluteMajorityProbs[cID];
        }
        var absMajProbText = usePercentage
          ? math.percentFormat(absMajProb, 2)
          : math.sformat(absMajProb, 3);

        var undProp = 0.0;
        if (cID in forecast.candUndecidedProportions) {
          undProp = forecast.candUndecidedProportions[cID];
        }
        $tbody.append(
          "<tr>" +
            "<td>" +
            candidate.lastName +
            " (" +
            candidate.party.charAt(0) +
            ")</td>" +
            "<td>" +
            math.sformat(candidate.prior, 3) +
            "</td>" +
            "<td>" +
            math.sformat(undProp, 2) +
            "</td>" +
            "<td>" +
            responseSumsByID[cID] +
            "</td>" +
            "<td>" +
            relMajProbText +
            "</td>" +
            "<td>" +
            absMajProbText +
            "</td>" +
            "</tr>"
        );
      } else {
        $tbody.append(
          "<tr>" +
            "<td>" +
            candidate.lastName +
            " (" +
            candidate.party.charAt(0) +
            ")</td>" +
            '<td colspan="5"><small>(inactive on ' +
            curTime +
            ")</small></td>" +
            "</tr>"
        );
      }
    });
  } // end _updateTables

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Race Overview module... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    // log an appropriate warning
    if (type === "invalid type") {
      console.warn("The Race Overview module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Race Overview module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Race Overview module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Race Overview module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Race Overview module.");
    }
  } // end error
} // end EARaceOverview prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaRaceOverview = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var raceOverviews = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    raceOverviews.push(new EARaceOverview($element, myOptions));
  });

  return raceOverviews;
};

// Autoloader
$(function () {
  $(".ea-race-overview").eaRaceOverview();
});
