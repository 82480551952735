/* Component: slider
 * =============================================================================
 *
 * The slider component allows the user to pick a value along a range.
 *
 * ** Component Options
 *    ticks : an array of values to be selectable on the slider
 *
 * ** Instance Variables
 *    _$element : The jquery object this component is called on.
 *
 */
import _ from "underscore";
import * as color from "../services/color";

export class Slider {
  /* Object Definition + Constructor
   * ------------------------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-cpnt-slider");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    ["ticks", "defaultTick"].forEach((option) => {
      if (!(option in options)) {
        this.error(`constructor: no option '${option}'`);
        return;
      }
    });

    const sortedTicks = options.ticks.sort((a, b) => a.slider - b.slider);
    this._initializeHtml(
      sortedTicks,
      options.ticks[options.defaultTick],
      options.gradient
    );
    this._setupSliderMovement(
      sortedTicks,
      options.onSelect,
      options.ticks[options.defaultTick],
      options.progressBar
    );
  } // end constructor

  /* _initializeHtml
   * ---------------------------------------------------------------------- */
  _initializeHtml(ticks, defaultTick, gradient) {
    const $range = $(`
        <input type="range" class="ea-cpnt-slider-range" min=0 max=2000 step=1
               value=${defaultTick.slider}>
      `);
    const $ticks = $(`
        <div class="ea-cpnt-slider-ticks">
          <div class="ea-cpnt-slider-ticks-line-progress"></div
          ><div class="ea-cpnt-slider-ticks-line-progress
                       ea-cpnt-slider-ticks-line-color"></div
          ><div class="ea-cpnt-slider-ticks-line"></div>
        </div>
      `);

    ticks.forEach((tick) => {
      const $tick = $(`
          <div class="ea-cpnt-slider-ticks-tick"></div>
        `);
      const fracPos = tick.slider / 2000;
      $tick.css({ width: `calc((100% - 50px) * ${fracPos} + 25px)` });
      $ticks.append($tick);
    });

    $ticks.append($(`<div class="ea-cpnt-slider-ticks-labels"></div>`));
    ticks.forEach((tick) => {
      const $label = $(`
          <div class="ea-cpnt-slider-ticks-label">${tick.label}</div>
        `);
      const fracPos = (tick.slider / 2000) * 2;
      $label.css({ width: `calc((100% - 50px) * ${fracPos} + 50px)` });
      $label.data("slider", tick.slider);
      $ticks.find(".ea-cpnt-slider-ticks-labels").append($label);
    });

    if (gradient !== undefined) {
      $ticks.find("> :first-child").css({
        "background-image": gradient,
      });
    }

    this._$element.append($range);
    this._$element.append($ticks);
  } // end _initializeHtml

  /* _setupSliderMovement
   * ---------------------------------------------------------------------- */
  _setupSliderMovement(ticks, onSelect, defaultTick, progressBar) {
    // elements used in the callbacks
    const $range = this._$element.find(".ea-cpnt-slider-range");
    const $color = this._$element.find(".ea-cpnt-slider-ticks-line-color");
    const $labels = this._$element.find(".ea-cpnt-slider-ticks-labels");

    // helper functions used in the callbacks
    const getClosest = function (arr, val) {
      return arr.reduce(function (prev, curr) {
        return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
      });
    };
    const sliderInput = function (slider, e) {
      const sliderX = $(slider).offset().left;
      const sliderWidth = $(slider).width();
      const mouseX = e.pageX;

      const inputVal = ((mouseX - sliderX) / sliderWidth) * 2000;
      $(slider).data("nextVal", inputVal);
      $(slider).data("endAfter", false);
      slider.value = $(slider).data("prevVal");
    };
    const highlightLabel = (tick) => {
      $labels.children().each(function () {
        if ($(this).data("slider") == tick.slider)
          $(this).css({ color: color.gray });
        else $(this).css({ color: color.lighterGray });
      });
    };

    // highlight the default label
    highlightLabel(defaultTick);

    // set the color slider to the proper width
    if (progressBar !== false) {
      $color.css({
        width: `calc((100% - 50px) * ${defaultTick.slider / 2000} + 20px)`,
      });
    } else {
      $color.css({
        display: "none",
      });
    }

    // initialize the slider initial state
    $range.data({
      prevVal: 2000,
      nextVal: null,
      endAfter: false,
      slideUpdate: null,
      mouseDown: false,
    });

    // on mouseup, tell the slider to go to the nearest valid slider position
    $("body").on("mouseup", function (e) {
      if ($range.data("mouseDown")) {
        $range.data("mouseDown", false);
        const next = $range.data("nextVal");
        const closest = getClosest(
          ticks.map((tick) => tick.slider),
          next
        );
        $range.data("nextVal", closest);
        $range.data("endAfter", true);
      }
    });

    // on mousedown, tell the slider to animate following the mouse
    $range.on("mousedown", function (e) {
      e.preventDefault();
      $(this).data("mouseDown", true);
      sliderInput(this, e);

      const proxFunc = $.proxy(function () {
        let next =
          $(this).data("nextVal") === null
            ? null
            : Number($(this).data("nextVal"));
        if (next === null) return;
        const prev = Number($(this).data("prevVal"));

        const prevA = (next - prev) / 6;

        if (Math.abs(next - prev) < 1 && $(this).data("endAfter")) {
          clearInterval($(this).data("slideUpdate"));
          $(this).data("slideUpdate", null);
          $(this).data("nextVal", null);
          $(this).data("endAfter", false);
          const selectedTick = ticks.find((tick) => tick.slider == next);
          if (onSelect !== undefined) onSelect(selectedTick.value);
          highlightLabel(selectedTick);
        }
        $(this).data("prevVal", prev + prevA);
        this.value = prev + prevA;
        if (progressBar !== false) {
          $color.css({
            width: `calc((100% - 50px) * ${this.value / 2000} + 20px)`,
          });
        }
      }, this);
      const slideUpdate = $(this).data("slideUpdate");
      if (slideUpdate === null) {
        $(this).data("slideUpdate", setInterval(proxFunc, 9));
      }
    });

    // update the slider state when the value of the slider is changed
    $("body").on("mousemove", function (e) {
      if ($range.data("mouseDown") == true) sliderInput($range, e);
    });
  } // end _setupSliderMovement

  /* disable
   * ---------------------------------------------------------------------- */
  disable() {
    this._$element.addClass("ea-cpnt-slider-disabled");
  } // end disable

  /* enable
   * ---------------------------------------------------------------------- */
  enable() {
    this._$element.removeClass("ea-cpnt-slider-disabled");
  } // end enable

  /* error
   * ---------------------------------------------------------------------- */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(`
        <div class="alert alert-error">
          Something seems to have gone wrong with our slider component.
          We'll look into the issue shortly. Perhaps a refresh would help?
        </div>
      `);
    this._$element.append($errorMsg);

    console.error(`An error occured in a slider component (${type})`);
  } // end error
} // end Slider prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaComponentSlider = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var selectObjs = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    selectObjs.push(new Slider($element, myOptions));
  });

  return selectObjs;
};

// Autoloader
$(function () {
  $(".ea-cpnt-slider").eaComponentSlider();
});
