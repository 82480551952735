import React, { PropsWithChildren } from "react";
import { css } from "@emotion/core";
import Table from "../Table";
import TableHead from "../TableHead";
import TableRow from "../TableRow";
import TableHeaderCell from "../TableHeaderCell";
import TableBody from "../TableBody";
import { Theme } from "../../../theme";

export interface ResultTableWithTurnOutProps {
  republican?: string;
  democrat?: string;
}

function ResultTableWithTurnOut({
  republican = "Republican",
  democrat = "Democrat",
  children,
}: PropsWithChildren<ResultTableWithTurnOutProps>): JSX.Element {
  return (
    <Table>
      <TableHead>
        <TableRow topBordered={false}>
          <TableHeaderCell leftBordered={false}></TableHeaderCell>
          <TableHeaderCell colSpan={2}>
            Actual Percentage of Votes
          </TableHeaderCell>
          <TableHeaderCell rightBordered={false} colSpan={3}>
            Forecast Chance of Winning (Democrat, Republican)
          </TableHeaderCell>
        </TableRow>
        <TableRow>
          <TableHeaderCell leftBordered={false}>State</TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.dem};
                color: white;
              `
            }
          >
            {democrat}
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.rep};
                color: white;
              `
            }
          >
            {republican}
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.demStrong};
                color: white;
              `
            }
          >
            Strong {democrat} Turnout
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.neutral};
                color: black;
              `
            }
          >
            Neutral
          </TableHeaderCell>
          <TableHeaderCell
            css={(theme: Theme) =>
              css`
                background-color: ${theme.colors.parties.repStrong};
                color: white;
              `
            }
            rightBordered={false}
          >
            Strong {republican} Turnout
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}

export default ResultTableWithTurnOut;
