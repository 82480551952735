import $ from "jquery";
import { Cache } from "./cache";
import * as util from "./util";
import * as data from "./data";
import * as api from "./api";

export interface CustomDataConfig {
  time?: any;
  pollFilters?: any;
  swingScenario?: any;
  pollScale?: any;
}

const _cache: Cache = new Cache();

// Global settings
const _bPriorMultiplier: number = 97.0; // 37.0 for 2012, 97.0 for 2014+
const _bUndecidedPrior: number = 3.0;
const _bOtherBase: number = 10.0;

export function createCustomForecasts(
  dataConfig: CustomDataConfig,
  racesByLocation: any
): any {
  // DEBUG:
  //console.log("Creating custom forecasts...");
  //console.log(dataConfig);

  var cacheLabel = util.generateDataCacheLabel("customForecasts", dataConfig);
  var cacheHit = _cache.get(cacheLabel);

  if (cacheHit) {
    return cacheHit;
  }
  // Else prep for customizing forecasts

  // Get default forecast time if none specified
  var fTime =
    "time" in dataConfig
      ? dataConfig.time
      : util.getLatestForecastTime(dataConfig, racesByLocation);
  var pollFilters = "pollFilters" in dataConfig ? dataConfig.pollFilters : [];
  var swingScenario =
    "swingScenario" in dataConfig ? dataConfig.swingScenario : "neutral";
  var pollScale = "pollScale" in dataConfig ? dataConfig.pollScale : 1.0;

  var customRaces: any = {};
  var partialForecasts: any[] = [];
  // Make shallow copies of races to modify forecast for it
  $.each(racesByLocation, function (locAbbrev, locRaces) {
    var customLocRaces = [];
    var nLocRaces = locRaces.length;
    for (var i = 0; i < nLocRaces; ++i) {
      var customRace: any = data.shallowCopyRace(locRaces[i]);

      // TODO: Can skip the shallow copy if we reset forecasts *after*
      // calling createPartialForecast
      customRace.forecasts = []; // Reset forecasts
      if (!util.isRaceEnded(locRaces[i], fTime)) {
        // Include a forecast if the race has not ended
        var partialForecast = _createPartialForecast(
          locRaces[i],
          fTime,
          pollFilters,
          swingScenario,
          pollScale
        );
        customRace.forecasts.push(partialForecast);
        if (
          "requestStatus" in partialForecast &&
          partialForecast.requestStatus === "notSent"
        ) {
          partialForecasts.push(partialForecast);
        }
      }
      customRace.customizations = {
        pollFilters: pollFilters,
        swingScenario: swingScenario,
      };
      customLocRaces.push(customRace);
    }
    customRaces[locAbbrev] = customLocRaces;
  });

  // Check if we need to send partial forecasts to server for processing
  if (partialForecasts.length > 0) {
    var computeProbsPromise = _computeProbsForPartialForecasts(
      partialForecasts
    ).then(function () {
      // Pass custom races through at the end
      return customRaces;
    });
    // Store promise in cache before returning
    _cache.set(cacheLabel, computeProbsPromise);
    return computeProbsPromise;
  } else {
    // Return empty promise with races passed through if no probs to
    // compute here
    return $.Deferred()
      .resolve()
      .promise()
      .then(function () {
        return customRaces;
      });
  }
}

function _createPartialForecast(
  race: any,
  fTime: any,
  pollFilters: any,
  swingScenario: any,
  pollScale: any
) {
  /* [2016-07-04] Removed this optimization
    // Get the forecast for this time for the race as a staring point,
    // and see if we can just use that here
    var forecast = ea.service.util.selectForecastAtTimeX(race, fTime);
    if ((forecast.pollFilter === pollFilter) &&
    (forecast.swingScenario === swingScenario)) {
    return forecast;
    }
    // Else we need to do a bit of work to customize the forecast
    */

  // Initialize auxiliary data structures for candidates and polls
  var auxData = _initializeAuxData(race.candidates, race.polls, fTime);
  var auxCandidates = auxData.auxCandidates;
  var auxPolls = auxData.auxPolls;

  // Filter polls based on date and organization
  _filterPolls(auxPolls, fTime, pollFilters);

  // Weight the polls and determine the poll classes
  var pollClasses = _weightPolls(auxPolls);

  // Sum the weighted polling data for each candidate
  var bUnd = _sumPolls(auxCandidates, auxPolls, pollClasses, pollScale);

  // Identify the competitive candidates
  var bOther = _determineCompetitiveCandidates(
    auxCandidates,
    bUnd,
    swingScenario
  );

  // Combine all necessary data into a partial forecast which can be
  // updated later by having the web server crunch numbers
  var partialForecast = _combineData(
    auxCandidates,
    auxPolls,
    pollClasses,
    fTime,
    pollFilters,
    swingScenario,
    bOther,
    bUnd
  );
  // Fill in some additional info for the partial forecast
  partialForecast.id = -1; // Dummy id
  partialForecast.raceID = race.id;
  // [2016-11-09] Added to apply numeric instability patches
  partialForecast.raceName = race.name;
  partialForecast.candidates = race.candidates;

  return partialForecast;
}

function _initializeAuxData(candidates: any, polls: any[], fTime: any) {
  // Construct aux structure for polls
  var auxPolls = [];
  var nPolls = polls.length;
  for (var i = 0; i < nPolls; ++i) {
    var poll = polls[i];
    var auxPoll = { pref: poll }; //, responses: {} };
    // TODO: Here we would copy poll responses to allow for generic
    // responses to be mapped to candidates as needed, but that happens
    // ahead of time here (web components don't see any generic poll
    // responses; that is all handled before data even gets into the DB)
    //$.each(poll.responses, function(cID, response) {
    //  auxPoll.responses[cID] = response;
    //});
    auxPolls.push(auxPoll);
  }

  // Ordinarily here, we would deal with generic responses in the
  // polling data, but that is taken care of before values are even
  // put into the database

  // Construct aux structure for candidates
  var auxCandidates = [];
  var priorSum = 0.0;
  var nCandidates = candidates.length;
  for (var i = 0; i < nCandidates; ++i) {
    var candidate = candidates[i];
    var prior = candidate.prior;
    priorSum += prior;
    var bPrior = prior * _bPriorMultiplier;
    if (bPrior < 1.0) {
      bPrior = 1.0;
    }

    var auxCand = {
      cref: candidate,
      active: util.isCandidateActive(candidate, fTime),
      bPrior: bPrior,
      bPolls: 0.0,
    };
    auxCandidates.push(auxCand);
  }

  return { auxCandidates: auxCandidates, auxPolls: auxPolls };
}

function _filterPolls(auxPolls: any, fTime: any, pollFilters: any) {
  // Compare the forecast time with each poll's time added field in
  // order to filter polls into available and unavailable categories.
  // If no forecast time is provided, all polls are ignored by default.
  // Also filter poll availability based on specified filters.

  // Create list of excluded orgs due to poll filters
  var excludedOrgs = util.createExcludedOrgsFromPollFilters(pollFilters);
  var nExcluded = excludedOrgs.length;
  //console.log(nExcluded);

  var nPolls = auxPolls.length;
  for (var i = 0; i < nPolls; ++i) {
    var auxPoll = auxPolls[i];
    if (fTime === undefined || auxPoll.pref.timeAdded > fTime) {
      auxPoll.available = false;
    } else if (nExcluded === 0) {
      auxPoll.available = true;
    } else {
      // Some orgs are exluded; use to determine availability
      // Default availability to true unless sponsored by an excluded org
      auxPoll.available = true;
      var nOrgs = auxPoll.pref.organizations.length;
      for (var j = 0; j < nOrgs; ++j) {
        var orgJ = auxPoll.pref.organizations[j].name;
        for (var k = 0; k < nExcluded; ++k) {
          if (orgJ === excludedOrgs[k]) {
            auxPoll.available = false;
          }
        }
      }
      // Don't worry about short-circuiting loops yet; probably won't
      // be a performance issue here
    }
  }
  return;
}

function _weightPolls(auxPolls: any): any {
  // Identify the polling classes for weighting
  var pollClasses: any = {};
  var classTimes = [];
  var nPolls = auxPolls.length;
  for (var i = 0; i < nPolls; ++i) {
    if (!auxPolls[i].available) {
      continue;
    }
    var endDate = auxPolls[i].pref.endDate;
    var endDateF = endDate.format();
    if (endDateF in pollClasses) {
      pollClasses[endDateF].count += 1;
    } else {
      classTimes.push(endDate);
      pollClasses[endDateF] = { count: 1, weight: 0.0 };
    }
  }
  classTimes.sort(function (a, b) {
    if (a === b) {
      return 0;
    }
    return b < a ? -1 : 1;
  });
  // Stop if we have no poll classes
  var nClasses = classTimes.length;
  if (nClasses < 1) {
    return pollClasses;
  }

  // Now assign weights to the classes
  var lastDate = classTimes[0]; // This date is used for weighting
  pollClasses[lastDate.format()].weight = 1.0;
  var numPolls = pollClasses[lastDate.format()].count;

  for (var i = 1; i < nClasses; ++i) {
    var nextDate = classTimes[i];
    var daysSince = lastDate.diff(nextDate, "days");
    var weight = 0.0;
    if (daysSince < 7) {
      weight = 1.0;
    } else if (daysSince < 14 || numPolls < 3) {
      weight = 0.5;
    } // else weight = 0.0
    if (weight <= 0.0) {
      break;
    }
    pollClasses[nextDate.format()].weight = weight;
    numPolls += pollClasses[nextDate.format()].count;
  }

  return pollClasses;
}

function _sumPolls(
  auxCandidates: any,
  auxPolls: any,
  pollClasses: any,
  pollScale: any
): any {
  var bUndecidedPolls = 0.0;

  var nPolls = auxPolls.length;
  for (var i = 0; i < nPolls; ++i) {
    var auxPoll = auxPolls[i];
    if (!auxPoll.available) {
      continue;
    } // Skip unavailable polls
    var endDate = auxPoll.pref.endDate;
    var weight = pollClasses[endDate.format()].weight;
    if (weight <= 0.0) {
      continue;
    } // Skip polls with 0 weight
    var size = auxPoll.pref.size;
    var responseSum = 0.0;
    var nCands = auxCandidates.length;
    for (var j = 0; j < nCands; ++j) {
      var auxCand = auxCandidates[j];
      if (!auxCand.active) {
        continue;
      } // Skip inactive candidates
      if (
        !(auxCand.cref.id in auxPoll.pref.responses) &&
        !(auxCand.cref.lastName in auxPoll.pref.responses)
      ) {
        // Skip candidate if neither id nor last name in poll responses
        continue;
      }
      // response is a percentage between 0 and 100
      var response =
        auxCand.cref.lastName in auxPoll.pref.responses
          ? auxPoll.pref.responses[auxCand.cref.lastName]
          : auxPoll.pref.responses[auxCand.cref.id];
      var votesForCand = Math.floor(size * 0.01 * response);
      responseSum += response;
      auxCand.bPolls += weight * votesForCand * pollScale;
    }
    // If we have a poll response for a candidate that is not listed in
    // the race, those responses contribute to the undecided votes.
    // Technically they should contribute to "other", but this is what
    // has been done in the past, so we're sticking with it.
    var undVotes = Math.floor(size * 0.01 * (100.0 - responseSum));
    if (undVotes < 0) {
      console.warn("ERROR: Negative number of undecideds");
      undVotes = 0.0;
    }
    bUndecidedPolls += weight * undVotes * pollScale;
  }

  // Determine the bUnd parameter value
  var bUndecided = _bUndecidedPrior + bUndecidedPolls;
  return bUndecided;
}

function _determineCompetitiveCandidates(
  auxCandidates: any,
  bUndecided: any,
  swingScenario: any
) {
  // Set the total bVal for each candidate based on polling data and
  // then sort the candidates based on that
  var bTotal = bUndecided;
  var activeCands = [];
  var activeCandsWithPolls = [];
  var nCands = auxCandidates.length;
  for (var i = 0; i < nCands; ++i) {
    var auxCand = auxCandidates[i];
    auxCand.bVal = auxCand.bPrior + auxCand.bPolls;
    if (auxCand.active) {
      bTotal += auxCand.bVal;
      activeCands.push(auxCand);
      if (auxCand.bPolls > 0.0) {
        activeCandsWithPolls.push(auxCand);
      }
    }
  }
  activeCands.sort(function (a, b) {
    if (b.bVal === a.bVal) {
      return 0;
    }
    return b.bVal < a.bVal ? -1 : 1;
  });

  // Compute the "competitive" threshold
  var bMax = activeCands[0].bVal;
  var threshold = bMax / 5.0;

  // Determine which candidates are competitive and which ones should
  // contribute to bOther as non-competitive candidates. Right now we
  // take the first two (active) candidates and potentially the third
  // depending on its bVal with respect to the first candidate.
  // NOTE: This is somewhat arbitrary, but it takes too long to
  // integrate over all of the candidates otherwise.
  var bOther = _bOtherBase;
  var nCompetitiveCands = 0;
  var nActiveCands = activeCands.length;
  for (var i = 0; i < nActiveCands; ++i) {
    var auxCand = activeCands[i];
    auxCand.wVal = 0.0;
    var bVal = auxCand.bVal;
    if (nCompetitiveCands < 2 || (nCompetitiveCands < 3 && bVal >= threshold)) {
      auxCand.isCompetitive = true;
      nCompetitiveCands += 1;
    } else {
      auxCand.isCompetitive = false;
      bOther += bVal;
    }
  }

  // Determine the undecided proportions.
  // - Compute how much extra is allocated to a particular party based
  //   on the swing scenario.
  // - Allocate 4% of the remainder to undecideds.
  // - If we have no polling data, then the remaining proportion of
  //   undecideds is split evenly among all active candidates.
  //   Otherwise the remainder is split evenly among all active
  //   candidates with some polling data.
  // Scenarios look like dem5, rep10, etc...
  var swingVal = 0.0;
  if (swingScenario.match(/^dem/) || swingScenario.match(/^rep/)) {
    swingVal = swingScenario.substring(3) / 100.0;
  }
  // Allocate 4% to undecideds by default, so 96% of total goes to cands
  var wCandSum = 0.96 * (1.0 - swingVal);

  // By default, split wCandSum evenly across all active candidates,
  // unless there is polling data available, in which case split across
  // candidates with polls
  var wValPerCand = wCandSum / nActiveCands;
  var candsToUpdate = activeCands;
  if (activeCandsWithPolls.length > 0) {
    wValPerCand = wCandSum / activeCandsWithPolls.length;
    candsToUpdate = activeCandsWithPolls;
  }
  var swingCands = [];
  var nCandsToUpdate = candsToUpdate.length;
  for (var i = 0; i < nCandsToUpdate; ++i) {
    candsToUpdate[i].wVal = wValPerCand;
    if (_candidateMeetsSwingCriterion(candsToUpdate[i].cref, swingScenario)) {
      swingCands.push(candsToUpdate[i]);
    }
  }
  // Finally, update candidates' wVals for swing scenarios
  var nSwingCands = swingCands.length;
  if (nSwingCands > 0) {
    var swingValPerCand = swingVal / nSwingCands;
    for (var i = 0; i < nSwingCands; ++i) {
      swingCands[i].wVal += swingValPerCand;
    }
  } // else any swing val is essentially re-allocated to the undecideds

  return bOther;
}

function _candidateMeetsSwingCriterion(
  candidate: any,
  swingScenario: any
): any {
  var party = candidate.party;
  return (
    (swingScenario.match(/^dem/) && party === "Democrat") ||
    (swingScenario.match(/^rep/) && party === "Republican")
  );
}

function _combineData(
  auxCandidates: any,
  auxPolls: any,
  pollClasses: any,
  fTime: any,
  pollFilters: any,
  swingScenario: any,
  bOther: any,
  bUnd: any
): any {
  var partialForecast: any = {
    forecastTime: fTime,
    pollFilters: pollFilters,
    swingScenario: swingScenario,
    candRelativeMajorityProbs: {},
    candAbsoluteMajorityProbs: {},
    candCompetitiveFlags: {},
    candUndecidedProportions: {},
    dateWeights: {},
    pollWeights: {},
    creator: { id: 2, name: "Customer" },
    candBVals: {},
    candWVals: {}, // duplicates undecided props
    bOther: bOther,
    bUnd: bUnd,
    requestStatus: "notSent",
  };
  // Set the candidate-specific data
  var nCands = auxCandidates.length;
  for (var i = 0; i < nCands; ++i) {
    var auxCand = auxCandidates[i];
    if (!auxCand.active) {
      continue;
    } // Skip inactive candidates
    var cID = auxCand.cref.id;
    partialForecast.candCompetitiveFlags[cID] = auxCand.isCompetitive ? 1 : 0;
    partialForecast.candBVals[cID] = auxCand.bVal;
    partialForecast.candWVals[cID] = auxCand.wVal;
    partialForecast.candUndecidedProportions[cID] = auxCand.wVal;
  }
  // Set the poll-specific data
  var nPolls = auxPolls.length;
  for (var i = 0; i < nPolls; ++i) {
    var auxPoll = auxPolls[i];
    if (!auxPoll.available) {
      continue;
    } // Skip unavailable polls
    var endDate = auxPoll.pref.endDate;
    var weight = pollClasses[endDate.format()].weight;
    if (weight <= 0.0) {
      continue;
    } // Skip polls with 0 weight
    // Store weight of poll and weight of date (if not yet stored)
    partialForecast.pollWeights[auxPoll.pref.id] = weight;
    partialForecast.dateWeights[endDate.format("YYYY-MM-DD")] = weight;
  }
  return partialForecast;
}

function _computeProbsForForecasts(param: any): any {
  return param;
}

function _computeProbsForPartialForecast(partialForecast: any) {
  return _computeProbsForForecasts([partialForecast]);
}

function _computeProbsForPartialForecasts(partialForecasts: any): any {
  var clArgs = [];
  var nPartialForecasts = partialForecasts.length;
  for (var i = 0; i < nPartialForecasts; ++i) {
    var partialForecast = partialForecasts[i];
    var bArgs: any[] = [];
    var wArgs: any[] = [];
    var nCands = 0;
    $.each(partialForecast.candCompetitiveFlags, function (cID, compFlag) {
      if (compFlag === 1) {
        ++nCands;
        bArgs.push(Math.round(100 * partialForecast.candBVals[cID]) / 100);
        wArgs.push(Math.round(100 * partialForecast.candWVals[cID]) / 100);
      }
    });
    clArgs.push(i, nCands);
    clArgs.push.apply(clArgs, bArgs);
    clArgs.push(
      Math.round(100 * partialForecast.bOther) / 100,
      Math.round(100 * partialForecast.bUnd) / 100
    );
    clArgs.push.apply(clArgs, wArgs);
    // Update the request status
    partialForecast.requestStatus = "inProgress";
  }

  // Create data to send to the number-cruncher
  var requestData = {
    commandLineArgs: clArgs.join(" "),
  };
  // DEBUG:
  //console.log("Args are:");
  //console.log(requestData.commandLineArgs);

  // Create a post-processing function that updates the forecast
  // given the returned numbers
  var probsPostProcessor = (probs: any) => {
    // DEBUG:
    //console.log("Received probs back from server!");
    //console.log(probs);
    for (var i = 0; i < nPartialForecasts; ++i) {
      var partialForecast = partialForecasts[i];
      var relMajProbs = probs.results[i].relMajorityProbs;
      var absMajProbs = probs.results[i].absMajorityProbs;
      var j = 0;
      var relMajProbSum = 0.0;
      var absMajProbSum = 0.0;

      // A bit of an overzealous warning, as the compErrors flag gets
      // set for very minor roundoff issues in probs
      //if (probs.results[i].compErrors === 1) {
      //  console.warn("WARN: Potential numeric instability for raceID "
      //            + partialForecast.raceID);
      //}

      $.each(partialForecast.candCompetitiveFlags, function (cID, compFlag) {
        // Inactive cands not listed, so no need to exclude here
        var candRelMajProb = 0.0;
        var candAbsMajProb = 0.0;
        if (compFlag === 1) {
          candRelMajProb = relMajProbs[j];
          candAbsMajProb = absMajProbs[j];
          ++j;
        }
        // Round invalid probabilities towards 0 and 1
        if (candRelMajProb < -0.00025 || 1.00025 < candRelMajProb) {
          console.warn(
            "ERROR: Instability in rel maj probability: " + candRelMajProb
          );
        } else if (candRelMajProb < 0) {
          candRelMajProb = 0.0;
        } else if (candRelMajProb > 1) {
          candRelMajProb = 1.0;
        }
        if (candAbsMajProb < -0.00025 || 1.00025 < candAbsMajProb) {
          console.warn(
            "ERROR: Instability in abs maj probability: " + candAbsMajProb
          );
        } else if (candAbsMajProb < 0) {
          candAbsMajProb = 0.0;
        } else if (candAbsMajProb > 1) {
          candAbsMajProb = 1.0;
        }
        // Store probs in forecast
        partialForecast.candRelativeMajorityProbs[cID] = candRelMajProb;
        partialForecast.candAbsoluteMajorityProbs[cID] = candAbsMajProb;
        relMajProbSum += candRelMajProb;
        absMajProbSum += candAbsMajProb;
      }); // end $.each candidate

      // Normalize the probabilities, allowing for slight round-off
      if (
        (0.95 < relMajProbSum && relMajProbSum < 0.99999) ||
        (1.00001 < relMajProbSum && relMajProbSum < 1.05)
      ) {
        $.each(partialForecast.candCompetitiveFlags, function (cID, compFlag) {
          partialForecast.candRelativeMajorityProbs[cID] /= relMajProbSum;
        });
      } else if (relMajProbSum <= 0.95 || 1.05 <= relMajProbSum) {
        // We have bigger problems in the numeric instability...
        console.warn(
          "ERROR: Significant instability in probabilities " +
            "for [" +
            partialForecast.raceName +
            "]" +
            ": relMajProbSum = " +
            relMajProbSum
        );
        // First pass to catch races we need to hard-code
        if (
          partialForecast.raceName === "FL: Clinton vs. Trump" &&
          partialForecast.swingScenario === "rep5"
        ) {
          console.log("PATCH: Invoking hard-coded fix for FL: C vs T, rep5");
          var nCands = partialForecast.candidates.length;
          for (var candInd = 0; candInd < nCands; ++candInd) {
            var cand = partialForecast.candidates[candInd];
            var cID = cand.id;
            if (cand.lastName === "Clinton") {
              partialForecast.candRelativeMajorityProbs[cID] = 0.15939;
            } else if (cand.lastName === "Trump") {
              partialForecast.candRelativeMajorityProbs[cID] = 0.84061;
            }
          }
        } else if (
          partialForecast.raceName === "FL: Clinton vs. Trump" &&
          partialForecast.swingScenario === "dem5"
        ) {
          console.log("PATCH: Invoking hard-coded fix for FL: C vs T, dem5");
          var nCands = partialForecast.candidates.length;
          for (var candInd = 0; candInd < nCands; ++candInd) {
            var cand = partialForecast.candidates[candInd];
            var cID = cand.id;
            if (cand.lastName === "Clinton") {
              partialForecast.candRelativeMajorityProbs[cID] = 0.43641;
            } else if (cand.lastName === "Trump") {
              partialForecast.candRelativeMajorityProbs[cID] = 0.56539;
            }
          }
        } else {
          // Try to "patch" our computation in a generic fashion
          var numCorrected = 0;
          //console.log(partialForecast); // DEBUG:
          $.each(partialForecast.candCompetitiveFlags, function (
            cID,
            compFlag
          ) {
            if (
              compFlag == 1 &&
              partialForecast.candRelativeMajorityProbs[cID] < 0.01
            ) {
              if (numCorrected == 0) {
                // Try to fix this candidate's probability
                console.log(
                  "PATCH:  Setting relMajProb for candID " +
                    cID.toString() +
                    " to " +
                    (1.0 - relMajProbSum)
                );
                partialForecast.candRelativeMajorityProbs[cID] =
                  1.0 - relMajProbSum;
                numCorrected = 1;
              } else {
                console.warn(
                  "ERROR: Multiple comp. cands for raceID: " +
                    partialForecast.raceID +
                    "; unsure how to " +
                    "correct probs for all"
                );
              }
            } else if (
              compFlag == 1 &&
              partialForecast.candRelativeMajorityProbs[cID] > 0.01
            ) {
              console.log(
                "PATCH: Assuming relMajProb for candID " +
                  cID.toString() +
                  " of " +
                  partialForecast.candRelativeMajorityProbs[cID] +
                  " is approximately correct."
              );
            }
          });
        }
      }
      // Only scale half probs if sum exceeds 1.0 (may not sum to 1)
      if (1.0 < absMajProbSum) {
        $.each(partialForecast.candCompetitiveFlags, function (cID, compFlag) {
          partialForecast.candAbsoluteMajorityProbs[cID] /= absMajProbSum;
        });
      }
      // Finally, update the status
      partialForecast.requestStatus = "complete";
    }
  };

  var probsData = api.request("Probs", "compute", requestData).then(
    (data: any, textStatus: any, jqXHR: any) => {
      // Pass through the post-processed data, removing rest
      return probsPostProcessor(JSON.parse(data));
    },
    (jqXHR: any, textStatus: any, errorThrown: any) => {
      console.warn("API request failed: " + textStatus);
      // Pass through the jqXHR's status and responseText values
      return {
        statusCode: jqXHR.status,
        statusText: jqXHR.responseText,
      };
    }
  );
  // Rather than returning the jqXHR object itself, we return an
  // associated Promise object, which filters out some unnecessary
  // details of the jqXHR object while still allowing users to access
  // the returned data and attach additional callback functions
  return probsData.promise();
}
