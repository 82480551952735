import React from "react";
import { css } from "@emotion/core";
import TableRow from "../TableRow";
import TableDataCell from "../TableDataCell";
import { Theme } from "../../../theme";

export interface ResultTableRowProps {
  state: string | JSX.Element;
  actualRepublican: number;
  actualDemocrat: number;
  forecastRepublican: number;
  forecastDemocrat: number;
}

function ResultTableRow({
  state,
  actualDemocrat,
  actualRepublican,
  forecastDemocrat,
  forecastRepublican,
}: ResultTableRowProps): JSX.Element {
  return (
    <TableRow>
      <TableDataCell leftBordered={false} variant="text">
        {state}
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.demStrong};
        `}
        style={{
          fontWeight: actualDemocrat > actualRepublican ? "bold" : "initial",
        }}
      >
        {actualDemocrat}%
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.repStrong};
        `}
        style={{
          fontWeight: actualRepublican > actualDemocrat ? "bold" : "initial",
        }}
      >
        {actualRepublican}%
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.demStrong};
        `}
        style={{
          fontWeight:
            forecastDemocrat > forecastRepublican ? "bold" : "initial",
        }}
      >
        {forecastDemocrat}%
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.repStrong};
        `}
        style={{
          fontWeight:
            forecastRepublican > forecastDemocrat ? "bold" : "initial",
        }}
        rightBordered={false}
      >
        {forecastRepublican}%
      </TableDataCell>
      {/* <TableDataCell>
        {correct}
      </TableDataCell> */}
    </TableRow>
  );
}

export default ResultTableRow;
