import $ from "jquery";
import _ from "underscore";
import { Cache } from "./cache";
import {
  Race,
  RacesByLocation,
  RacesBySubtype,
  Candidate,
  Poll,
  DataConfig,
  DataConfigCustomizations,
} from "./common";

/* Util Service
 * ==========================================================================
 * Modeled after election-data/lib/perl/ElectionUtil.pm
 *
 * TODO/NOTE: [20160317] To have the election-data number-crunching reuse
 * the functionality here, we can provide a way to convert to Perl data
 * into a format that would be recognizable on the web front end. Problem
 * right now is that Perl sees a poll as
 *   { responses: { 'Obama': 50.2, 'Romney': 49.2 }
 *     ... }
 * while Javascript side of things sees data as it comes out of the DB
 * with IDs instead of candidate names, like:
 *   { responses: { 5: 50.2, 6: 49.2 }
 *     ... }
 */

const cache: Cache = new Cache();

export function groupRacesByLocation(races: Race[]): RacesByLocation {
  // Functionality also implemented in data.ts after processing
  // responses from DB
  let racesByLocation: RacesByLocation = {};
  let nRaces = races.length;

  for (let i = 0; i < nRaces; ++i) {
    let race = races[i];
    let locAbbrev = race.location.abbrev;

    if (!(locAbbrev in racesByLocation)) {
      racesByLocation[locAbbrev] = [];
    }

    racesByLocation[locAbbrev].push(race);
  }

  return racesByLocation;
}

export function groupRacesBySubtype(races: Race[]): RacesBySubtype {
  let racesBySubtype: RacesBySubtype = {
    primary: [],
    primaryRunoff: [],
    general: [],
    generalRunoff: [],
  };

  // First compute the results from any primary races, if present
  let nRaces = races.length;

  for (let i = 0; i < nRaces; ++i) {
    let subtype = races[i].subtype;

    if (subtype === "primary" || subtype === "special-primary") {
      racesBySubtype.primary.push(races[i] as never);
    } else if (
      subtype === "primary-runoff" ||
      subtype === "special-primary-runoff"
    ) {
      racesBySubtype.primaryRunoff.push(races[i] as never);
    } else if (subtype === "general" || subtype === "special") {
      racesBySubtype.general.push(races[i] as never);
    } else {
      // subtype === 'general-runoff' || 'special-runoff'
      racesBySubtype.generalRunoff.push(races[i] as never);
    }
  }

  return racesBySubtype;
}

function _isGenericResponseLabel(label: string): boolean {
  return label === "Democrat" || label === "Republican";
}

export function pollsUseGenericResponseLabel(polls: Poll[]): boolean {
  // TODO: This function won't work as expected because we have already
  // mapped generic responses to candidates before even storing anything
  // in the database. So we shouldn't need to worry about this part...
  let nPolls = polls.length;

  for (let i = 0; i < nPolls; ++i) {
    let pollUsesGenericResponse = false;
    $.each(polls[i].responses, function (cID, response): boolean | undefined {
      if (_isGenericResponseLabel(cID)) {
        pollUsesGenericResponse = true;
        return false; // break $.each loop
      }
    });
    // Early termination if current poll uses generic response
    if (pollUsesGenericResponse) {
      return true;
    }
  }
  return false;
}

export function isCandidateActive(cand: Candidate, fTime?: number): boolean {
  if (fTime) {
    if (
      (cand.timeEntered && fTime < cand.timeEntered) ||
      (cand.timeWithdrew && cand.timeWithdrew <= fTime)
    ) {
      return false;
    } // else
    return true;
  } // else
  return !cand.timeEntered ? true : false;
}

export function isRaceActive(race: Race, fTime?: number): boolean {
  if (fTime) {
    if (
      (race.timeAdded && fTime < race.timeAdded) ||
      (race.timeRemoved && race.timeRemoved <= fTime)
    ) {
      return false;
    }

    return true;
  }

  return !race.timeAdded ? true : false;
}

export function isRaceEnded(race: Race, fTime: any): boolean {
  return race.endTime && race.endTime <= fTime;
}

export function removeInactiveRaces(
  dataConfig: DataConfig & Pick<DataConfigCustomizations, "time">,
  racesByLocation: any
) {
  if (dataConfig.type === "president" && dataConfig.year == 2016) {
    var activeRacesByLocation: any = {};
    var fTime = dataConfig.time;
    $.each(racesByLocation, function (locAbbrev, locRaces) {
      var inactiveRaceCount = 0;
      var n = locRaces.length;
      for (var i = 0; i < n; i++) {
        var race = locRaces[i];
        if (!isRaceActive(race, fTime)) {
          inactiveRaceCount++;
        }
      }
      if (inactiveRaceCount < n) activeRacesByLocation[locAbbrev] = locRaces;
    });
    return activeRacesByLocation;
  }
  return racesByLocation;
}

/**
 * Constructs a dataConfig object from a set of options given to a
 * module constructor.
 *
 * Options that are copied over directly:
 *
 *  - `type`: [required for most modules] The type of race.
 *  - `year`: [required for most modules] The year of the election cycle.
 *  - `swingScenario`:   The swing scenario. Defaults to "neutral".
 *
 * Options that are processed in some way (e.g., for formatting):
 *
 *  - `time`:     The time used to choose a Forecast, specified as
 *                "YYYY-MM-DD HH:mm:ss". If not set, the most recent
 *                Forecast time will be used.
 *  - `timezone`: The timezone for the forecast time. Defaults to "GMT".
 *  - `pollFilter`: The initial filter to apply to polls (NOTE: does not
 *                  support multiple filters on initialization). Defaults
 *                  to "none" (so `dataConfig.pollFilters` starts as an
 *                  empty array).
 *
 */
export function createDataConfigFromOptions(
  options: any
): Partial<DataConfig & DataConfigCustomizations> {
  // By having to explicitly copy over options when generating the
  // dataConfig object, invalid or unsupported options are discarded.
  var dataConfig: Partial<DataConfig & DataConfigCustomizations> = {};

  // Most modules require these options
  if ("type" in options) {
    dataConfig.type = options.type;
  }
  if ("year" in options) {
    dataConfig.year = options.year;
  }

  // Convert time from "YYYY-MM-DD HH:mm:ss" string to moment.tz
  if ("time" in options) {
    if ("timezone" in options) {
      dataConfig.time = moment.tz(options.time, options.timezone);
    } else {
      dataConfig.time = moment.tz(options.time, "GMT");
    }
  }

  // Process swingScenario (if specified)
  if ("swingScenario" in options) {
    dataConfig.swingScenario = options.swingScenario;
  }
  // Process pollFilter (if specified and valid)
  if ("pollFilter" in options && isValidPollFilter(options.pollFilter)) {
    dataConfig.pollFilters = [options.pollFilter];
  }
  //HACK: For 2016 presidential election, default to 4-way election
  if (
    dataConfig.type === "president" &&
    dataConfig.year == 2016 &&
    !("raceFilter" in options)
  ) {
    dataConfig.raceFilter = "basic";
  }
  // Process poll scaling
  if ("pollScale" in options) {
    dataConfig.pollScale = parseFloat(options.pollScale);
  }

  // TODO: Add support for additional data configuration options here

  return dataConfig;
}

/**
 * Returns a new dataConfig object without customization options
 * included. This is useful for getting the "regular" forecasts.
 */
export function createDataConfigWithoutCustomizations<T>(
  oldDataConfig: DataConfig & T
): DataConfig {
  var newDataConfig: DataConfig = {
    type: oldDataConfig.type,
    year: oldDataConfig.year,
  };
  // raceFilter is not a "customization" option, so it can be included
  if ("raceFilter" in oldDataConfig) {
    newDataConfig.raceFilter = oldDataConfig.raceFilter;
  }
  return newDataConfig;
}

export function validateDataConfigTime(
  dataConfig: DataConfig & Pick<DataConfigCustomizations, "time">,
  racesByLocation: RacesByLocation
): any {
  if (dataConfig && "time" in dataConfig) {
    // round the forecast time to the closest forecast time before it
    return roundToForecastTime(dataConfig, racesByLocation, dataConfig.time);
  } else {
    // initialize time to the latest forecast time if not specified
    return getLatestForecastTime(dataConfig, racesByLocation);
  }
}

export function getAllForecastTimes(
  dataConfig: DataConfig,
  racesByLocation: RacesByLocation
): any {
  var cacheLabel = generateDataCacheLabel("allForecastTimes", dataConfig);
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else compute the union of all forecast times across races
  // TODO: Improve the efficiency of this method (if needed)
  var forecastTimesHash: any = {};
  $.each(racesByLocation, function (locAbbrev, racesInLocation) {
    $.each(racesInLocation, function (rIdx, race) {
      // If race has ended, construct a forecast at that time, which
      // will use the race outcomes as a forecast
      if (race.endTime !== null) {
        forecastTimesHash[race.endTime.format()] = race.endTime;
      }
      // Also add each of the race's actual forecasts
      $.each(race.forecasts, function (fIdx, forecast) {
        forecastTimesHash[forecast.forecastTime.format()] =
          forecast.forecastTime;
      });
    });
  });
  var forecastTimes: any = [];
  $.each(forecastTimesHash, function (fTimeString, fTime) {
    forecastTimes.push(fTime);
  });
  forecastTimes.sort(function (a: any, b: any) {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  });
  // Now store the times in the cache and return it
  cache.set(cacheLabel, forecastTimes);
  return forecastTimes;
}

export function getLatestForecastTime(
  dataConfig: any,
  racesByLocation: any
): any {
  var fTimes = getAllForecastTimes(dataConfig, racesByLocation);
  return fTimes[fTimes.length - 1];
}

export function roundToForecastTime(
  dataConfig: any,
  racesByLocation: any,
  time: any
) {
  var fTimes = getAllForecastTimes(dataConfig, racesByLocation);
  var nFTimes = fTimes.length;
  // New process to simplify switching between pres and senate
  //*
  if (time < fTimes[0] || fTimes[nFTimes - 1] < time) {
    return fTimes[nFTimes - 1];
  }
  for (var i = nFTimes - 1; i >= 0; --i) {
    if (time.isSame(fTimes[i])) {
      return fTimes[i];
    }
  }
  return fTimes[nFTimes - 1];
  //*/
  // OLD: Used to try to round; had some issues with 2016 president
  // and senate forecast times being slightly offset, so changed this
  // to reset to most recent time if the time doesn't exist.
  /*
    var i = 0;
    while ((i < nFTimes) && (fTimes[i] <= time)) { ++i; }
    if (i === 0) {
      return fTimes[fTimes.length-1];
    }
    return fTimes[i-1];
    */
}

export function selectForecastAtTimeX(race: any, fTime: any): any {
  // If the requested fTime is after the race has ended, then we want to
  // use the results of the election as identified in the candidates'
  // vote fields. Otherwise, we use the last available forecast.
  if (race.endTime !== null && race.endTime <= fTime) {
    return _generateOutcomeAsForecast(race);
  }
  // Else we find the latest forecast that we have.

  // TODO: Use a binary search to identify most recent forecast
  // Right now we'll just do a simple sequential search
  var i = 0;
  while (
    i < race.forecasts.length &&
    race.forecasts[i].forecastTime.diff(fTime) <= 0
  ) {
    ++i;
  }

  // When we finish the loop, either i == race.forecasts.length OR
  // race.forecasts[i].forecastTime > fTime. In either case, the
  // forecast at i-1 is the one to use.
  //
  // NOTE: This assumes that we have at least one forecast per race and
  // that race.forecasts[0].forecastTime <= fTime, which is (generally)
  // true because the priors create a forecast and we don't allow users
  // to select a forecast time before this. (TODO: Need to make sure
  // this will work for our implementation of custom forecasts.)
  return race.forecasts[i - 1];
}

function _generateOutcomeAsForecast(race: any): any {
  var candidates = race.candidates;
  var nCands = candidates.length;
  // TODO: Initialize the other forecast fields as needed
  var outcomeAsForecast: any = {
    raceID: race.id,
    candRelativeMajorityProbs: {},
    candAbsoluteMajorityProbs: {},
    pollWeights: {},
    isActualOutcome: true,
    isRunoffLikely: true,
  };
  // Identify the winning candidate based on votes; and also determine
  // whether or not a runoff is likely to occur
  var winningCandID = -1;
  var maxVotes = -1;
  // Now search through candidates for another winner
  for (var k = 0; k < nCands; ++k) {
    var cand = candidates[k];
    if (cand.votes >= 0.5 * race.totalVotes) {
      outcomeAsForecast.isRunoffLikely = false;
    }
    // If candidate has more than max votes, set as winner
    if (cand.votes > maxVotes) {
      maxVotes = cand.votes;
      // The previous highest candidate now loses
      if (winningCandID >= 0) {
        outcomeAsForecast.candRelativeMajorityProbs[winningCandID] = null;
      }
      // The current candidate is now winning
      winningCandID = cand.id;
      outcomeAsForecast.candRelativeMajorityProbs[winningCandID] = 1.0;
    } else {
      // Candidate loses
      outcomeAsForecast.candRelativeMajorityProbs[cand.id] = null;
    }
  }
  return outcomeAsForecast;
}

export function generateFullCacheLabel(baseLabel: any, dataConfig: DataConfig) {
  // Exploit the fact that the data labels are a subset of the general
  // cache labels, omitting only some of the potential configurations
  // (e.g., raceFilter)
  var label = generateDataCacheLabel(baseLabel, dataConfig);
  // Now add on any additional cache info
  // Check the race filter
  if ("raceFilter" in dataConfig) {
    label.push(dataConfig.raceFilter);
  } else {
    label.push("basic");
  }
  return label;
}

/**
 * Generates a cache label based on the dataConfig object; ignores
 * dataOptions that will not affect the type of data returned by the
 * data service (e.g., ignores raceFilter option used by probs service
 * to configure which races are used per location).
 */
export function generateDataCacheLabel(baseLabel: any, dataConfig: any): any {
  var label = [baseLabel, dataConfig.type, dataConfig.year];
  // Check the time in the dataConfig
  if ("time" in dataConfig) {
    label.push(dataConfig.time.format("YYYY-MM-DD_HHmmss"));
  } else {
    label.push("default");
  }
  // Check the poll filters and convert to a bit string
  var pollFiltersLabel = "pollFilters:000000000";
  if ("pollFilters" in dataConfig) {
    pollFiltersLabel =
      "pollFilters:" +
      (_.contains(dataConfig.pollFilters, "excludeCBS") ? "1" : "0") +
      (_.contains(dataConfig.pollFilters, "excludeCNN") ? "1" : "0") +
      (_.contains(dataConfig.pollFilters, "excludeFOX") ? "1" : "0") +
      (_.contains(dataConfig.pollFilters, "excludeGra") ? "1" : "0") +
      /*+ ((_.contains(dataConfig.pollFilters, 'excludeHar')) ? '1' : '0')*/
      (_.contains(dataConfig.pollFilters, "excludeNBC") ? "1" : "0") +
      /*+ ((_.contains(dataConfig.pollFilters, 'excludeNYT')) ? '1' : '0')*/
      (_.contains(dataConfig.pollFilters, "excludePPP") ? "1" : "0") +
      (_.contains(dataConfig.pollFilters, "excludeQui") ? "1" : "0") +
      (_.contains(dataConfig.pollFilters, "excludeRas") ? "1" : "0") +
      (_.contains(dataConfig.pollFilters, "excludeWSJ") ? "1" : "0");
  }
  label.push(pollFiltersLabel);
  // Check the swing scenario
  if ("swingScenario" in dataConfig) {
    label.push(dataConfig.swingScenario);
  } else {
    label.push("neutral");
  }

  // Check the pollScaler
  if ("pollScale" in dataConfig) {
    label.push("pollScale:" + dataConfig.pollScale);
  } else {
    label.push("pollScale:1.0");
  }
  return label;
}

export function configContainsCustomizations(
  dataConfig: DataConfig & Partial<DataConfigCustomizations>
): boolean {
  let precomputedScales = [
    0.01,
    0.1,
    0.2,
    0.3,
    0.4,
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1,
  ];
  let precomputedSwings = [
    "neutral",
    "rep5",
    "rep10",
    "rep20",
    "dem5",
    "dem10",
    "dem20",
  ];
  return (
    ("pollFilters" in dataConfig && dataConfig.pollFilters.length > 0) ||
    ("swingScenario" in dataConfig &&
      !precomputedSwings.includes(dataConfig.swingScenario as string)) ||
    ("pollScale" in dataConfig &&
      !precomputedScales.includes(dataConfig.pollScale as number))
  );
}

export function isValidPollFilter(potentialFilter: string): boolean {
  return (
    potentialFilter === "excludeCBS" ||
    potentialFilter === "excludeCNN" ||
    potentialFilter === "excludeFOX" ||
    potentialFilter === "excludeGra" ||
    /*(potentialFilter === 'excludeHar') ||*/
    potentialFilter === "excludeNBC" ||
    /*(potentialFilter === 'excludeNYT') ||*/
    potentialFilter === "excludePPP" ||
    potentialFilter === "excludeQui" ||
    potentialFilter === "excludeRas" ||
    potentialFilter === "excludeWSJ"
  );
}

export function createExcludedOrgsFromPollFilters(pollFilters: any): string[] {
  var excludedOrgs: string[] = [];
  if (_.contains(pollFilters, "excludeCBS")) {
    excludedOrgs.push("CBS", "CBS News");
  }
  if (_.contains(pollFilters, "excludeCNN")) {
    excludedOrgs.push("CNN");
  }
  if (_.contains(pollFilters, "excludeFOX")) {
    excludedOrgs.push(
      "Fox",
      "Fox News",
      "Fox Business",
      "FOX",
      "FOX News",
      "FOX Business",
      "FOX 12",
      "FOX 5 Atlanta",
      "FOX 2 Detroit"
    );
  }
  if (_.contains(pollFilters, "excludeGra")) {
    excludedOrgs.push("Gravis", "Gravis Marketing");
  }
  /*if (_.contains(pollFilters, 'excludeHar')) {
      excludedOrgs.push("Harper", "Harper (R)");
    }*/
  if (_.contains(pollFilters, "excludeNBC")) {
    excludedOrgs.push("NBC", "NBC News", "NBC 4");
  }
  /*if (_.contains(pollFilters, 'excludeNYT')) {
      excludedOrgs.push("NYT", "NY Times", "New York Times");
    }*/
  if (_.contains(pollFilters, "excludePPP")) {
    excludedOrgs.push(
      "PPP",
      "Public Policy Polling",
      "PPP (D)",
      "Public Policy Polling (D)"
    );
  }
  if (_.contains(pollFilters, "excludeQui")) {
    excludedOrgs.push("Quinnipiac");
  }
  if (_.contains(pollFilters, "excludeRas")) {
    excludedOrgs.push(
      "Rasmussen",
      "Rasmussen Reports",
      "Rasmussen (R)",
      "Rasmussen Reports (R)"
    );
  }
  if (_.contains(pollFilters, "excludeWSJ")) {
    excludedOrgs.push("WSJ");
  }
  return excludedOrgs;
}

export function pollFilterUID(pollFilters: any): number {
  let id = 0;
  if (_.contains(pollFilters, "excludeCBS")) {
    id += 1 << 0;
  }
  if (_.contains(pollFilters, "excludeCNN")) {
    id += 1 << 1;
  }
  if (_.contains(pollFilters, "excludeFOX")) {
    id += 1 << 2;
  }
  if (_.contains(pollFilters, "excludeGra")) {
    id += 1 << 3;
  }
  if (_.contains(pollFilters, "excludeNBC")) {
    id += 1 << 4;
  }
  if (_.contains(pollFilters, "excludePPP")) {
    id += 1 << 5;
  }
  if (_.contains(pollFilters, "excludeQui")) {
    id += 1 << 6;
  }
  if (_.contains(pollFilters, "excludeRas")) {
    id += 1 << 7;
  }
  if (_.contains(pollFilters, "excludeWSJ")) {
    id += 1 << 8;
  }
  return id;
}
