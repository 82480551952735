import React from "react";
import { css } from "@emotion/core";
import TableRow from "../TableRow";
import TableDataCell from "../TableDataCell";
import { Theme } from "../../../theme";

export interface ResultTableWithTurnOutRowProps {
  readonly state: string;
  readonly democrat: number;
  readonly republican: number;
  readonly strongDemocratTurnout: string;
  readonly neutral: string;
  readonly strongRepulbicanTurnout: string;
}

function ResultTableWithTurnOutRow({
  state,
  democrat,
  republican,
  strongDemocratTurnout,
  neutral,
  strongRepulbicanTurnout,
}: ResultTableWithTurnOutRowProps): JSX.Element {
  return (
    <TableRow>
      <TableDataCell leftBordered={false} variant="text">
        {state}
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.demStrong};
        `}
        style={{
          fontWeight: democrat > republican ? "bold" : "initial",
        }}
      >
        {democrat}%
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.repStrong};
        `}
        style={{
          fontWeight: republican > democrat ? "bold" : "initial",
        }}
      >
        {republican}%
      </TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.demStrong};
        `}
      >
        {strongDemocratTurnout}
      </TableDataCell>
      <TableDataCell>{neutral}</TableDataCell>
      <TableDataCell
        css={(theme: Theme) => css`
          color: ${theme.colors.parties.repStrong};
        `}
        rightBordered={false}
      >
        {strongRepulbicanTurnout}
      </TableDataCell>
    </TableRow>
  );
}

export default ResultTableWithTurnOutRow;
