/* Component: select
 * =============================================================================
 *
 * The select component is a custom drop down menu styled to match the design
 * of the site.
 *
 * ** component options
 *    choices  : an array of choices availible to the user
 *    startIdx : the index of the choices array to start with
 *    onChange : the function to run when the value of the select menu is
 *               changed
 *
 * ** Instance Variables
 *    _$element : The jquery object this component is called on.
 *    _value    : The current selected value.
 *    _onChange : Callback when the value of the select is changed. Takes one
 *                parameter which is the newly selected value of the component.
 *
 */
import _ from "underscore";
import $ from "jquery";

export interface SelectorChoice {
  value: string;
  display: string;
}

export interface SelectorOptions {
  startValue?: string;
  onChange?: (value: any) => void;
  choices?: SelectorChoice[];
}

export class Selector {
  _$element: JQuery<HTMLElement>;
  _onChange?: any;
  _value?: any;

  /* Object Definition + Constructor
   * ------------------------------------------------------------------------ */
  constructor(
    element: HTMLElement | JQuery<HTMLElement>,
    options: SelectorOptions
  ) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-cpnt-select");

    this._updateVars(options, () => {
      this._initializeHtml();
      this._updateOptions(options.choices!);
    });
  } // end constructor

  /* Create
   * -------------------------------------------------------------------------
   * Initialize the component's html structure and non-updatable properties.
   * NOTE: Only the _$element instance var is set at this point
   */
  _initializeHtml(): void {
    const $select = $(`
        <div class="ea-cpnt-select-inner">
          <div class="ea-cpnt-select-trigger">
            <span></span>
            <svg class="ea-cpnt-select-arrow" focusable="false"
                 xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" viewBox="0 0 15 15"
                 width="1.25em" height="1.25em"
                 xml:space="preserve">
              <path d="M10.2,5.2L7.3,8L4.4,5.2L3.6,
                       6.1l3.8,3.8l3.8-3.8L10.2,5.2z"/>
            </svg>
          </div>
          <div class="ea-cpnt-select-options"></div>
        </div>
      `);

    $select.on(
      "click",
      function () {
        $select.toggleClass("ea-cpnt-select-inner-open");
      }.bind(this)
    );

    $(window).on("click", function (e) {
      if (!$.contains($select.get(0), e.target as any)) {
        $select.removeClass("ea-cpnt-select-inner-open");
      }
    });

    this._$element.append($select);
  } // end _initializeHtml

  /* disable
   * ---------------------------------------------------------------------- */
  disable(): void {
    this._$element
      .find("ea-cpnt-select-inner")
      .removeClass("ea-cpnt-select-inner-open");
    this._$element.addClass("ea-cpnt-select-disabled");
  } // end disable

  /* enable
   * ---------------------------------------------------------------------- */
  enable(): void {
    this._$element.removeClass("ea-cpnt-select-disabled");
  } // end enable

  /* update
   * ---------------------------------------------------------------------- */
  update(options: SelectorOptions) {
    this._updateVars(options, () => {
      this._updateOptions(options.choices!);
    });
  } // end updateVars

  /* _updateVars
   * ---------------------------------------------------------------------- */
  _updateVars(options: SelectorOptions, success: () => void) {
    // Confirm that all neccecary options are present
    if (!_.isObject(options)) {
      this.error("updateVars: options not an object");
      return;
    }
    if (!("choices" in options) || options.choices!.length <= 0) {
      this.error("updateVars: no choices");
      return;
    }
    if (!("startValue" in options)) {
      this.error("updateVars: no start value");
      return;
    }
    if (!("onChange" in options)) {
      if (this._onChange === undefined) this._onChange = null;
    } else this._onChange = options.onChange;

    this._value = options.startValue;

    success();
  } // end updateVars

  /* setValue
   * ---------------------------------------------------------------------- */
  setValue(value: any) {
    const $options = this._$element.find(".ea-cpnt-select-options");
    this._setValue($options.find(`[data-value='${value}']`));
  } // end setValue

  /* _setValue
   * ---------------------------------------------------------------------- */
  _setValue($clickOpt: JQuery<Element>) {
    if (!$clickOpt.hasClass("ea-cpnt-select-selected")) {
      $clickOpt
        .parent()
        .find(".ea-cpnt-select-selected")
        .removeClass("ea-cpnt-select-selected");
      $clickOpt.addClass("ea-cpnt-select-selected");
      $clickOpt
        .parent()
        .parent()
        .find(".ea-cpnt-select-trigger > span")
        .text($clickOpt.text());
      this._value = $clickOpt.data("value");
      if (this._onChange !== null) this._onChange(this._value);
    }
  } // end _setValue

  /* _updateOptions
   * ---------------------------------------------------------------------- */
  _updateOptions(choices: SelectorChoice[]) {
    this._$element.find(".ea-cpnt-select-options").empty();

    for (let i = 0; i < choices.length; i++) {
      const $option = $(`
          <span
            class="ea-cpnt-select-option"
            data-value="${choices[i].value}">${choices[i].display}</span>
        `);
      if (choices[i].value == this._value) {
        $option.addClass("ea-cpnt-select-selected");
        this._$element
          .find(".ea-cpnt-select-trigger > span")
          .text(choices[i].display);
      }

      $option.on("click", (e) => {
        const $clickOpt = $(e.target);
        this._setValue($clickOpt);
      });

      this._$element.find(".ea-cpnt-select-options").append($option);
    }
  } // end _updateOptions

  /* error
   * ---------------------------------------------------------------------- */
  error(type: any) {
    this._$element.empty();

    var $errorMsg = $(`
        <div class="alert alert-error">
          Something seems to have gone wrong with our select component.
          We'll look into the issue shortly. Perhaps a refresh would help?
        </div>
      `);
    this._$element.append($errorMsg);

    console.error(`An error occured in a select component (${type})`);
  } // end error
} // end Selector prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------------------------ */
// jQuery Plugin Definition
// ts-ignore
$.fn.eaComponentSelect = function (options: SelectorOptions | undefined) {
  var elements = this;
  options = typeof options === "object" ? options : {};

  var selectObjs: any[] = [];

  $(elements).each(function (idx, element: HTMLElement) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    selectObjs.push(new Selector($element, myOptions));
  });

  return selectObjs;
};

// Autoloader
$(function () {
  $(".ea-component-select").eaComponentSelect();
});
