/* Module: Sliders
 * ==========================================================================
 *
 * The Sliders module displays all probabilities for all races in an election
 * in a graphical format.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/sliders.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *   - `showTitle`:     Use the default title or not. Defaults to false.
 *   - `usePercentage`: A boolean flag that indicates whether probabilities
 *                      should be displayed as percentage or should be
 *                      displayed as number.
 *                      It defaults to true (display percentage).
 *   - TODO: Consider adding support for specifying default sorting order
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_moduleConfig`: Stores info about module configuration
 *   - `_races`:        The races associated with the current data config
 *   - `_displayOrder`: An array of location abbreviations sorted based on the
 *                      currently selected display order
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *   - `_chart`:        The Highcharts instance.
 *   - `_titleModule`:  A reference to the title module.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as data from "../services/data";
import * as math from "../services/math";
import * as probs from "../services/probs";
import * as election from "../services/election";
import * as color from "../services/color";

export class EASliders {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-sliders");

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // Initialize the module-specific configurations. These options should
    // only be changed by the module itself.
    this._moduleConfig = {
      showTitle: false,
      usePercentage: true,
    };
    if ("showTitle" in options) {
      this._moduleConfig.showTitle = options.showTitle;
    }

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._initializeHighcharts();
        this._updateHtml();
      }, this)
    );
  }

  _initializeHtml() {
    var $title = $('<div class="ea-sliders-title"></div>');

    var $displayOptions = $(
      '<form class="form-inline ea-sliders-displayOptions">' +
        '<div class="form-group clearfix">' +
        '<label for="rowOrder" class="control-label">Row Order:</label> ' +
        '<select name="rowOrder" class="form-control ea-sliders-rowOrder">' +
        '<option value="alpha-sort">Alphabetical</option>' +
        '<option value="dem">Democrat First</option>' +
        '<option value="rep">Republican First</option>' +
        "</select> " +
        "</div>" +
        "</form>"
    );
    var $sliders = $('<div class="ea-sliders-chart"></div>');

    this._titleModule = $title.eaTitle({
      format: "", // format is set in update()
    })[0];

    var $rowOrder = $displayOptions.find(".ea-sliders-rowOrder");
    var callUpdate = $.proxy(function () {
      // Only need to update the chart itself, as no data options are
      // changing; this should be relatively fast
      $rowOrder.prop("disabled", true);
      this._updateChart();
      $rowOrder.prop("disabled", false);
    }, this);
    $rowOrder.change(callUpdate).prop("disabled", true); // Start out disabled
    // Set display to dem first by default [2016-09-26]
    $rowOrder.val("dem");

    this._$element.append($title).append($displayOptions).append($sliders);
  } // end _initializeHtml

  _initializeHighcharts() {
    var $sliders = this._$element.find(".ea-sliders-chart");
    var usePercentage = this._moduleConfig.usePercentage;
    var probText = usePercentage ? "Chance" : "Probability";

    $sliders.highcharts({
      chart: {
        animation: false,
        spacingBottom: 0,
        type: "bar",
      },
      credits: { text: "Graphs Powered by Highcharts.com." },
      legend: {
        borderRadius: 4,
        borderWidth: 1,
        x: 30,
        y: -30,
      },
      plotOptions: {
        bar: {
          pointWidth: 15,
          stacking: "normal",
        },
        series: {
          animation: false,
          events: {
            legendItemClick: function (event) {
              // Highcharts, by default, toggles the series when you
              // click on its legend icon.  So just let that happen
              // unless the event would hide the last visible party.
              var series = this;

              if (series.visible) {
                // turning it off
                var visibleCount = 0;

                for (var i = 0; i < series.chart.series.length; i++) {
                  var series = series.chart.series[i];
                  if (series.visible) {
                    visibleCount++;
                  }
                }

                if (visibleCount === 1) {
                  event.preventDefault();
                }
              }
            },
          },
        },
      },
      series: [],
      title: { text: "" },
      tooltip: {
        formatter: function () {
          // Choose format for display probability
          var probText = usePercentage
            ? math.percentFormat(this.point.y, 2)
            : math.sformat(this.point.y, 3);
          return (
            "<span>" +
            this.key +
            "</span><br/>" +
            '<span style="color:' +
            this.series.color +
            '">' +
            this.series.name +
            ": " +
            "</span>" +
            "<b>" +
            probText +
            "</b>" +
            "<br/>"
          );
        },
      },
      xAxis: { categories: [] },
      yAxis: [
        {
          labels: {
            overflow: "justify",
          },
          min: 0,
          max: 1,
          title: { text: probText + " of Winning" },
        },
        {
          labels: {
            overflow: "justify",
          },
          linkedTo: 0,
          opposite: true,
          title: { text: probText + " of Winning" },
        },
      ],
    });

    this._chart = $sliders.highcharts();
  } // end _initializeHighcharts

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    // Make sure drop-down is disabled before doing an update
    this._$element.find(".ea-sliders-rowOrder").prop("disabled", true);

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();
      }, this)
    ); // end updater promise

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      var filteredLocRaces = util.removeInactiveRaces(
        this._dataConfig,
        this._races
      );
      // Update the default display order
      this._displayOrder = [];
      $.each(
        filteredLocRaces,
        $.proxy(function (locAbbrev) {
          this._displayOrder.push(locAbbrev);
        }, this)
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get Promise object from data service API
    var racesWithForecastsPromise = data.getRacesWithForecasts(
      this._dataConfig
    );
    racesWithForecastsPromise.done(successCallback).fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    if (this._moduleConfig.showTitle) {
      // truthy
      var formatStr =
        this._dataConfig.type === "president"
          ? "{year} {type} Races by State"
          : "{year} {type} Races by Senate Seat";

      this._titleModule.setFormat(formatStr);
      this._titleModule.update({
        type: this._dataConfig.type,
        year: this._dataConfig.year,
      });

      this._$element.find(".ea-sliders-title").show();
    } else {
      this._$element.find(".ea-sliders-title").hide();
    }

    // Update the sort data display drop-down option
    var $rowOrder = this._$element.find(".ea-sliders-rowOrder");
    var oldRowOrder = $rowOrder.val();
    //console.log(oldRowOrder); // DEBUG
    $rowOrder.empty();
    if (this._dataConfig.type === "senate") {
      // no sorting by # of votes for senate races, because they all
      // have a 'vote' of 1
      $rowOrder.append(
        '<option value="alpha-sort">Alphabetical</option>' +
          '<option value="dem">Democrat First</option>' +
          '<option value="rep">Republican First</option>'
      );
    } else {
      $rowOrder.append(
        '<option value="alpha-sort">Alphabetical</option>' +
          '<option value="v-asc">Votes Ascending</option>' +
          '<option value="v-dsc">Votes Descending</option>' +
          '<option value="dem">Democrat First</option>' +
          '<option value="rep">Republican First</option>'
      );
    }
    // Reset the row order to what it was prior to update, assuming that
    // it is available
    var oldRowOrderAvailable = false;
    $rowOrder.find("option").each(function () {
      if (this.value === oldRowOrder) {
        $rowOrder.val(oldRowOrder);
        oldRowOrderAvailable = true;
        return false;
      }
    });
    if (!oldRowOrderAvailable) {
      $rowOrder.val("dem"); // Default to dem first [2016-09-26]
    }

    // Update the chart and re-enable the drop-down
    this._updateChart();
    $rowOrder.prop("disabled", false);
    return;
  }

  _updateChart() {
    var locResults = probs.computeLocationResults(
      this._dataConfig,
      this._races
    );

    // Sort the races according to the selected drop-down option
    this._sortDisplayOrder(locResults);

    var locationLabels = [];
    for (var i = 0; i < this._displayOrder.length; i++) {
      var locAbbrev = this._displayOrder[i];
      var locResult = locResults[locAbbrev];

      if (this._dataConfig.type === "president") {
        locationLabels.push("(" + locResult.value + ") " + locAbbrev);
      } else {
        locationLabels.push(locAbbrev);
      }
    }
    this._chart.xAxis[0].setCategories(locationLabels, false);
    this._chart.setSize(this._$element.width(), 20 * locationLabels.length);

    // Remove all existing series from the chart
    while (this._chart.series.length > 0) {
      this._chart.series[0].remove(false);
    }
    // Add updated series back
    var allSeries = this._getAllSeries(locResults);
    for (var i = 0; i < allSeries.length; i++) {
      this._chart.addSeries(allSeries[i], false);
    }

    this._chart.redraw();
  } // end _updateChart

  _sortDisplayOrder(locResults) {
    var rowOrder = this._$element.find(".ea-sliders-rowOrder").val();

    switch (rowOrder) {
      case "alpha-sort":
        this._displayOrder.sort(
          $.proxy(function (a, b) {
            var aVal = this._races[a][0].location.name;
            var bVal = this._races[b][0].location.name;
            if (aVal === bVal) {
              return 0;
            }
            return aVal < bVal ? -1 : 1;
          }, this)
        );
        break;

      case "v-asc":
        this._displayOrder.sort(
          $.proxy(function (a, b) {
            var aVal = this._races[a][0].location.value;
            var bVal = this._races[b][0].location.value;
            if (aVal === bVal) {
              return 0;
            }
            return aVal < bVal ? -1 : 1;
          }, this)
        );
        break;

      case "v-dsc":
        this._displayOrder.sort(
          $.proxy(function (a, b) {
            var aVal = this._races[a][0].location.value;
            var bVal = this._races[b][0].location.value;
            if (aVal === bVal) {
              return 0;
            }
            return aVal > bVal ? -1 : 1;
          }, this)
        );
        break;

      case "dem":
        this._displayOrder.sort(function (a, b) {
          var aProbs = locResults[a].probsByParty;
          var aVal = "Democrat" in aProbs ? aProbs.Democrat : 0.0;
          var bProbs = locResults[b].probsByParty;
          var bVal = "Democrat" in bProbs ? bProbs.Democrat : 0.0;
          if (aVal === bVal) {
            return 0;
          }
          // Note: We want to show the list in descending order,
          //       (highest dem % first), so these values are reversed.
          return aVal < bVal ? 1 : -1;
        });
        break;

      case "rep":
        this._displayOrder.sort(function (a, b) {
          var aProbs = locResults[a].probsByParty;
          var aVal = "Republican" in aProbs ? aProbs.Republican : 0.0;
          var bProbs = locResults[b].probsByParty;
          var bVal = "Republican" in bProbs ? bProbs.Republican : 0.0;
          if (aVal === bVal) {
            return 0;
          }
          // Note: We want to show the list in descending order,
          //       (highest rep % first), so these values are reversed.
          return aVal < bVal ? 1 : -1;
        });
        break;

      default:
        // leave array as is
        console.warn("EASliders.getSeries received an unknown rowOrder");
        break;
    }
    return;
  } // end _sortDisplayOrder

  _getAllSeries(locResults) {
    // Each candidate or party needs their own series. Right now we just
    // show the aggregate results by party (or main candidate in case of
    // president). However, if we want to break this down further for the
    // Senate races to show the individual candidates' probabilities (such
    // as when a primary has not finished yet), then this may get quite a
    // bit messier...
    var partyProbs = {};
    for (var i = 0; i < this._displayOrder.length; ++i) {
      var locAbbrev = this._displayOrder[i];
      var locResult = locResults[locAbbrev];

      $.each(locResult.probsByParty, function (party) {
        if (!(party in partyProbs)) {
          partyProbs[party] = [];
        }
      });
    }
    var showIndep =
      this._dataConfig.type === "senate" ||
      ("raceFilter" in this._dataConfig &&
        this._dataConfig.raceFilter !== "basic");

    // Ensure that independents are included for Senate races
    // This ensures that an 'independent' series always appears
    // on the chart; without this, we might have problems when trying
    // to update
    if (this._dataConfig.type === "senate") {
      if (!("Independent" in partyProbs)) {
        partyProbs.Independent = [];
      }
    }

    for (var i = 0; i < this._displayOrder.length; ++i) {
      var locAbbrev = this._displayOrder[i];
      var locResult = locResults[locAbbrev];

      $.each(partyProbs, function (party, probs) {
        var prob = 0;
        if (party in locResult.probsByParty) {
          prob = locResult.probsByParty[party];
        }
        probs.push(math.nformat(prob, 3));
      });
    }

    // add each series of candidate probs to the chart
    var labelsByParty = election.getPartyLabels(
      this._dataConfig.type,
      _.values(this._races)[0][0].candidates
    );
    var allSeries = [];
    $.each(partyProbs, function (party, probs) {
      var partyChartIndex =
        party === "Democrat" ? 0 : party === "Republican" ? 2 : 1;
      var partySeries = {
        color: color.getStrongPartyColor(party),
        data: probs,
        index: 2 - partyChartIndex, // Not quite sure why we need this...
        legendIndex: partyChartIndex,
        name: labelsByParty[party],
      };
      // TODO: Changing index changes display ordering. This can be used
      // to enable candidate / party ordering of actual sliders
      if (party !== "Independent" || showIndep) {
        allSeries.push(partySeries);
      }
    });
    return allSeries;
  } // end _getAllSeries

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our Sliders widget... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The Sliders module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The Slider module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The Sliders module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The Sliders module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the Sliders module.");
    }

    // TODO: notify devs
  } // end error
} // end EASliders prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaSliders = function (options) {
  var elements = this;

  options = _.isObject(options) ? options : {};

  var sliders = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    sliders.push(new EASliders($element, myOptions));
  });

  return sliders;
};

// Autoloader
$(function () {
  $(".ea-sliders").eaSliders();
});
