import React, { HTMLProps } from "react";
import { css } from "@emotion/core";

function Table({
  children,
  ...others
}: HTMLProps<HTMLTableElement>): JSX.Element {
  return (
    <div
      css={css`
        overflow-x: auto;
      `}
    >
      <table
        css={css`
          width: 100%;
          max-width: 100%;
          border-collapse: collapse;
        `}
        {...others}
      >
        {children}
      </table>
    </div>
  );
}

export default Table;
