import React, { Component, ReactNode } from "react";
import { css } from "@emotion/core";
import ReactDOM from "react-dom";
import _ from "underscore";
import * as util from "../../../services/util";
import * as data from "../../../services/data";
import * as probs from "../../../services/probs";
import * as election from "../../../services/election";
import * as colorService from "../../../services/color";
import promisify from "../../../utils/promisify";
import Overview from "./Overview";
import { BlockContent } from "./Block";
import Blocks from "./Blocks";

export interface ExpectedValueBarProps {
  options: any;
}

interface ExpectedValueBarState {
  markerMarginLeft: number;
  bluePercentage: number;
  blueNumber: number;
  redNumber: number;
  blueTitle: string;
  redTitle: string;
  blocks: BlockContent[];
}

function calcMargin(a: any): number {
  const partyProbs = [
    a[1].probsByParty.Independent !== undefined
      ? a[1].probsByParty.Independent
      : 0,
    a[1].probsByParty.Democrat !== undefined ? a[1].probsByParty.Democrat : 0,
    a[1].probsByParty.Republican !== undefined
      ? a[1].probsByParty.Republican
      : 0,
  ];
  const maxProb = Math.max(...partyProbs);
  const probSum = partyProbs.reduce((a, b) => a + b, 0) - maxProb;
  const marginA = maxProb - probSum;
  if (maxProb === a[1].probsByParty.Democrat) return marginA;
  else return -marginA;
}

function cmpFn(a: any, b: any): number {
  let marginA = calcMargin(a);
  let marginB = calcMargin(b);
  return marginB - marginA;
}

class ExpectedValueBar extends Component<
  ExpectedValueBarProps,
  ExpectedValueBarState
> {
  private dataConfig: any;
  private races?: any;

  private containerRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      markerMarginLeft: 0,
      bluePercentage: 0.5,
      blueNumber: 0,
      redNumber: 0,
      blueTitle: "Democrats",
      redTitle: "Republicans",
      blocks: [],
    };

    const { options } = this.props;

    // Validate the initial options
    if (typeof options !== "object") {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this.dataConfig = util.createDataConfigFromOptions(options);
    this.dataConfig.pollScale = 0.3;
    this.dataConfig.swingScenario = "neutral";
  }

  private error(message: any): void {
    console.error(message);
  }

  private getBlocks(): BlockContent[] {
    const stateStats = probs.computeLocationResults(
      this.dataConfig,
      this.races
    );

    const raceType: string = this.dataConfig.type;
    let totalVotes = 0;

    if (raceType == "president") {
      totalVotes = election.control.president.half * 2;
    } else {
      totalVotes = election.control.senate.half * 2;
    }

    const blocks: BlockContent[] = [];
    const raceYear = this.dataConfig.year;

    if (raceType == "senate") {
      const demSeatNotUp =
        election.senateSeatsNotUp[raceYear].Democrat +
        election.senateSeatsNotUp[raceYear].Independent;

      const demNotUpColor = colorService.getSteppedMarginColor(1);

      blocks.push({
        name: "Democrat Seats Not Up",
        demName: "Democrats",
        repName: "Republicans",
        background: demNotUpColor,
        demColor: colorService.getStrongPartyColor("Democrat"),
        repColor: colorService.getStrongPartyColor("Republican"),
        width: demSeatNotUp / totalVotes,
        demProb: 1,
        repProb: 0,
      });
    }

    for (const [state, results] of Object.entries<any>(stateStats).sort(
      cmpFn
    )) {
      const margin = calcMargin([state, results]);
      const value = (results as any).value;
      const color = colorService.getSteppedMarginColor(margin);

      let name = this.races[state][0].location.name;

      if (name === "Maine 1st Congressional District") {
        name = "Maine's 1st District";
      }

      if (name === "Maine 2nd Congressional District") {
        name = "Maine's 2nd District";
      }

      let demName: string = "";
      let repName: string = "";
      let indName: string = "";

      for (const candidate of Object.values<any>(
        (results as any).candidatesByID
      )) {
        if (candidate.party == "Democrat") {
          if (!demName) {
            demName = candidate.lastName;
          }
        } else if (candidate.party == "Republican") {
          if (!repName) {
            repName = candidate.lastName;
          }
        } else {
          if (!indName) {
            indName = candidate.lastName;
          }
        }
      }

      let demProb = demName ? results.probsByParty.Democrat : 0;
      let repProb = repName ? results.probsByParty.Republican : 0;
      let demColor = colorService.getStrongPartyColor("Democrat");
      let repColor = colorService.getStrongPartyColor("Republican");
      if (indName) {
        if (!demName) {
          demColor = colorService.getWeakPartyColor("Independent");
          demName = indName;
          demProb = results.probsByParty.Independent;
          repProb = results.probsByParty.Republican;
        } else if (!repName) {
          repColor = colorService.getWeakPartyColor("Independent");
          repName = indName;
          demProb = results.probsByParty.Democrat;
          repProb = results.probsByParty.Independent;
        } else {
          demProb = results.probsByParty.Democrat;
          repProb = results.probsByParty.Republican;
        }
      }

      const electoralVotes = raceType == "president" ? ` (${value})` : "";

      blocks.push({
        width: value / totalVotes,
        background: color,
        demName,
        repName,
        demColor,
        repColor,
        demProb,
        repProb,
        name,
        electoralVotes,
      });
    }

    if (raceType == "senate") {
      const repSeatNotUp = election.senateSeatsNotUp[raceYear].Republican;
      const repNotUpColor = colorService.getSteppedMarginColor(-1);

      blocks.push({
        name: "Republican Seats Not Up",
        width: repSeatNotUp / totalVotes,
        demName: "Democrats",
        repName: "Republicans",
        demProb: 0,
        repProb: 1,
        demColor: colorService.getStrongPartyColor("Democrat"),
        repColor: colorService.getStrongPartyColor("Republican"),
        background: repNotUpColor,
      });
    }

    return blocks;
  }

  private updateContent(): void {
    const containerWidth = this.containerRef.current?.clientWidth!;
    const stats = probs.computeStats(this.dataConfig, this.races);
    const raceType = this.dataConfig.type;

    let totalVotes = 0;

    if (raceType == "president") {
      totalVotes = election.control.president.half * 2;
    } else {
      totalVotes = election.control.senate.half * 2;
    }

    const demStats =
      raceType == "president" ? stats.info.Democrat : stats.info.DemInd;

    const labels = election.getPartyLabels(
      this.dataConfig.type,
      _.values(this.races)[0][0].candidates
    );

    const blocks = this.getBlocks();

    let blueNumber: number;

    if (raceType === "president") {
      blueNumber = stats.info.Democrat.expected.toFixed(1);
    } else {
      blueNumber = stats.info.DemInd.expected.toFixed(1);
    }

    this.setState((state, props) => ({
      markerMarginLeft: (demStats.expected / totalVotes) * containerWidth,
      bluePercentage: demStats.expected / totalVotes,
      blueNumber,
      redNumber: stats.info.Republican.expected.toFixed(1),
      blueTitle: labels.Democrat,
      redTitle: labels.Republican,
      blocks,
    }));
  }

  private async updateData(): Promise<void> {
    try {
      this.races = await promisify(data.getRacesWithForecasts(this.dataConfig));

      // Make sure that dataConfig time is set appropriately
      this.dataConfig.time = util.validateDataConfigTime(
        this.dataConfig,
        this.races
      );
    } catch (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error("");
      }
    }
  }

  public async componentDidMount(): Promise<void> {
    await this.updateData();
    this.updateContent();
  }

  public async update(options: any): Promise<void> {
    this.dataConfig = options;

    await this.updateData();
    this.updateContent();
  }

  public render(): ReactNode {
    const {
      bluePercentage,
      blueNumber,
      redNumber,
      blueTitle,
      redTitle,
      blocks,
    } = this.state;

    return (
      <div
        css={css`
          position: relative;
        `}
        ref={this.containerRef}
      >
        <Overview
          bluePercentage={bluePercentage}
          blueNumber={blueNumber}
          redNumber={redNumber}
          blueTitle={blueTitle}
          redTitle={redTitle}
        ></Overview>
        <Blocks blocks={blocks} />
      </div>
    );
  }
}

export function mount(
  container: HTMLElement,
  props: ExpectedValueBarProps
): ExpectedValueBar {
  let ref = React.createRef<ExpectedValueBar>();
  ReactDOM.render(<ExpectedValueBar ref={ref} {...props} />, container);

  if (!ref.current) {
    throw new Error("unable to obtain ref");
  }

  return ref.current;
}

export default ExpectedValueBar;
