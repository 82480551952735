import _ from "underscore";
import $ from "jquery";
import { Cache } from "./cache";
import * as util from "./util";
import { WithElectionType } from "./election";
import * as election from "./election";
import { DataConfig } from "./common";

const cache: Cache = new Cache();

export function _filterRacesInLocation(
  locRaces: any,
  raceFilter: "with-Johnson" | "with-Johnson-Stein",
  fTime: any
): any {
  var n = locRaces.length;
  if (n === 1) {
    return locRaces;
  } // Nothing to filter
  var filteredLocRaces: any[] = [];
  var racesByCandCount: any = {};
  var inactiveRaceCount = 0;
  for (var i = 0; i < n; ++i) {
    var race = locRaces[i];
    // skip race that is not active
    if (!util.isRaceActive(race, fTime)) {
      inactiveRaceCount++;
      continue;
    }
    var nCands = race.candidates.length;
    if (racesByCandCount[nCands]) {
      console.warn("ERROR: Multiple races with " + nCands + " candidates");
    }
    racesByCandCount[nCands] = race;
  }
  // if all races in locRaces inactive, just return empty array
  if (inactiveRaceCount === n) return filteredLocRaces;

  var candCountOrdering;
  if (raceFilter === "with-Johnson") {
    candCountOrdering = [3, 4, 2];
  } else if (raceFilter === "with-Johnson-Stein") {
    candCountOrdering = [4, 3, 2];
  } else {
    // raceFilter === 'basic'
    candCountOrdering = [2, 3, 4];
  }
  for (var i = 0; i < candCountOrdering.length; ++i) {
    var count = candCountOrdering[i];
    if (count in racesByCandCount) {
      filteredLocRaces.push(racesByCandCount[count]);
      break;
    }
  }
  if (filteredLocRaces.length != 1) {
    console.warn("ERROR: Filter failed...");
  }
  return filteredLocRaces;
}

/* ### computeLocationResults
 *
 * Computes aggregate results from the forecasts for all races in each
 * location. See `computeLocationResult` below for details of the return
 * objects.
 *
 * **Returns**
 *
 * A hash object containing locResult objects for each location.
 *
 * Example:
 *
 * {
 *      AL: <locResult object>
 *    , AK: <locResult object>
 *    , ...
 * }
 *
 */
export function computeLocationResults(dataConfig: any, racesByLocation: any) {
  var cacheLabel = util.generateFullCacheLabel("locationResults", dataConfig);
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else we need to compute the results from scratch
  var locResults: any = {};
  $.each(racesByLocation, function (locAbbrev, locRaces) {
    // TODO: Find a better way to validate this
    if (dataConfig.type === "president" && dataConfig.year == 2016) {
      locRaces = _filterRacesInLocation(
        locRaces,
        "raceFilter" in dataConfig
          ? dataConfig.raceFilter
          : "with-Johnson-Stein",
        dataConfig.time
      );
    }
    // Only add to locResults if locRaces is not empty
    if (locRaces.length > 0) {
      locResults[locAbbrev] = computeLocationResult(locRaces, dataConfig.time);
    }
  });

  // Handle corner cases here (bit of a hack...)
  if (dataConfig.type === "president" && dataConfig.year == 2008) {
    if (locResults.NE.isFinal) {
      locResults.NE.probsByParty.Democrat = 0.2;
      locResults.NE.probsByParty.Republican = 0.8;
    }
  }

  // Now store the results and return them
  cache.set(cacheLabel, locResults);
  return locResults;
}

/* ### computeLocationResult
 *
 * Groups the races in each location (state or Senate seat by class)
 * into four categories, 'primary', 'primaryRunoff', 'general', and
 * 'generalRunoff', and then determines probabilities that each
 * candidate wins the general election. (E.g., a candidate's probability
 * of winning a general race is weighted by his or her probability of
 * winning the respective party's primary.)
 *
 * Races have a natural ordering by race-date and dependence:
 *  1. primary          special-primary
 *  2. primary-runoff   special-primary-runoff
 *  3. general          special
 *  4. general-runoff   special-runoff
 *
 * Probabilities need to be computed in order and applied to subsequent
 * races. If a race has already occurred, however, the winner is known
 * and can be used without requiring probabilities.
 *
 * Any races that have been removed by this forecast time are ignored.
 * Similarly, any races that were not yet active at this forecast time
 * are skipped.
 *
 * **Returns**
 *
 * A locResult object containing probabilities for the candidates.
 *
 * Example for Presidential location:
 *
 * {
 *      value: 9
 *    , probsByParty: {
 *          Democrat: 0.031
 *        , Republican: 0.969
 *      }
 *    , candidatesByID: {
 *          2: { Candidate object for Obama }
 *        , 4: { Candidate object for Romney }
 *      }
 *    , probsByCandID: {
 *          2: 0.031
 *        , 4: 0.969
 *      }
 *    , primaryProbsByCandID: {
 *      }
 *    , raceProbsByRaceID: {
 *          17: 1.0
 *      }
 * }
 *
 * Example for Senate location with multiple races:
 *
 * {
 *      value: 1
 *    , probsByParty: {
 *          Democrat:   0.525 // = Prob Dem A wins + Prob Dem B wins
 *        , Republican: 0.475 // = Prob Rep A wins
 *      }
 *    , candidatesByID: {
 *          17: { Candidate object for Dem A }
 *        , 19: { Candidate object for Dem B }
 *        , 15: { Candidate object for Rep A }
 *      }
 *    , probsByCandID: {
 *          17: 0.45  // = 0.75 * Prob Dem A beats Rep A (0.6)
 *        , 19: 0.075 // = 0.25 * Prob Dem B beats Rep A (0.3)
 *        , 15: 0.475 // = 0.75 * Prob Rep A beats Dem A (0.4)
 *                    // + 0.25 * Prob Rep A beats Dem B (0.7)
 *      }
 *    , primaryProbsByCandID: {
 *          17: 0.75  // Dem A has a 0.75 chance of winning primary
 *        , 19: 0.25  // Dem B has a 0.25 chance of winning primary
 *      }
 *    , raceProbsByRaceID: {
 *          5: 1.00 // = Dem primary will happen; Race: Dem A vs. Dem B
 *        , 3: 0.75 // = Prob Dem A wins primary; Race: Dem A vs. Rep A
 *        , 4: 0.25 // = Prob Dem B wins primary; Race: Dem B vs. Rep A
 *      }
 * }
 *
 * Example for Senate location with multiple races but finished primary:
 *
 * {
 *      value: 1
 *    , probsByParty: {
 *          Democrat:   0.60 // = Prob Dem A wins + Prob Dem B wins
 *        , Republican: 0.40 // = Prob Rep A wins
 *      }
 *    , candidatesByID: {
 *          17: { Candidate object for Dem A }
 *        , 19: { Candidate object for Dem B }
 *        , 15: { Candidate object for Rep A }
 *      }
 *    , probsByCandID: {
 *          17: 0.60 // = 1.00 * Prob Dem A beats Rep A (0.6)
 *        , 19: null // = null * Prob Dem B beats Rep A (0.3)
 *        , 15: 0.40 // = 1.00 * Prob Rep A beats Dem A (0.4)
 *                   // + null * Prob Rep A beats Dem B (0.7)
 *      }
 *    , primaryProbsByCandID: {
 *          17: 1.0   // Dem A won the primary
 *        , 19: null  // Dem B lost the primary
 *      }
 *    , raceProbsByRaceID: {
 *          5: 1.00 // = Dem primary will happen; Race: Dem A vs. Dem B
 *        , 3: 1.00 // = Prob Dem A wins primary; Race: Dem A vs. Rep A
 *        , 4: null // = Prob Dem B wins primary; Race: Dem B vs. Rep A
 *      }
 * }
 */
export function computeLocationResult(races: any, fTime: any) {
  //console.log("Computing results for " + races[0].location.abbrev
  //        + " at " + fTime);

  // TODO: Find a better structure for this?
  var locResult: any = {
    value: races[0].location.value,
    isFinal: false, // Set if all races ended by given fTime
    finalRace: null, // Set to deciding general election race if final
    probsByParty: {},
    candidatesByID: {}, // Look-up table for mapping ids to candidates
    probsByCandID: {},
    primaryProbsByCandID: {},
    raceProbsByRaceID: {},
    runoffPredecessors: {},
  };

  var racesBySubtype = util.groupRacesBySubtype(races);
  if (racesBySubtype.primary.length > 0) {
    _computePrimaryProbs(
      locResult,
      racesBySubtype.primary,
      racesBySubtype.primaryRunoff,
      fTime
    );
  }
  _computeGeneralProbs(
    locResult,
    racesBySubtype.general,
    racesBySubtype.generalRunoff,
    fTime
  );

  // Once we have processed all of the races, we can determine the
  // final outputs for each candidate and party
  $.each(locResult.probsByCandID, function (cID, candWinProb) {
    // Skip candidates that cannot win
    if (candWinProb === null) {
      return;
    }
    // Look up candidate object by ID.
    var candidate = locResult.candidatesByID[cID];
    var candParty = candidate.party;
    // TODO: Revise this to only apply for Senate
    // Combine all third-party candidates (e.g., Green Party,
    // Libertarian party) into a catch-all labeled 'Independent'
    if (!(candParty === "Democrat" || candParty === "Republican")) {
      candParty = "Independent";
    }
    if (!(candParty in locResult.probsByParty)) {
      locResult.probsByParty[candParty] = 0.0;
    }
    // Error-checking, with slight tolerance for round-off
    if (candWinProb < 0.0 || 1.00001 < candWinProb) {
      console.warn("ERROR: Probability " + candWinProb + " out of bounds");
    }
    locResult.probsByParty[candParty] += candWinProb;
  });

  return locResult;
}

function _computePrimaryProbs(
  locResult: any,
  primaryRaces: any,
  runoffRaces: any,
  fTime: any
) {
  // For each primary race, identify the candidates and store their
  // probability of winning in a look-up table
  var nPrimaries = primaryRaces.length;
  for (var i = 0; i < nPrimaries; ++i) {
    var primary = primaryRaces[i];
    // Skip inactive races at this time
    if (!util.isRaceActive(primary, fTime)) {
      locResult.raceProbsByRaceID[primary.id] = null;
      continue;
    }
    // Otherwise race is included in the location results
    locResult.raceProbsByRaceID[primary.id] = 1.0;
    var forecast = util.selectForecastAtTimeX(primary, fTime);

    var candidates = primary.candidates;
    var nCands = candidates.length;
    for (var k = 0; k < nCands; ++k) {
      var candidate = candidates[k];
      if (!util.isCandidateActive(candidate, fTime)) {
        continue;
      }
      var candWinProb = forecast.candRelativeMajorityProbs[candidate.id];
      locResult.primaryProbsByCandID[candidate.id] = candWinProb;
      // Track all candidates by ID for a quick look-up
      locResult.candidatesByID[candidate.id] = candidate;
    }
    // Check for runoff to the race
    if (runoffRaces.length > 0) {
      _checkRunoffs(
        primary,
        forecast,
        runoffRaces,
        fTime,
        locResult.primaryProbsByCandID,
        locResult
      );
    }
  }

  return;
}

function _computeGeneralProbs(
  locResult: any,
  generalRaces: any,
  runoffRaces: any,
  fTime: any
) {
  // For each general race, compute the probability of the race occuring.
  // Also, determine if all general races have finished. A particular
  // race is finished if either of the following conditions are met:
  //  - The race's probability is null
  //  - The forecast used by the race is an actual outcome
  //    AND the race does not have a runoff
  //        OR the race's runoff forecast is an actual outcome
  var allGeneralRacesAreFinished = true;
  var nGeneral = generalRaces.length;
  for (var i = 0; i < nGeneral; ++i) {
    var generalRace = generalRaces[i];
    // Skip inactive races at this time
    if (!util.isRaceActive(generalRace, fTime)) {
      locResult.raceProbsByRaceID[generalRace.id] = null;
      continue;
    }

    var candidates = generalRace.candidates;
    var nCands = candidates.length;
    var forecast = util.selectForecastAtTimeX(generalRace, fTime);
    var candProbsThisRace: any = {};

    // Compute the probability of general election matchup occurring
    var raceProb: number | null = 1.0;
    for (var k = 0; k < nCands; ++k) {
      var candidate = candidates[k];
      // Skip inactive candidates at this time
      if (!util.isCandidateActive(candidate, fTime)) {
        continue;
      }
      // Initialize the candidate's probability of winning this race
      var candWinProb = forecast.candRelativeMajorityProbs[candidate.id];
      candProbsThisRace[candidate.id] = candWinProb;

      // Identify the candidate's probability of winning the primary
      var candPrimaryWinProb = 1.0; // Assume 1.0 by default
      if (candidate.id in locResult.primaryProbsByCandID) {
        candPrimaryWinProb = locResult.primaryProbsByCandID[candidate.id];
        // Will override to null if candidate is eliminated in primary
      }
      // Update the probability of this matchup occurring based on the
      // associated candidates' primary probs
      if (raceProb !== null) {
        if (candPrimaryWinProb !== null) {
          raceProb *= candPrimaryWinProb;
        } else {
          raceProb = null;
        }
      }

      // Track all candidates by ID for a quick look-up
      locResult.candidatesByID[candidate.id] = candidate;
    }
    locResult.raceProbsByRaceID[generalRace.id] = raceProb;

    // Check if the race is finished
    var raceIsFinished = "isActualOutcome" in forecast || raceProb === null;

    // Check for a runoff to this race, and if so, use the runoff
    // forecast to update the candidates' probabilities
    if (runoffRaces.length > 0) {
      // Runoff finished if no runoff or if it uses actual outcome
      var runoffIsFinished = _checkRunoffs(
        generalRace,
        forecast,
        runoffRaces,
        fTime,
        candProbsThisRace,
        locResult
      );
      raceIsFinished = raceIsFinished && runoffIsFinished;
    }

    allGeneralRacesAreFinished = allGeneralRacesAreFinished && raceIsFinished;

    // Finally, update the candidates' overall probabilities, weighted
    // by the probability of this race occurring (only if race can occur)
    if (raceProb !== null) {
      for (var k = 0; k < nCands; ++k) {
        var candidate = candidates[k];
        if (!util.isCandidateActive(candidate, fTime)) {
          continue;
        }
        var candWinProb = candProbsThisRace[candidate.id];
        // candWinProb may be null if a runoff occurs without this
        // candidate in it, so make sure this is not the case
        if (candWinProb !== null) {
          // Ensure that the probability of the candidate winning the
          // location is initialized before incrementing it.
          if (!(candidate.id in locResult.probsByCandID)) {
            locResult.probsByCandID[candidate.id] = 0.0;
          }
          locResult.probsByCandID[candidate.id] += raceProb * candWinProb;
        }
      }
    }
  }
  // Finally, determine whether this location is finished, and if so,
  // identify the race whose totalVotes corresponds to the actual
  // outcome (either general race or a runoff)
  locResult.isFinal = allGeneralRacesAreFinished;
  if (locResult.isFinal) {
    // Only one general race should have a non-null probability
    for (var i = 0; i < nGeneral; ++i) {
      var generalRace = generalRaces[i];
      if (locResult.raceProbsByRaceID[generalRace.id] !== null) {
        locResult.finalRace = generalRace;
      }
    }
    // Check runoff races if there are any
    if (runoffRaces.length > 0) {
      var nRunoffs = runoffRaces.length;
      for (var i = 0; i < nRunoffs; ++i) {
        var runoffRace = runoffRaces[i];
        if (locResult.raceProbsByRaceID[runoffRace.id] !== null) {
          locResult.finalRace = runoffRace;
        }
      }
    }
  }
  return;
}

function _checkRunoffs(
  race: any,
  forecast: any,
  runoffs: any,
  fTime: any,
  candProbs: any,
  locResult: any
) {
  // This method is called whenever runoff races exist in the location.
  // The input forecast is used to determine the top two candidates for a
  // likely runoff. If such a runoff race exists, then we use the runoff
  // probabilities for the candidates instead.

  // First search for an associated runoff with the top two candidates
  // based on the forecast
  var runoff = _identifyAssociatedRunoff(
    race,
    forecast,
    runoffs,
    fTime,
    locResult
  );

  // If no runoff is found, return true indicating that it is finished
  if (runoff.id === race.id) {
    return true;
  }

  // Else runoff is not the same as the race, so update its probability
  // of occurring
  var runoffProb = _computeRunoffProb(race, forecast);
  locResult.raceProbsByRaceID[runoff.id] = runoffProb;

  // If runoff has no chance of occurring, then it is finished
  if (runoffProb === null || runoffProb <= 0.0) {
    return true;
  }
  // NOTE: This will terminate if the forecast is an actual outcome but
  // the vote totals have one candidate with more than 50% of the vote.
  // Thus, the runoff race won't come into play here.

  // Otherwise, update the candidates' probabilities based on whether or
  // not the runoff is guaranteed to occur.
  var candidates = race.candidates;
  var nCands = candidates.length;
  var runoffCands = runoff.candidates;
  var nRunoffCands = runoffCands.length;
  var runoffForecast = util.selectForecastAtTimeX(runoff, fTime);

  if ("isActualOutcome" in forecast) {
    // Set predecessor race candidates' probs to null to prevent them
    // from being carried over if the runoff is known to have occurred.
    for (var k = 0; k < nCands; ++k) {
      var cID = candidates[k].id;
      if (util.isCandidateActive(candidates[k], fTime)) {
        candProbs[cID] = null;
      }
    }
    // Now set the probabilities for the candidates based on the runoff,
    // which has occurred with prob 1.0
    for (var k = 0; k < nRunoffCands; ++k) {
      var cID = runoffCands[k].id;
      if (util.isCandidateActive(runoffCands[k], fTime)) {
        candProbs[cID] = runoffForecast.candRelativeMajorityProbs[cID];
      }
    }
  } else {
    // Each candidate's probability of advancing is initialized to their
    // probability of outright winning the predecessor race
    for (var k = 0; k < nCands; ++k) {
      var cID = candidates[k].id;
      if (util.isCandidateActive(candidates[k], fTime)) {
        candProbs[cID] = forecast.candAbsoluteMajorityProbs[cID];
      }
    }
    // Each candidate's probability of advancing is updated by their
    // probability of winning the runoff, weighted by the probability of
    // the runoff actually occurring
    for (var k = 0; k < nRunoffCands; ++k) {
      var cID = runoffCands[k].id;
      if (util.isCandidateActive(runoffCands[k], fTime)) {
        candProbs[cID] +=
          runoffForecast.candRelativeMajorityProbs[cID] * runoffProb;
      }
    }
  }
  var runoffIsFinished = "isActualOutcome" in runoffForecast;
  return runoffIsFinished;
}

function _identifyAssociatedRunoff(
  race: any,
  forecast: any,
  runoffs: any,
  fTime: any,
  locResult: any
) {
  var raceCandidates = race.candidates;
  var nRaceCands = raceCandidates.length;

  // Sort the active candidates by either votes received or probabilities
  var cands = [];
  for (var k = 0; k < nRaceCands; ++k) {
    var cand = raceCandidates[k];
    if (util.isCandidateActive(cand, fTime)) {
      var candProb = forecast.candRelativeMajorityProbs[cand.id];
      cands.push({
        id: cand.id,
        prob: candProb,
        votes: cand.votes,
      });
    }
  }
  if ("isActualOutcome" in forecast) {
    cands.sort(function (a, b) {
      if (a.votes === b.votes) {
        return 0;
      }
      return a.votes < b.votes ? 1 : -1;
    });
  } else {
    cands.sort(function (a, b) {
      if (a.prob === b.prob) {
        return 0;
      }
      return a.prob < b.prob ? 1 : -1;
    });
  }

  // Identify the top two candidates
  var topCandID = cands[0].id;
  var runnerUpID = cands[1].id;

  var nRunoffs = runoffs.length;
  var bestMatch = null;
  //for (var i = 0; i < nRunoffs; ++i) {
  for (var i = nRunoffs - 1; i >= 0; --i) {
    var runoff = runoffs[i];
    // Skip inactive runoff races
    if (!util.isRaceActive(runoff, fTime)) {
      // Set the runoff prob to null if it is inactive and continue
      locResult.raceProbsByRaceID[runoff.id] = null;
      continue;
    }
    // Skip runoff races that already have a predecessor race
    if (runoff.id in locResult.runoffPredecessors) {
      continue;
    }

    var runoffCandidates = runoff.candidates;
    var nRunoffCands = runoffCandidates.length;
    var nActiveRunoffCands = 0;
    for (var k = 0; k < nRunoffCands; ++k) {
      if (util.isCandidateActive(runoffCandidates[k], fTime)) {
        ++nActiveRunoffCands;
      }
    }
    // Right now we assume all runoffs have two active candidates. If
    // this is not the case, this needs to be updated to handle that.
    if (nActiveRunoffCands != 2) {
      return race;
    }

    // Ensure that the top two candidates exist in this runoff
    var foundTopCand = false;
    var foundRunnerUp = false;
    for (var k = 0; k < nRunoffCands; ++k) {
      var runoffCand = runoffCandidates[k];
      if (!util.isCandidateActive(runoffCand, fTime)) {
        continue;
      }
      if (runoffCand.id == topCandID) {
        foundTopCand = true;
        bestMatch = runoff; // Keep this runoff as the most likely one
      } else if (runoffCand.id == runnerUpID) {
        foundRunnerUp = true;
      }
    }
    if (foundTopCand && foundRunnerUp) {
      locResult.runoffPredecessors[runoff.id] = race.id;
      return runoff;
    }
  }
  // If no runoff race matches perfectly, use the one with the same top
  // candidate (if it exists). Otherwise, return the race itself
  if (bestMatch !== null) {
    locResult.runoffPredecessors[bestMatch.id] = race.id;
    return bestMatch;
  } else {
    return race;
  }
}

function _computeRunoffProb(race: any, forecast: any) {
  if ("isActualOutcome" in forecast) {
    return forecast.isRunoffLikely ? 1.0 : null;
  }
  // Else compute the probability of a runoff as 1.0 minus the
  // probability that some candidate receives more than 50% of the vote
  var runoffProb = 1.0;
  $.each(forecast.candAbsoluteMajorityProbs, function (cID, absMajProb) {
    runoffProb -= absMajProb;
  });
  if (runoffProb < 0) {
    runoffProb = 0.0;
  }
  return runoffProb;
}

/**
 * computeDP
 *
 * Runs through the dynamic programming algorithm for the given races
 * (sorted by location) to determine the overall probability of each
 * party securing each location (which represents a state with electoral
 * votes or a Senate seat).
 *
 * **Returns**
 *
 * A dp object containing the results of the computation.
 *
 * Example:
 *
 * {
 *     parties: ['Democrat', 'Republican']
 *   , totalAvailableValues: 152
 *   , competitiveLocations: ['OH', 'VA', 'WI', ...]
 *   , info: {
 *         Democrat: {
 *             baseValue: 216
 *           , valueDistribution: [0.0, 0.02, 0.11, 0.3, 0.0, ...]
 *         }
 *       , Republican: {
 *             baseValue: 170
 *           , valueDistribution: [0.0, 0.01, 0.15, 0.0, 0.0, ...]
 *         }
 *     }
 * }
 *
 */
export function computeDP(dataConfig: any, racesByLocation: any) {
  var cacheLabel = util.generateFullCacheLabel("DP", dataConfig);
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else we need to compute the DP from scratch
  // Start by computing the results for each location
  var locResults = computeLocationResults(dataConfig, racesByLocation);

  var dp: any = {
    parties: [],
    totalAvailableValues: 0,
    competitiveLocations: [],
    info: {},
    isFinal: false, // Set if all locResults are final
  };

  // Filter locations into competitive and non-competitive
  var allLocationsFinished = true;
  $.each(locResults, function (locAbbrev, locResult) {
    allLocationsFinished = allLocationsFinished && locResult.isFinal;
    var clearWinner = false;
    $.each(locResult.probsByParty, function (party, partyProb) {
      // Set up info for each party if this is the first location where
      // that party appears in a race
      if (!_.contains(dp.parties, party)) {
        dp.parties.push(party);
        dp.info[party] = {
          baseValue: 0,
          valueDistribution: [],
        };
      }
      if (partyProb > 1.0 - 0.00005) {
        clearWinner = true;
        dp.info[party].baseValue += locResult.value;
      }
    });
    if (!clearWinner) {
      // The location is up for grabs
      dp.totalAvailableValues += locResult.value;
      dp.competitiveLocations.push(locAbbrev);
    }
  });
  dp.isFinal = allLocationsFinished;

  // Ensure that the three main parties exist in the DP structure
  // TODO: Figure out a better way to initialize and handle corner cases
  var mainParties = ["Democrat", "Independent", "Republican"];
  for (var i = 0; i < mainParties.length; ++i) {
    var mainParty = mainParties[i];
    if (!_.contains(dp.parties, mainParty)) {
      dp.parties.push(mainParty);
      dp.info[mainParty] = {
        baseValue: 0,
        valueDistribution: [],
      };
    }
  }

  // Run the actual dynamic programming algorithm
  _runDP(dp, locResults);

  // Add coalitions as needed
  if (dataConfig.type === "senate") {
    _addCoalitionToDP(dp, "DemInd", ["Democrat", "Independent"], "Republican");
  }

  // Handle corner cases here
  // TODO: Devise a better solution to this...
  if (
    dataConfig.type === "president" &&
    dataConfig.year == 2008 &&
    dp.isFinal
  ) {
    dp.totalAvailableValues = 0;
    dp.competitiveLocations = [];
    dp.info.Democrat.baseValue = 365;
    dp.info.Democrat.valueDistribution = [1.0];
    dp.info.Republican.baseValue = 173;
    dp.info.Republican.valueDistribution = [1.0];
  }

  // Now store the results and return them
  cache.set(cacheLabel, dp);
  return dp;
}

function _runDP(dp: any, locResults: any): any {
  // Early termination check if there are no competitive locations
  var nParties = dp.parties.length;
  var nCompetitiveLocations = dp.competitiveLocations.length;
  if (nCompetitiveLocations === 0) {
    // Each party has a 100% chance of 0 gain
    for (var i = 0; i < nParties; ++i) {
      dp.info[dp.parties[i]].valueDistribution.push(1.0);
    }
    return;
  }

  // Otherwise, start by initializing the DP columns for each party
  for (var i = 0; i < nParties; ++i) {
    for (var val = 0; val <= dp.totalAvailableValues; ++val) {
      dp.info[dp.parties[i]].valueDistribution.push(0.0);
    }
  }

  // Handle the base case for initializing the DP table. Done separately
  // in order to initialize the first DP column, which would be all zeros
  // otherwise.
  var firstLoc = dp.competitiveLocations[0];
  var firstLocResult = locResults[firstLoc];
  var firstLocVal = firstLocResult.value;
  for (var i = 0; i < nParties; ++i) {
    var party = dp.parties[i];
    var partyWinProb = 0.0;
    var valueDistribution = dp.info[party].valueDistribution;
    if (party in firstLocResult.probsByParty) {
      partyWinProb = firstLocResult.probsByParty[party];
    }
    valueDistribution[0] = 1.0 - partyWinProb;
    valueDistribution[firstLocVal] = partyWinProb;
  }

  // Run through the DP for the remaining locations
  for (var l = 1; l < nCompetitiveLocations; ++l) {
    var locAbbrev = dp.competitiveLocations[l];
    var locResult = locResults[locAbbrev];
    var locVal = locResult.value;
    for (var i = 0; i < nParties; ++i) {
      var party = dp.parties[i];
      var pWinProb = 0.0;
      if (party in locResult.probsByParty) {
        pWinProb = locResult.probsByParty[party];
      }
      var vTotal = dp.totalAvailableValues;
      var valueDistribution = dp.info[party].valueDistribution;
      // Update the DP column for this party. By going in reverse, we
      // can save some space.
      for (var v = vTotal; v >= 0; --v) {
        var vProb;
        if (v >= locVal) {
          vProb =
            (1.0 - pWinProb) * valueDistribution[v] +
            pWinProb * valueDistribution[v - locVal];
        } else {
          // Must lose the location because winning it would push the
          // party over the current total value
          vProb = (1.0 - pWinProb) * valueDistribution[v];
        }
        valueDistribution[v] = vProb;
      }
    }
  }
  //console.log("Finished computing the DP");
  return;
}

function _addCoalitionToDP(
  dp: any,
  coalition: any,
  partiesInCoalition: any,
  opposite: any
): void {
  dp.parties.push(coalition);
  dp.info[coalition] = {
    baseValue: 0,
    valueDistribution: [],
  };
  // Initialize the base values for the coalition as the sum of the base
  // values for all parties in the coalition
  var nPartiesInCoalition = partiesInCoalition.length;
  for (var i = 0; i < nPartiesInCoalition; ++i) {
    var party = partiesInCoalition[i];
    if (party in dp.info) {
      dp.info[coalition].baseValue += dp.info[party].baseValue;
    }
  }
  // Now initialize the value distribution for the party
  var coalitionDist = dp.info[coalition].valueDistribution;
  var oppositeDist = dp.info[opposite].valueDistribution;
  var n = oppositeDist.length;
  for (var i = n - 1; i >= 0; --i) {
    coalitionDist.push(oppositeDist[i]);
  }
  return;
}

/**
 * computeStats
 *
 * Uses the results from computeDP to determine expected values and
 * other stats across all races of a given type and year.
 *
 * **Returns**
 *
 * Statistics about the current election in aggregate.
 *
 * Example:
 *
 * {
 *     parties: ['Democrat', 'Republican', 'DemInd']
 *   , potentialAvailableValues: 538
 *   , info: {
 *         Democrat: {
 *             expected: 351.48785
 *           , safe: 332
 *           , pHalf: 0.0     (or null if event is impossible)
 *           , pWin: 0.631    (or null if event is impossible)
 *           , pSuper: 0.22   (or null if event is impossible)
 *           , expGain: 135.48785 (should equal expected - base)
 *           , stddev: 17.10
 *         }
 *       , Republican: { ... }  (same stats as above)
 *       , DemInd: { ... }      (same stats as above)
 *     }
 * }
 *
 */
export function computeStats(
  dataConfig: DataConfig,
  racesByLocation: any
): any {
  var cacheLabel = util.generateFullCacheLabel("stats", dataConfig);
  var cacheHit = cache.get(cacheLabel);
  if (cacheHit) {
    return cacheHit;
  }
  // Else we need to compute the stats from scratch
  // Start by computing the results for each location and the DP
  var locResults = computeLocationResults(dataConfig, racesByLocation);
  var dp = computeDP(dataConfig, racesByLocation);
  // First initialize the stats object
  var stats: any = {
    parties: [],
    potentiallyAvailableValues: election.control[dataConfig.type].half * 2,
    // May need to change the above at some point...
    info: {},
    isFinal: false, // Set if all locResults are final
  };

  // Set the stats for each party in the DP
  var nParties = dp.parties.length;
  for (var i = 0; i < nParties; ++i) {
    var party = dp.parties[i];
    stats.parties.push(party);
    stats.info[party] = {
      expected: 0.0,
      safe: 0,
      pHalf: 0.0,
      pWin: 0.0,
      pSuper: 0.0,
      expGain: 0.0,
      stddev: 0.0,
      notUp: 0,
      valuesWon: 0,
    };
  }

  // Perform remainder of initialization, with single parties first and
  // coalitions on an as-needed basis
  _initializePartyStats(dataConfig, stats, locResults);
  if (_.contains(stats.parties, "DemInd")) {
    _initializeCoalitionStats(dataConfig, stats, locResults, "DemInd", [
      "Democrat",
      "Independent",
    ]);
  }

  _checkGuaranteedEvents(dataConfig, stats, dp);
  if (dp.totalAvailableValues > 0) {
    _checkUncertainEvents(dataConfig, stats, dp);
  }

  // Now store the results and return them
  cache.set(cacheLabel, stats);
  return stats;
}

function _initializePartyStats(dataConfig: any, stats: any, locResults: any) {
  // If this is a Senate race, adjust the values by seats not up
  if (dataConfig.type === "senate") {
    var senateSeatsNotUp = election.senateSeatsNotUp[dataConfig.year];
    $.each(senateSeatsNotUp, function (party, partySeatsNotUp) {
      stats.potentiallyAvailableValues -= partySeatsNotUp;
      if (_.contains(stats.parties, party)) {
        stats.info[party].expected += partySeatsNotUp;
        //stats.info[party].safe += partySeatsNotUp;
        stats.info[party].notUp = partySeatsNotUp;
      }
    });
  }

  // Compute expected and safe votes across all of the locations for
  // single parties based on aggregate probabilities in locResult
  var allLocationsFinished = true;
  $.each(locResults, function (locAbbrev, locResult) {
    // Note if the location is finished or not
    allLocationsFinished = allLocationsFinished && locResult.isFinal;
    $.each(locResult.probsByParty, function (party, totalWinProb) {
      // We know (party in stats.parties) because (party in dp.parties)
      // when any locResult has a probability for that party
      stats.info[party].expected += totalWinProb * locResult.value;
      if (totalWinProb >= 0.85) {
        stats.info[party].safe += locResult.value;
        // If the location outcome is already known, update the
        // potentially available count here.
        if (locResult.isFinal) {
          stats.potentiallyAvailableValues -= locResult.value;
          stats.info[party].valuesWon += locResult.value;
        }
      }
    });
  });
  stats.isFinal = allLocationsFinished;
  // We always have the following:
  //    dp.totalAvailableValues <= stats.potentiallyAvailableValues
  // This is because the dp ignores locations that have prob 1.0 for any
  // party (or locations that have already finished). However, to
  // determine impossible events, we need to distinguish between these
  // two possibilities.
  return;
}

/**
 * Handle coalitions separately to keep the code simple (relatively)
 * @param dataConfig
 * @param stats
 * @param locResults
 * @param coalition
 * @param partiesInCoalition
 */
function _initializeCoalitionStats(
  dataConfig: any,
  stats: any,
  locResults: any,
  coalition: any,
  partiesInCoalition: any
) {
  var coalitionSeatsNotUp = 0;
  var coalitionSeatsWon = 0;
  var nPartiesInCoalition = partiesInCoalition.length;
  for (var i = 0; i < nPartiesInCoalition; ++i) {
    var party = partiesInCoalition[i];
    coalitionSeatsNotUp += stats.info[party].notUp;
    coalitionSeatsWon += stats.info[party].valuesWon;
  }
  stats.info[coalition].expected += coalitionSeatsNotUp;
  //stats.info[coalition].safe += coalitionSeatsNotUp;
  stats.info[coalition].notUp = coalitionSeatsNotUp;
  stats.info[coalition].valuesWon = coalitionSeatsWon;

  // Update expected and safe votes for coalition across all locations
  $.each(locResults, function (locAbbrev, locResult) {
    var coalitionWinProb = 0.0;
    for (var i = 0; i < nPartiesInCoalition; ++i) {
      var party = partiesInCoalition[i];
      if (party in locResult.probsByParty) {
        coalitionWinProb += locResult.probsByParty[party];
      }
    }
    stats.info[coalition].expected += coalitionWinProb * locResult.value;
    if (coalitionWinProb >= 0.85) {
      stats.info[coalition].safe += locResult.value;
    }
  });

  return;
}

function _checkGuaranteedEvents(
  dataConfig: WithElectionType,
  stats: any,
  dp: any
): void {
  // Check if any candidate / party already has a majority, or if it is
  // impossible for the party to reach a majority / super-majority
  var controlVals = election.control[dataConfig.type];
  var nParties = stats.parties.length;
  var potentialAvail = stats.potentiallyAvailableValues;
  var actualAvail = dp.totalAvailableValues;
  for (var i = 0; i < nParties; ++i) {
    var party = stats.parties[i];
    var partyNotUp = stats.info[party].notUp;
    var partyWon = stats.info[party].valuesWon;
    var partyDPBase = dp.info[party].baseValue;
    var partySecure = partyNotUp + partyDPBase; // Held w/ high prob
    var partyAchieved = partyNotUp + partyWon; // Guaranteed (known)
    // NOTE: partyAchieved <= partySecure always
    var valueDist = dp.info[party].valueDistribution;
    if (partySecure >= controlVals.majority) {
      stats.info[party].pWin = 1.0;
      if (partySecure >= controlVals.superMajority) {
        stats.info[party].pSuper = 1.0;
      }
    } else if (partySecure + actualAvail >= controlVals.half) {
      stats.info[party].pHalf = valueDist[controlVals.half - partySecure];
    } // else party can't get to half, so leave probs at 0

    // Check for impossible events
    if (partyAchieved + potentialAvail < controlVals.superMajority) {
      // => partySecure + actualAvail < controlVals.superMajority
      stats.info[party].pSuper = null;
      if (partyAchieved + potentialAvail < controlVals.majority) {
        // => partySecure + actualAvail < controlVals.majority
        stats.info[party].pWin = null;
        if (partyAchieved + potentialAvail < controlVals.half) {
          // => partySecure + actualAvail < controlVals.half
          stats.info[party].pHalf = null;
        }
      }
    }
    // Need to handle the tie case separately
    if (partyAchieved > controlVals.half) {
      stats.info[party].pHalf = null;
    }
  }
  return;
}

function _checkUncertainEvents(
  dataConfig: WithElectionType,
  stats: any,
  dp: any
): void {
  var controlVals = election.control[dataConfig.type];
  var nParties = stats.parties.length;
  var vTotal = dp.totalAvailableValues;
  for (var i = 0; i < nParties; ++i) {
    var party = stats.parties[i];
    var valueDistribution = dp.info[party].valueDistribution;
    for (var v = 0; v <= vTotal; ++v) {
      stats.info[party].expGain += v * valueDistribution[v];
      stats.info[party].stddev += v * v * valueDistribution[v];
    }
    var mean = stats.info[party].expGain;
    stats.info[party].stddev -= mean * mean;
    if (stats.info[party].stddev >= 0.0) {
      stats.info[party].stddev = Math.sqrt(stats.info[party].stddev);
    } else if (stats.info[party].stddev >= -0.001) {
      stats.info[party].stddev = 0.0;
    } else {
      console.warn("ERROR: Stddev is " + stats.info[party].stddev);
    }

    // Now compute the probability of getting over certain amounts
    var partySecure = stats.info[party].notUp + dp.info[party].baseValue;

    for (
      var v = controlVals.majority - partySecure;
      0 <= v && v <= vTotal;
      ++v
    ) {
      stats.info[party].pWin += valueDistribution[v];
    }
    for (
      var v = controlVals.superMajority - partySecure;
      0 <= v && v <= vTotal;
      ++v
    ) {
      stats.info[party].pSuper += valueDistribution[v];
    }
  }
  return;
}
