import React, { HTMLProps } from "react";
import { css } from "@emotion/core";
import { bottom } from "@popperjs/core";

export interface TableRowProps extends HTMLProps<HTMLTableRowElement> {
  topBordered?: boolean;
  bottomBordered?: boolean;
}

function TableRow({
  children,
  topBordered = true,
  bottomBordered = false,
  ...others
}: TableRowProps): JSX.Element {
  return (
    <tr
      css={css`
        border-top: ${topBordered ? "0.7px" : "0px"} solid #d3d3d3;
        border-bottom: ${bottomBordered ? "0.7px" : "0px"} solid #d3d3d3;
      `}
      {...others}
    >
      {children}
    </tr>
  );
}

export default TableRow;
