const jstz = require("jstz");
import _ from "underscore";

export type PhpDateTimeClassification = "yes" | "no" | "maybe";

export interface IPhpDateTime {
  date: any;
  timezone: any;
  timezone_type: any;
}

const localTimezone: string = jstz.determine().name();
const phpDateTimeFieldIndicators: string[] = [
  "date",
  "timezone",
  "timezone_type",
];

/**
 * Get local timezone
 *
 * @returns a string representing local time zone
 */
export function getLocalTimezone(): string {
  return localTimezone;
}

export function convertPhpDateTimes(obj: any): any {
  // TODO: Optionally use a recursive-starter method to make a deep clone
  //       of the original obj, so that we don't modify it but rather
  //       return a new, converted object?
  switch (isPhpDateTime(obj)) {
    case "yes":
      obj = moment.tz(obj.date, obj.timezone).tz(localTimezone);
      break;

    case "no":
      if (_.isObject(obj)) {
        _.each(obj, (val, key, obj) => {
          obj[key] = convertPhpDateTimes(val);
        });
      }
      break;

    default:
    case "maybe":
      console.warn(
        "ea.service.time.convertPhpDateTimes recieved an object that might be a PHP DateTime: it has the necessary fields but also contains other properties.  Leaving this one as is."
      );
      break;
  }

  return obj;
}

/**
 * Determine if an object is php date time
 * @param obj some object
 */
export function isPhpDateTime(
  obj: any | IPhpDateTime
): PhpDateTimeClassification {
  // precondition: obj is an object
  if (!_.isObject(obj)) {
    return "no";
  }

  let classification: PhpDateTimeClassification;
  let keys = _.keys(obj);
  let intersection = _.intersection(keys, phpDateTimeFieldIndicators);

  if (intersection.length === phpDateTimeFieldIndicators.length) {
    if (keys.length === phpDateTimeFieldIndicators.length) {
      classification = "yes";
    } else {
      // The object has all the PhpDateTimeFieldIndicators,
      // but also other properties.
      classification = "maybe";
    }
  } else {
    classification = "no";
  }

  return classification;
}
