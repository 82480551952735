import React from "react";
import { css } from "@emotion/core";
import Tippy from "@tippyjs/react";
import * as math from "../../../services/math";

export interface BlockContent {
  width: number;
  electoralVotes?: string;
  background: string;
  name: string;
  demProb: number;
  repProb: number;
  demColor: string;
  repColor: string;
  demName: string | null;
  repName: string | null;
}

export interface BlockProps extends BlockContent {
  target: any;
}

function Tooltip(props: BlockContent): JSX.Element {
  let demFlexGrow = props.demProb;
  let repFlexGrow = props.repProb;

  if (props.demProb === props.repProb) {
    demFlexGrow = 1;
    repFlexGrow = 1;
  }

  return (
    <div
      css={css`
        width: 215px;
        text-align: left;
        font-size: 14px;
      `}
    >
      {/* State */}
      <div
        css={css`
          font-weight: bold;
        `}
      >
        <span>
          {props.name}
          {props.electoralVotes}
        </span>
      </div>
      {/* Candidates & Probs */}
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          css={css`
            text-align: left;
          `}
        >
          <div>{props.demName ? props.demName : "Democrats"}</div>
          <div>{math.formatProbability(props.demProb)}</div>
        </div>

        <span
          css={css`
            flex-grow: 1;
          `}
        ></span>
        <div
          css={css`
            text-align: right;
          `}
        >
          <div>{props.repName ? props.repName : "Republicans"}</div>
          <div>{math.formatProbability(props.repProb)}</div>
        </div>
      </div>
      {/* Bar */}
      <div
        css={css`
          height: 10px;
          margin-top: 8px;
          background: white;
          display: flex;
        `}
      >
        <div
          style={{ flexGrow: demFlexGrow, background: props.demColor }}
        ></div>
        <div
          style={{ flexGrow: repFlexGrow, background: props.repColor }}
        ></div>
      </div>
    </div>
  );
}

function Block(props: BlockProps): JSX.Element {
  const { target, ...others } = props;

  return (
    <Tippy
      content={<Tooltip {...others}></Tooltip>}
      interactive
      singleton={target}
    >
      <div
        css={css`
          height: 100%;
          display: block;
          cursor: crosshair;
          background: black;
          position: relative;
        `}
        style={{ flexGrow: props.width }}
      >
        <div
          css={css`
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;

            :hover {
              left: 1px;
              right: 1px;
              top: 1px;
              bottom: 1px;
            }
          `}
          style={{ background: props.background }}
        ></div>
      </div>
    </Tippy>
  );
}

export default Block;
