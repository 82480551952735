import React, { PureComponent, ReactNode } from "react";
import { css } from "@emotion/core";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import TableRow from "../TableRow";
import TableDataCell from "../TableDataCell";

export interface RowProps {
  title: string;
  blue: string;
  red: string;
  tooltip: JSX.Element;
}

class Row extends PureComponent<RowProps> {
  static defaultProps = {
    title: "Wierd",
    blue: "x",
    red: "x",
    tooltip: <span>A wierd tip</span>,
  };

  constructor(props: any) {
    super(props);
  }

  public render(): ReactNode {
    const { title, blue, red, tooltip } = this.props;

    return (
      <TableRow>
        <TableDataCell
          css={css`
            text-align: left;
          `}
          variant="text"
          leftBordered={false}
        >
          <Tippy
            theme="light-border"
            placement="right"
            trigger="click"
            content={tooltip}
          >
            <span
              css={css`
                font-weight: bold;

                :hover {
                  text-decoration: underline;
                  cursor: pointer;
                }
              `}
            >
              {title}
            </span>
          </Tippy>
        </TableDataCell>
        <TableDataCell
          css={css`
            color: #318ce7;
            text-align: center;
          `}
        >
          <span>{blue}</span>
        </TableDataCell>
        <TableDataCell
          css={css`
            color: #f64a41;
            text-align: center;
          `}
          rightBordered={false}
        >
          <span>{red}</span>
        </TableDataCell>
      </TableRow>
    );
  }
}

export default Row;
