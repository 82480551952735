import React, { HTMLProps } from "react";

function TableHead({
  children,
  ...others
}: HTMLProps<HTMLTableSectionElement>): JSX.Element {
  return <thead {...others}>{children}</thead>;
}

export default TableHead;
