/* Component: calendar
 * =============================================================================
 *
 * The calendar component allows the user to select a date.
 *
 * ** Component Options
 *    days     : an array of days to be selectable in the calendar
 *    selected : the day currently selected on the calendar
 *
 * ** Instance Variables
 *    _$element  : The jquery object this component is called on.
 *    _picker    : The datepicker component to use.
 *    _selected  : The selected date.
 *    _isVisible : If the calendar is currently visible.
 *
 */

import datepicker from "js-datepicker";
import _ from "underscore";

export class Calendar {
  /* Object Definition + Constructor
   * ------------------------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-cpnt-calendar");

    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    [].forEach((option) => {
      if (!(option in options)) {
        this.error(`constructor: no option '${option}'`);
        return;
      }
    });

    this._initializeHtml(options.onSelect);
    this._initializePicker(options.onSelect);
    if ("days" in options)
      this._setBounds(options.days.sort((a, b) => a.diff(b)));
    if ("selected" in options) this.selectDate(options.selected);
  } // end constructor

  /* _initializeHtml
   * ---------------------------------------------------------------------- */
  _initializeHtml(onSelectPrm) {
    const $calendar = $(`
        <div class="ea-cpnt-calendar-dateBox">
          <svg class="ea-cpnt-calendar-dateBox-icon" width="1.25em"
               height="1.25em" viewBox="0 0 16 16" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M14 0H2a2 2 0 00-2 2v12a2 2 0
                                         002 2h12a2 2 0 002-2V2a2 2 0
                                         00-2-2zM1 3.857C1 3.384 1.448
                                         3 2 3h12c.552 0 1 .384 1
                                         .857v10.286c0 .473-.448.857-1
                                         .857H2c-.552
                                         0-1-.384-1-.857V3.857z"
                  clip-rule="evenodd"/>
            <path fill-rule="evenodd" d="M6.5 7a1 1 0 100-2 1 1 0 000 2zm3
                                         0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1
                                         0 100-2 1 1 0 000 2zm-9 3a1 1 0
                                         100-2 1 1 0 000 2zm3 0a1 1 0 100-2
                                         1 1 0 000 2zm3 0a1 1 0 100-2 1 1 0
                                         000 2zm3 0a1 1 0 100-2 1 1 0 000
                                         2zm-9 3a1 1 0 100-2 1 1 0 000 2zm3
                                         0a1 1 0 100-2 1 1 0 000 2zm3 0a1 1
                                         0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"/>
          </svg
          ><div class="ea-cpnt-calendar-dateBox-text">
            <div class="ea-cpnt-calendar-dateBox-text-text"></div>
            <div class="ea-cpnt-calendar-dateBox-text-width">May 29, 2020</div>
          </div>
          <svg class="ea-cpnt-calendar-dateBox-step-right" width="1.25em"
               height="1.25em" focusable="false"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6
                     6-6 6-1.41-1.41z"></path>
          </svg
          ><svg class="ea-cpnt-calendar-dateBox-step-left" focusable="false"
                width="1.25em" height="1.25em"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14
                     6l-6 6 6 6 1.41-1.41z"></path>
          </svg>
        </div>
      `);

    $calendar
      .find(
        `.ea-cpnt-calendar-dateBox-step-left,
                      .ea-cpnt-calendar-dateBox-step-right`
      )
      .on(
        "click",
        function (e) {
          e.stopPropagation();
          const isLeft = $(e.currentTarget).hasClass(
            "ea-cpnt-calendar-dateBox-step-left"
          );
          const dayInc = isLeft ? -1 : 1;
          const minDate = moment(this._picker.minDate);
          const maxDate = moment(this._picker.maxDate);
          let newDate = moment(this._selected);
          do {
            newDate = newDate.add(dayInc, "days");
          } while (
            newDate.isBefore(maxDate, "day") &&
            newDate.isAfter(minDate, "day") &&
            this._picker.disabler(newDate) == true
          );
          if (this._picker.disabler(newDate) == true) return;
          this.selectDate(newDate);
          if (onSelectPrm !== undefined) onSelectPrm(this, newDate);
        }.bind(this)
      );

    this._$element.append($calendar);
  } // end _initializeHtml

  /* _positionCalendar
   * ---------------------------------------------------------------------- */
  _positionCalendar() {
    const $calendar = this._$element.find(".ea-cpnt-calendar-dateBox");
    const cc = this._picker.calendarContainer;

    cc.style.setProperty("top", `${$calendar.height() + 12}px`);

    const w1 = $calendar.innerWidth();
    const w2 = cc.clientWidth;
    cc.style.setProperty("left", `${(w1 - w2) / 2}px`);

    cc.classList.remove("ea-cpnt-calendar-datePicker-left");
    cc.classList.remove("ea-cpnt-calendar-datePicker-right");

    const $pointer = this._$element.find();
    const rect = cc.getBoundingClientRect();
    if (rect.left <= 0) {
      cc.style.setProperty("right", `auto`);
      cc.style.setProperty("left", `-1px`);
      cc.classList.add("ea-cpnt-calendar-datePicker-left");
    } else if (rect.right >= window.innerWidth) {
      cc.style.setProperty("left", `auto`);
      cc.style.setProperty("right", `-1px`);
      cc.classList.add("ea-cpnt-calendar-datePicker-right");
    }
  } // end _positionCalendar

  /* _initializePicker
   * ---------------------------------------------------------------------- */
  _initializePicker(onSelectPrm) {
    const $calendar = this._$element.find(".ea-cpnt-calendar-dateBox");
    this._picker = datepicker($calendar[0], {
      customDays: ["S", "M", "T", "W", "T", "F", "S"],
      disableYearOverlay: true,
      onShow: function (instance) {
        this._isVisible = true;
        $calendar.addClass("ea-cpnt-calendar-dateBox-selected");
        this._positionCalendar();
      }.bind(this),
      onHide: function (instance) {
        this._isVisible = false;
        $calendar.removeClass("ea-cpnt-calendar-dateBox-selected");
      }.bind(this),
      onSelect: function (instance, date) {
        if (date !== undefined) this._selected = moment(date);
        this.selectDate(this._selected);
        if (date !== undefined)
          if (onSelectPrm !== undefined) onSelectPrm(this, date);
      }.bind(this),
    });

    this._picker.calendarContainer.classList.add("ea-cpnt-calendar-datePicker");

    $(window).resize(() => {
      if (this._isVisible) this._positionCalendar();
    });

    this._isVisible = false;
  } // end _initializePicker

  /* _setBounds
   * ---------------------------------------------------------------------- */
  _setBounds(days) {
    this._picker.setDate();
    this._picker.setMin();
    this._picker.setMax();
    this._picker.setMin(days[0].toDate());
    this._picker.setMax(days[days.length - 1].toDate());
    this._picker.disabler = function (date) {
      if (this.bSearch(days, date) === null) return true;
      return false;
    }.bind(this);
  } // end _setBounds

  /* setBounds
   * ---------------------------------------------------------------------- */
  setBounds(days) {
    this._setBounds(days.sort((a, b) => a.diff(b)));
  } // end setBounds

  /* selectDate
   * ---------------------------------------------------------------------- */
  selectDate(date) {
    this._picker.setDate(date.toDate(), true);
    this._$element
      .find(".ea-cpnt-calendar-dateBox-text-text")
      .text(date.format("MMM D, YYYY"));
    this._selected = date;
    this._updateDateSteps();
  } // end setBounds

  /* _bSearch
   * ---------------------------------------------------------------------- */
  _bSearch(arr, x, start, end) {
    if (start > end) return null;
    let mid = Math.floor((start + end) / 2);
    if (arr[mid].isAfter(x, "day"))
      return this._bSearch(arr, x, start, mid - 1);
    else if (arr[mid].isBefore(x, "day"))
      return this._bSearch(arr, x, mid + 1, end);
    else return mid;
  } // end _bSearch

  /* bSearch
   * ---------------------------------------------------------------------- */
  bSearch(arr, x) {
    return this._bSearch(arr, x, 0, arr.length - 1);
  } // end bSearch

  /* _updateDateSteps
   * ---------------------------------------------------------------------- */
  _updateDateSteps() {
    this._$element
      .find(".ea-cpnt-calendar-dateBox-step-left")
      .addClass("ea-cpnt-calendar-dateBox-disabled");
    this._$element
      .find(".ea-cpnt-calendar-dateBox-step-right")
      .addClass("ea-cpnt-calendar-dateBox-disabled");

    const minDate = moment(this._picker.minDate);
    const maxDate = moment(this._picker.maxDate);
    if (this._selected.isAfter(minDate, "day"))
      this._$element
        .find(".ea-cpnt-calendar-dateBox-step-left")
        .removeClass("ea-cpnt-calendar-dateBox-disabled");
    if (this._selected.isBefore(maxDate, "day"))
      this._$element
        .find(".ea-cpnt-calendar-dateBox-step-right")
        .removeClass("ea-cpnt-calendar-dateBox-disabled");
  } // end _updateDateSteps

  /* disable
   * ---------------------------------------------------------------------- */
  disable() {
    this._picker.hide();
    this._$element.addClass("ea-cpnt-calendar-disabled");
  } // end disable

  /* enable
   * ---------------------------------------------------------------------- */
  enable() {
    this._$element.removeClass("ea-cpnt-calendar-disabled");
  } // end enable

  /* error
   * ---------------------------------------------------------------------- */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(`
        <div class="alert alert-error">
          Something seems to have gone wrong with our calendar component.
          We'll look into the issue shortly. Perhaps a refresh would help?
        </div>
      `);
    this._$element.append($errorMsg);

    console.error(`An error occured in a calendar component (${type})`);
  } // end error
} // end Calendar prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaComponentCalendar = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var selectObjs = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    selectObjs.push(new Calendar($element, myOptions));
  });

  return selectObjs;
};

// Autoloader
$(function () {
  $(".ea-component-calendar").eaComponentCalendar();
});
