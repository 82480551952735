/**
 * @file Math service
 */

/**
 * Check for equality with roundoff tolerance ###
 *
 * @param valA number one to compare
 * @param valB number two to compare
 *
 * @returns whether or not the numbers are equal within tolerance
 */
export function approxEqual(valA: number, valB: number): boolean {
  return Math.abs(valA - valB) <= 0.0001;
}

/**
 * Trim Decimal ###
 *
 * @param num a number
 * @param nDP a number of decimal points
 *
 * @returns that number, trimmed to the given number of decimal points
 */
export function nformat(num: number, nDP: number): number {
  // precondition: nDP is valid
  if (nDP < 0) {
    throw "window.ea.service.math.nformat Invalid Parameter: nDP==" + nDP;
  }

  var shift = Math.pow(10, nDP);
  return Math.round(shift * num) / shift;
}

/**
 * Trim Decimal and Padd ###
 *
 * @param num a number
 * @param nDP a number of decimal points
 *
 * @returns that number, trimmed to the given number of decimal points and
 * padded with additional zeros if necessary
 *
 */
export function sformat(num: number, nDP: number): string {
  let str = "" + nformat(num, nDP);

  if (nDP > 0) {
    let dInd = str.indexOf(".");
    if (dInd == -1) {
      str += ".";
      dInd = str.length - 1;
    }

    while (str.length - dInd <= nDP) {
      str += "0";
    }
  }

  return str;
}

/**
 * Convert Decimal to Percent ###
 *
 * @param prob a probability [0.0 - 1.0]
 * @param nDP a number of decimal places
 *
 * @returns that probability in a percent form, trimmed to the given
 * number of decimal points and padded with additional zeros
 * if necessary
 */
export function percentFormat(prob: number, nDP: number): string {
  var percent = prob * 100;
  var str = nformat(percent, nDP);
  return str + "%";
}

/**
 * Format Probability ###
 *
 * @param p a probability [0.0 - 1.0]
 *
 * @returns that probability in a percent form, with one decimal
 * point and never allowing complete certainty
 */
export function formatProbability(p: number): string {
  const numberProb = Math.round(p * 1000) / 10;
  if (numberProb > 99.9) return `>99.9%`;
  else if (numberProb < 0.1) return `<0.1%`;
  return `${numberProb.toFixed(1)}%`;
}

/**
 * Insert commas into a (large) integer number ###
 *
 * @parma bigVal an integer
 *
 * @returns that integer as a string with commas every three digits
 */
export function commaFormat(bigVal: number): string {
  var str = sformat(bigVal, 0);
  var buffer = [];
  var len = str.length;
  while (len >= 3) {
    buffer.push(str.substr(len - 3, 3));
    len -= 3;
  }
  if (len > 0) {
    buffer.push(str.substr(0, len));
  }
  return buffer.reverse().join(",");
}
