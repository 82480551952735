import React, { HTMLProps, useState } from "react";
import { css } from "@emotion/core";

export interface CollapsePanelProps
  extends Omit<HTMLProps<HTMLDivElement>, "title"> {
  title: JSX.Element | string;
}

function CollapsePanel({
  title,
  children,
  ...others
}: CollapsePanelProps): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <div
      aria-multiselectable="true"
      className="panel panel-default ea-prevres-tab"
      {...others}
    >
      <div
        className="panel-heading panel-heading-custom panel-heading-bkcolor"
        css={css`
          cursor: pointer;
        `}
        id="headingFive"
        data-toggle="collapse"
        aria-expanded="false"
        aria-controls="collapseFive"
        onClick={() => setOpen(!open)}
      >
        {title}
      </div>
      {open ? children : null}
    </div>
  );
}

export default CollapsePanel;
