/* Module: Election Selector
 * ==========================================================================
 *
 * The election-selector module modifies the elections that are being
 * displayed. It can select new race types (presidential, senate), race years,
 * and forecasts.
 *
 * This module is designed to be used in conjunction with other modules;
 * a page's controller should respond to the events the Election Selector emits
 * and update the other modules. Specifically, when the selector element is
 * created on a page, an event handler should be attached that tells other
 * modules to update themselves with new options based on what is now
 * selected.
 *
 * Usage examples available at:
 *
 *     site/dynamic/template/module/election-selector.twig
 *
 * ** dataConfig options
 *   - `type`: [required on init] The type of race.
 *   - `year`: [required on init] The year of the election cycle.
 *
 * NOTE: See the `createDataConfigFromOptions` function in `util.service.js`
 *       for more information on the supported dataConfig options.
 *
 * ** moduleConfig options
 *  - N/A
 *
 * NOTE: To specify options in HTML, it is necessary to convert camelCase
 *       options to lowercase words separated by dashes. E.g.,
 *          `type`      => `data-type`
 *          `showTitle` => `data-show-title`
 *
 *
 * ** Instance variables
 *   - `_dataConfig`:   Stores info about data being displayed
 *   - `_races`:        All Races of `type` in `year`.
 *   - `_forecastTimes`:Times for Forecasts, across all Races. These times are
 *                      determined solely by type-year combination;
 *                      customization options are omitted.
 *
 *   - `_$element`:     The element this jQuery plugin is called on.
 *
 */
import _ from "underscore";
import * as util from "../services/util";
import * as election from "../services/election";
import * as data from "../services/data";

export class EAElectionSelector {
  /* Creation
   * ------------------------------------------------------ */
  constructor(element, options) {
    this._$element = $(element);
    this._$element.empty();
    this._$element.addClass("ea-es");

    // TODO: have this module update URL params as well / read from them, for
    // bookmarking / link sharing purposes?

    // Validate the initial options
    if (!_.isObject(options)) {
      this.error("constructor: options not an object");
      return;
    }
    if (!("type" in options)) {
      this.error("constructor: no type");
      return;
    }
    if (!("year" in options)) {
      this.error("constructor: no year");
      return;
    }

    // Initialize the data-specific configurations.
    this._dataConfig = util.createDataConfigFromOptions(options);

    // No module config needed

    this._updateData().done(
      $.proxy(function () {
        this._initializeHtml();
        this._updateHtml();
        this._enableSelectors();
      }, this)
    );
  }

  _initializeHtml() {
    var $selectors = $(
      '<form class="form-inline ea-es-selectors">' +
        "<span>Choose an Election and Forecast: </span>" +
        '<div class="form-group">' +
        '<label class="sr-only" for="typeSelector">Choose a Type: </label>' +
        '<select name="typeSelector" class="form-control ea-es-selector ea-es-typeSelector">' +
        '<option value="president">President</option>' +
        '<option value="senate">Senate</option>' +
        "</select>" +
        "</div>" +
        '<div class="form-group">' +
        '<label class="sr-only" for="yearSelector">Choose a Year: </label>' +
        '<select name="yearSelector" class="form-control ea-es-selector ea-es-yearSelector"></select>' +
        "</div>" +
        '<div class="form-group">' +
        '<label class="sr-only" for="forecastSelector">Choose a Forecast: </label>' +
        '<select name="forecastSelector" class="form-control ea-es-selector ea-es-forecastSelector"></select>' +
        "</div>" +
        "</form>"
    );

    $selectors.change(
      $.proxy(function () {
        var forecastTime = moment(
          this._$element.find(".ea-es-forecastSelector").val()
        );
        var newDataConfig = {
          type: this._$element.find(".ea-es-typeSelector").val(),
          year: this._$element.find(".ea-es-yearSelector").val(),
          time: forecastTime,
        };

        this.update(newDataConfig);
      }, this)
    );

    /*
        var $extraOptions = $(
          '<form class="form-inline ea-es-extraOptions">' +
            '<span>Choose an option for third-party candidates: </span>' +
            '<div class="form-group">' +
              '<label class="sr-only" for="thirdPartySel">???: </label>' +
              '<select name="thirdPartySel" class="form-control ea-es-thirdPartySelector">' +
                '<option value="basic">Only Clinton and Trump</option>' +
                '<option value="with-Johnson">Include Johnson</option>' +
                '<option value="with-Johnson-Stein">Include Johnson and Stein</option>' +
              '</select>' +
            '</div>' +
          '</form>'
        );
        $extraOptions.find(".ea-es-thirdPartySelector").val("basic");

        $extraOptions.hide();
        if (this._dataConfig.type === 'president' &&
            (this._dataConfig.year == 2016 || this._dataConfig.year === '2016')) {
          $extraOptions.show();
        }
*/
    this._$element.append($selectors);
    //.append($extraOptions);
  } // end _initializeHtml

  /* Update
   * ------------------------------------------------------ */
  update(newDataConfig) {
    this._dataConfig = newDataConfig;

    this._disableSelectors();

    // Returns a filtered promise to avoid exposing excess data.
    var updater = this._updateData().then(
      $.proxy(function () {
        this._updateHtml();

        // Trigger event to update other modules on page
        var changeEvent = $.Event("selection", { options: newDataConfig });
        this._$element.trigger(changeEvent);

        this._enableSelectors();
      }, this)
    );

    return updater;
  } // end update

  _updateData() {
    var internalStateUpdated = $.Deferred();

    // Ensure that the year is valid; might not be if user switched from
    // Senate off-year to presidential without modifying the year
    this._dataConfig.year = election.validateYearForType(
      this._dataConfig.type,
      this._dataConfig.year
    );

    // Create a dataConfig without customizations, so we can retrieve the
    // base forecast times
    var dataConfigNoCustom = util.createDataConfigWithoutCustomizations(
      this._dataConfig
    );

    // Callback function to execute after loading data
    var successCallback = $.proxy(function (racesNoCustom, races) {
      this._races = races;
      // Make sure that dataConfig time is set appropriately
      this._dataConfig.time = util.validateDataConfigTime(
        this._dataConfig,
        this._races
      );
      // Update list of available forecast times
      this._forecastTimes = util.getAllForecastTimes(
        dataConfigNoCustom,
        racesNoCustom
      );
      // Resolve internal state after update
      internalStateUpdated.resolve();
    }, this);

    // Callback function to execute upon failure
    var failCallback = $.proxy(function (errorInfo) {
      if (
        errorInfo.statusCode === 400 &&
        errorInfo.statusText === "Invalid type"
      ) {
        this.error("invalid type");
      } else {
        this.error();
      }
      // Reject internal state
      internalStateUpdated.reject();
    }, this);

    // Get multiple Promise objects from data service API
    var dataPromise = $.when(
      data.getRacesWithForecasts(dataConfigNoCustom),
      data.getRacesWithForecasts(this._dataConfig)
    )
      .done(successCallback)
      .fail(failCallback);

    return internalStateUpdated.promise();
  } // end _updateData

  _updateHtml() {
    // Update type selector
    this._$element.find(".ea-es-typeSelector").val(this._dataConfig.type);

    // Update year selector
    var $yearSelector = this._$element.find(".ea-es-yearSelector");
    $yearSelector.empty();
    var years = election.getValidYearsForType(this._dataConfig.type);
    for (var i = 0; i < years.length; i++) {
      $yearSelector.append(new Option(years[i], years[i]));
    }
    $yearSelector.val(this._dataConfig.year);

    // Update forecast selector
    var $forecastSelector = this._$element.find(".ea-es-forecastSelector");
    $forecastSelector.empty();
    // go through the array backwards, so the most recent times are first
    for (var i = this._forecastTimes.length - 1; i >= 0; i--) {
      var fTime = this._forecastTimes[i];
      $forecastSelector.append(
        new Option(fTime.format("MMM DD, YYYY @ hh:mm A"), fTime.format())
      );
    }
    $forecastSelector.val(this._dataConfig.time.format());

    return;
  } // end _updateHtml

  _disableSelectors() {
    this._$element.find(".ea-es-selectors :input").prop("disabled", true);
  } // end _disableSelectors

  _enableSelectors() {
    this._$element.find(".ea-es-selectors :input").prop("disabled", false);
  } // end _enableSelectors

  /* Error
   * ------------------------------------------------------ */
  error(type) {
    this._$element.empty();

    var $errorMsg = $(
      '<div class="alert alert-error">Something seems to have gone wrong with our election selector... we\'ll be looking into the issue shortly.  Perhaps a refresh would help?</div>'
    );
    this._$element.append($errorMsg);

    if (type === "invalid type") {
      console.warn("The ElectionSelector module has an invalid race type.");
    } else if (type === "constructor: options not an object") {
      console.warn(
        "The ElectionSelector module's constructor received an invalid options parameter: it wasn't an object."
      );
    } else if (type === "constructor: no type") {
      console.warn(
        "The ElectionSelector module's constructor requires a `type` option."
      );
    } else if (type === "constructor: no year") {
      console.warn(
        "The ElectionSelector module's constructor requires a `year` option."
      );
    } else {
      console.warn("An unknown error occurred in the ElectionSelector module.");
    }

    // TODO: notify devs
  } // end error
} // end EAElectionSelector.prototype

/* jQuery Plugin & Autoloading
 * ------------------------------------------------------ */
// jQuery Plugin Definition
$.fn.eaElectionSelector = function (options) {
  var elements = this;
  options = _.isObject(options) ? options : {};

  var selectors = [];

  $(elements).each(function (idx, element) {
    var $element = $(element);

    var myOptions = {};
    _.defaults(myOptions, options, $element.data());

    selectors.push(new EAElectionSelector($element, myOptions));
  });

  return selectors;
};

// Autoloader
$(function () {
  $(".ea-election-selector").eaElectionSelector();
});
